import React from 'react';
import { BlacklistSection } from 'src/sections/admin';

function BlacklistPage(props) {
    return (
        <div>
            <BlacklistSection />
        </div>
    );
}

export default BlacklistPage;