import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Slide, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Add, Close } from '@mui/icons-material';
import { JobpostForm } from '.';
import { Post } from 'src/actions/API/apiActions';
import { notify } from 'src/components/notify';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

export default function AddPersonDialog({ open, onClose, person }) {
  const [jobPostValues, setJobPostValues] = useState();
  const [formErrors, setFormErrors] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessages, setErrorMessages] = React.useState([]);

  const addJobPost = () => {
    jobPostValues.person_id = person?.id;
    if (jobPostValues === undefined) {
      setErrorMessages(['Please must be fill out job Title, Job Posting link, Date, Country, Sector, Industry']);
      return;
    }
    if (formErrors) {
      setErrorMessages(['Please must be fill out job Title, Job Posting link, Date, Country, Sector, Industry']);
      return;
    }
    try {
      jobPostValues.person_id = person?.id;
      Post(
        jobPostValues,
        API_URLS.addJobPostWithPersonId,
        (resp) => {
          console.log('jobpostresp', resp);
          // notify('Job post added', 'success', 1000);
          enqueueSnackbar('Job post added', { variant: 'success' });
          onClose();
        },
        (error) => {
          console.log(error);
          const errorMessage = error.response?.data?.message || 'Error in adding job post';
          enqueueSnackbar(errorMessage, { variant: 'error' });
        }
      );
    } catch (error) {
      notify('Failed to add job post', 'error', 1000);
    }
  };
  console.log('jobPostValues', jobPostValues);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      hideBackdrop
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          ml: 'auto',
          mt: 'auto',
          mr: '2rem',
          width: '900px',
          height: '70%',
        },
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        sx={{
          backgroundColor: '#F4F9FD',
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" className="ml-8">
            Add Job Post
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ backgroundColor: '#F4F9FD' }}>
        <JobpostForm
          onSubmit={(values, actions) => {
            setJobPostValues(values);
          }}
          isError={(isValid) => {
            setFormErrors(isValid);
          }}
          onUpdate={(values) => {
            setJobPostValues({ ...jobPostValues, ...values });
          }}
        />
        <br />
        {errorMessages.length > 0 && (
          <div style={{ color: 'red', marginBottom: '20px' }}>
            {errorMessages.map((msg, index) => (
              <div key={index}>{msg}</div>
            ))}
          </div>
        )}
        <br />
        <div className="flex items-center justify-center mt-4">
          <Button
            variant="outlined"
            size="large"
            startIcon={<Add />}
            className="text-white border-[#00C1FE]  bg-[#00C1FE] hover:border-[#00C1FE] hover:bg-[#00C1FE]"
            style={{ outline: '1px solid rgba(0, 0, 0, 0.12)', backdropFilter: 'blur(4px)', marginRight: '1rem' }}
            onClick={addJobPost}
          >
            Add
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
