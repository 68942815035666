import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Stack } from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';
import Scrollbar from '../../../components/scrollbar';
import logo from 'src/images/logo.png';
import { Link as RouterLink } from 'react-router-dom';
import SidebarMenu from './config';
import { API_URLS } from 'src/constants/apiURLs';
import { Post } from 'src/actions/API/apiActions';
import { serverURLForImages } from 'src/constants/serverURLs';

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const isDesktop = useResponsive('up', 'lg');

  const [accountInfo, setAccountInfo] = useState({
    displayName: '',
    email: '',
    role: '',
    photoURL: '',
  });

  // Get user role from local storage
  const userRole = localStorage.getItem('role')
  const userId = parseInt(localStorage.getItem('userId'))

  const getUserById = useCallback(() => {
    Post(
      { userId: userId },
      API_URLS.getUserById,
      (resp) => {
        setAccountInfo({
          displayName: resp?.first_name + ' ' + resp.last_name,
          email: resp.email,
          role: resp.role,
          photoURL: `${serverURLForImages}/${resp.profile_image}`,
        });
      },
      (error) => {
        setAccountInfo({
          displayName: 'John Doe',
        });
      }
    );
  }, [userId]);

  useEffect(() => {
    getUserById();
  }, [userId]);

  useEffect(() => {
    setAccountInfo({
      displayName: 'John Doe',
      email: 'john.doe@example.com',
      role: userRole,
    });
  }, [userRole]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Link to="/" component={RouterLink}>
          <img src={logo} alt="Logo" height={80} width={80} />
        </Link>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            {/* <Avatar src={account.photoURL} alt="photoURL" /> */}

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {accountInfo.displayName}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {accountInfo.role}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      {/* Use SidebarMenu instead of NavSection */}
      <SidebarMenu userRole={userRole} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box sx={{ textAlign: 'center' }}>
            <div>
              <p>
                &copy; {new Date().getFullYear()} <span style={{ fontWeight: 'bolder' }}>UOL Developers</span>. All Rights Reserved.
              </p>
            </div>
          </Box>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
