import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import CheckEmailForVerification from './pages/auth/CheckEmailForVerification';

import DashboardPage from './pages/DashboardPage';
import Page404 from './pages/Page404';
import VerifiedAccountPage from './pages/auth/AccountVerificationPage';
import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';
import { CompanyDetailsPage, CompanyListPage, MainLeadGenerationPage } from './pages/leads';
import ForgetPasswordPage from './pages/auth/ForgetPasswordPage';
import AdminDashboardPage from './pages/admin/AdminDashboardPage';
import CampaignsPage from './pages/admin/CampaignsPage';
import CampaignSettings from './pages/admin/CampaignSettings';
import TemplatesPage from './pages/admin/TemplatesPage';
import { ClientUnsubscriptionPage } from './pages/public/subscriptions';
import BlacklistPage from './pages/admin/BlacklistPage';
import { CandidateList } from './sections/candidates';
import { EmailResearcherDashboardPage, EmailResearcherPage } from './pages/leadFinder';
import UsersPage from './pages/admin/UsersPage';
import { ACMInvoicesPage, ACMManageClientsPage, ACMManageRoles, ACMPage } from './pages/acm';
import { RolesProcessing } from './sections/acm/components';
import { ResourcingCandidatePage } from './pages/resourcing';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    // Admin Routes

    {
      path: '/leadExpert',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/leadExpert/app" />, index: true },
        { path: 'app', element: <DashboardPage /> },
        { path: 'leads', element: null },
        { path: 'findJobs', element: <MainLeadGenerationPage role="leadExpert" /> },
        { path: 'findEmails', element: <EmailResearcherPage /> },
      ],
    },
    // Admin Routes

    {
      path: '/admin',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/admin/app" />, index: true },
        { path: 'app', element: <AdminDashboardPage /> },
        { path: 'campaigns', element: <CampaignsPage /> },
        { path: 'create-campaign', element: <CampaignSettings /> },
        { path: 'templates', element: <TemplatesPage /> },
        { path: 'leads', element: <MainLeadGenerationPage role="admin" /> },
        { path: 'blacklist', element: <BlacklistPage /> },
        { path: 'candidates', element: <CandidateList /> },
        { path: 'users', element: <UsersPage /> },

        { path: 'companies', element: <CompanyListPage /> },
        { path: 'company', element: <CompanyDetailsPage /> },
        { path: 'resourcing', element: <ResourcingCandidatePage /> },

      ],
    },
    {
      path: '/acm',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/acm/app" />, index: true },
        { path: 'app', element: <ACMPage /> },
        { path: 'invoices', element: <ACMInvoicesPage /> },
        { path: 'roles', element: <ACMManageRoles /> },
        { path: 'clients', element: <ACMManageClientsPage /> },
        { path: 'roles-processing', element: <RolesProcessing /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: 'verify/:verificationtoken',
      element: <VerifiedAccountPage />,
    },
    {
      path: 'verifyEmail',
      element: <CheckEmailForVerification />,
    },
    {
      path: 'forgetPassword',
      element: <ForgetPasswordPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
        { path: 'unsubscribe', element: <ClientUnsubscriptionPage /> },
      ],
    },

    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
