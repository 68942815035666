import { Icon } from '@iconify/react';
import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomizedRolesComponent } from '.';

function RolesComponent(props) {
    const navigate = useNavigate();
  return (
    <div>
      <Grid container spacing={2} justifyContent="space-around" sx={{ marginTop: '3rem' }}>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <Button
            variant="outlined"
            startIcon={<Icon icon="ri:skip-back-fill" />}
            sx={{
              fontSize: '15px',
              borderRadius: '15px',
              borderWidth: '2px',
              color: '#0ec4fe',
              height: '55px',
              borderColor: '#0ec4fe',
              backgroundColor: '#EEF7FF',
              '&:hover': {
                color: '#0ec4fe !important',
                backgroundColor: '#EEF7FF !important',
                border: '2px solid #0ec4fe !important',
              },
            }}
            size="large"
            fullWidth
            // onClick={() => {
            //   setAdvanceClicked(false);
            //   setPreviousClicked(true);
            // }}
          >
            Previous Roles
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          fullWidth
          sx={{
            borderRadius: '10px',
            backgroundColor: '#DCECFF',
            height: '55px',
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 'bold',
            marginTop: '15px',
          }}
        >
          <Typography variant="h5" sx={{ width: '100%' }}>
            Total
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <Button
            variant="outlined"
            startIcon={<Icon icon="fluent:next-16-filled" />}
            sx={{
              fontSize: '15px',
              borderRadius: '15px',
              borderWidth: '2px',
              color: '#0ec4fe',
              height: '55px',
              borderColor: '#0ec4fe',
              backgroundColor: '#EEF7FF',
              '&:hover': {
                color: '#0ec4fe !important',
                borderColor: '#0ec4fe !important',
                backgroundColor: '#EEF7FF !important',
                border: '2px solid #0ec4fe !important',
              },
            }}
            size="large"
            fullWidth
            // onClick={() => {
            //   setPreviousClicked(false);
            //   setAdvanceClicked(true);
            // }}
          >
            Advance Roles
          </Button>
        </Grid>
      </Grid>
      <br />
      {/* <button onClick={() => navigate("/acm/roles-processing")}>see progress</button> */}
      <br/>
 
    </div>
  );
}

export default RolesComponent;
