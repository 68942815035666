import { Box, Button, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { AddNewClient, AddNewService, ArrowPipeline } from '../components';
import { Icon } from '@iconify/react';
import dayjs from 'dayjs';
import MaterialReactTable from 'material-react-table';

function Clients(props) {
  const [activePipeline, setActivePipeline] = useState(null);
  const [openClientDialog, setOpenClientDialog] = useState(false);

  const handleActivate = (title) => {
    setActivePipeline(title); // Set the active pipeline based on title
  };

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const [openServiceDialog, setOpenServiceDialog] = useState(false);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'assignedACM',
        header: 'ACM',
        enableClickToCopy: true,
        minWidth: 170,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableClickToCopy: true,
        minWidth: 170,
      },
      {
        accessorKey: 'clientNumber',
        header: 'Client No.',
        enableClickToCopy: true,
        minWidth: 170,
      },
      {
        accessorKey: 'roles',
        header: 'Roles',
        enableClickToCopy: true,
        minWidth: 170,
        // Cell: ({ cell }) => (
        //   <Box component="span">
        //     {cell?.row?.original?.clientService?.serviceName === 'IT and Consulting' ||
        //     cell?.row?.original?.clientService?.serviceName === 'Lead-Generation' ||
        //     cell?.row?.original?.clientService?.serviceName === 'VA' ? (
        //       <span style={{ fontWeight: 'bold' }}>Not Applicable</span>
        //     ) : (
        //       <>
        //         <span style={{ fontWeight: 'bold' }}>{cell?.row?.original?.creditsUsed}</span> /{' '}
        //         {cell?.row?.original?.noOfCredits}
        //       </>
        //     )}
        //   </Box>
        // ),
      },
      {
        accessorKey: 'clientService',
        header: 'Service',
        enableClickToCopy: true,
        minWidth: 170,
      },
      {
        accessorKey: 'amount',
        header: 'Amount',
        enableClickToCopy: true,
        minWidth: 170,
        // Cell: ({ cell }) => (
        //   <Box component="span">
        //     <>
        //       <span style={{ fontWeight: 'bold' }}>
        //         {cell?.row?.original?.amount ? cell?.row?.original?.amount : 'Not Added'}
        //       </span>
        //     </>
        //   </Box>
        // ),
      },
      {
        accessorKey: 'vat',
        header: 'VAT',
        enableClickToCopy: true,
        minWidth: 170,
        Cell: ({ cell }) => (
          <Box component="span">
            <>
              <span style={{ fontWeight: 'bold' }}>20%</span>
            </>
          </Box>
        ),
      },
      {
        accessorKey: 'fullName',
        header: 'Full name',
        enableClickToCopy: true,
        minWidth: 170,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        enableClickToCopy: true,
        minWidth: 150,
      },
      {
        accessorKey: 'joiningDate',
        header: 'Start date',
        enableClickToCopy: true,
        minWidth: 170,
      },
      // add expires in column w.r.t expiry date
      // {
      //   accessorKey: 'kuchbhi',
      //   header: 'Expires in',
      //   Cell: ({ cell }) => {
      //     const expiryDate = dayjs(cell.row.original.endDate);
      //     const currentDate = dayjs();
      //     const days = expiryDate.diff(currentDate, 'days');
      //     if (days <= 0) {
      //       // if (cell.row.original.status !== 'Expired') {
      //       //   updateStatusToExpired(cell.row.original.id);
      //       // }
      //       const daysAgo = Math.abs(days);
      //       const expiredMessage =
      //         daysAgo === 0 ? 'Expired today' : daysAgo === 1 ? 'Expired yesterday' : `Expired ${daysAgo} days ago`;
      //       return <span style={{ color: 'GREY', fontWeight: 'bold' }}>{expiredMessage}</span>;
      //     } else if (days === 1) {
      //       return <span style={{ fontWeight: 'bold', color: 'red' }}>1 day</span>;
      //     } else if (days < 5) {
      //       return <span style={{ color: 'red', fontWeight: 'bold' }}>{days} days</span>;
      //     } else {
      //       return <span style={{ fontWeight: 'bold' }}>{days} days</span>;
      //     }
      //   },
      // },
    ],
    []
  );

  return (
    <div style={{ backgroundColor: '#ECF6FE' }}>
      <Box display="flex" marginLeft="10px">
        <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
          Manage Clients
        </Typography>
      </Box>
      <br />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: '1rem', backgroundColor: 'white', width: '95%', margin: 'auto' }}
      >
        <ArrowPipeline
          title="Total"
          left={20}
          center={50}
          right={30}
          color="#F85ED6"
          leftTooltip="Expried"
          centerTooltip="Total"
          rightTooltip="Active"
          onLeftClick={() => {}}
          onCenterClick={() => {}}
          onRightClick={() => {}}
          onPipeLineClick={() => {}}
          // titleService={}
        />
        <ArrowPipeline
          title="CV Sourcing"
          left={40}
          center={60}
          right={50}
          color="#8C85FB"
          leftTooltip="Expried"
          centerTooltip="Total"
          rightTooltip="Active"
          onLeftClick={() => {}}
          onCenterClick={() => {}}
          onRightClick={() => {}}
          onPipeLineClick={() => {}}
        />

        <ArrowPipeline
          title="Pre Qualification"
          left={40}
          center={60}
          right={50}
          color="#00C1FE"
          leftTooltip="Expried"
          centerTooltip="Total"
          rightTooltip="Active"
          onLeftClick={() => {}}
          onCenterClick={() => {}}
          onRightClick={() => {}}
          onPipeLineClick={() => {}}
        />
        <ArrowPipeline
          title="360/Direct"
          left={40}
          center={60}
          right={50}
          color="#F85ED6"
          onCenterClick={() => {}}
          leftTooltip="Expired"
          centerTooltip="Total"
          rightTooltip="Active"
          onLeftClick={() => {}}
          onRightClick={() => {}}
          onPipeLineClick={() => {}}
        />
      </Box>
      <br />
      <Box display="flex" justifyContent="flex-end" sx={{ marginBottom: '1rem', width: '95%', margin: 'auto' }}>
        <Button
          variant="outlined"
          startIcon={<Icon icon="material-symbols:add" />}
          sx={{
            fontSize: '18px',
            borderRadius: '10px',
            borderWidth: '2px',
            color: '#0ec4fe',
            height: '54px',
            borderColor: '#0ec4fe',
            boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.2)',
            '&:hover': {
              color: '#0ec4fe !important',
              borderColor: '#0ec4fe !important',
              boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.2) !important',
              backgroundColor: 'transparent !important',
              border: '2px solid #0ec4fe !important',
            },
          }}
          size="small"
          onClick={() => setOpenClientDialog(true)}
        >
          Create New Client
        </Button>
        <Button
          variant="outlined"
          startIcon={<Icon icon="material-symbols:add" />}
          sx={{
            fontSize: '18px',
            borderRadius: '10px',
            borderWidth: '2px',
            color: '#0ec4fe',
            height: '54px',
            borderColor: '#0ec4fe',
            boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.2)',
            marginLeft: '10px',

            '&:hover': {
              color: '#0ec4fe !important',
              borderColor: '#0ec4fe !important',
              boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.2) !important',
              backgroundColor: 'transparent !important',
              border: '2px solid #0ec4fe !important',
            },
          }}
          size="small"
          onClick={() => setOpenServiceDialog(true)}
        >
          Add New Service
        </Button>
      </Box>

      <div style={{ margin: '2rem' }}>
        <MaterialReactTable
          columns={columns}
          data={[]}
          enableColumnActions={false}
          enableColumnFilters={false}
          enableTopToolbar={false}
          defaultColumn={{
            size: 50,
          }}
          manualPagination
          rowCount={totalCount}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          muiTableBodyRowProps={{ hover: false }}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            sorting,
          }}
          muiTableProps={{
            sx: {
              borderRadius: '30px',
            },
          }}
        />
      </div>
      <br />

      {openClientDialog && (
        <AddNewClient
          open={openClientDialog}
          onClose={() => setOpenClientDialog(false)}
          // onSubmit={(values, actions) => handleAddNewClient(values, actions)}
        />
      )}

      {openServiceDialog && <AddNewService open={openServiceDialog} onClose={() => setOpenServiceDialog(false)} />}
    </div>
  );
}

export default Clients;
