import { Alert, Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { API_URLS } from 'src/constants/apiURLs';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBox from '@mui/material/Checkbox';
import { Get, Post } from 'src/actions/API/apiActions';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { CreateSchedule, CreateSequence } from '.';
import { useNavigate } from 'react-router-dom';
// import AntSwitch from './AntSwitch'; // Assuming AntSwitch is a custom component

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CreateCampaign = () => {
  // const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedSector, setSelectedSector] = useState(null);
  const [countires, setCountires] = useState([]);
  const [BCC, setBCC] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [secondaryDelay, setSecondaryDelay] = useState(10);
  const { enqueueSnackbar } = useSnackbar();
  const [moveToSequence, setMoveToSequence] = useState(false);
  const [sequenceId, setSequenceId] = useState();
  const [campaignName, setCampaignName] = useState();
  const [countrySchedule, setCountrySchedule] = useState();
  const navigate = useNavigate();
  const [isCampaignDuplicate, setIsCampaignDuplicate] = useState(false);
  const [schedules, setSchedules] = useState([]);

  const [bdUsers, setBdUsers] = useState([]);

  // const handleScheduleData = (scheduleData)=>{
  //     setData(scheduleData)
  // }
  const handleDecrement = () => {
    setSecondaryDelay(secondaryDelay - 1);
  };

  const handleIncrement = () => {
    setSecondaryDelay(secondaryDelay + 1);
  };
  const handleInputChange = (e) => {
    const value = parseInt(e.target.value);
    if (isNaN(value)) {
      setSecondaryDelay(0);
    } else {
      setSecondaryDelay(value);
    }
  };

  const useStyles = makeStyles((theme) => ({
    checkbox: {
      '&$checked': {
        color: '#00C1FE', // color of the checkbox when checked
      },
    },
    checked: {},
    label: {
      color: '#000', // color of the label text
    },
  }));
  const classes = useStyles();

  const getAllCountries = () => {
    try {
      Get(
        {},
        API_URLS.getAllCountries,
        (response) => {
          setCountires(response.data);
        },
        (error) => {
          console.log('error', error);
        }
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getAllCountries();
  }, []);
  const getAllSectors = () => {
    try {
      Get(
        {},
        API_URLS.getAllSectors,
        (response) => {
          setSectors(response.data);
        },
        (error) => {
          console.log('error', error);
        }
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getAllSectors();
  }, []);

  const getRoleBasedUsers = () => {
    try {
      Get(
        { role: 'bdExpert' },
        API_URLS.getRoleBasedUsers,
        (response) => {
          console.log('bdusers', response.data);
          setBdUsers(response.data?.roleBaseUsers);
        },
        (error) => {
          console.log('error', error);
        }
      );
    } catch {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  useEffect(() => {
    getRoleBasedUsers();
  }, []);

  const addSchedule = (values) => {
    try {
      Post(
        {
          countryId: selectedCountry?.id,
          schedules: values,
        },
        API_URLS.createSchedule,
        (res) => {
          enqueueSnackbar('Schedule added successfully', { variant: 'success' });
          // actions.resetForm();
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message || 'Failed to add schedule', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const updateSchedule = (values) => {
    try {
      Post(
        {
          countryId: selectedCountry?.id,
          schedules: values,
        },
        API_URLS.updateSchedule,
        (res) => {
          enqueueSnackbar('Schedule updated successfully', { variant: 'success' });
          // actions.resetForm();
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message || 'Failed to update schedule', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const CreateCampaign = () => {
    if (!sequenceId) {
      enqueueSnackbar('Please create a sequence first', { variant: 'warning' });
      return;
    }
    try {
      Post(
        {
          name: campaignName,
          start_date: new Date(),
          is_running: true,
          status: 'active',
          country_id: selectedCountry?.id,
          sector_id: selectedSector?.id,
          sequence_id: sequenceId,
          BCC: BCC,
          BDs: selectedUsers?.map((user) => user.id),
        },
        API_URLS.createCampaign,
        (resp) => {
          enqueueSnackbar('Campaign Created Successfully', { variant: 'success' });
          navigate('/admin/campaigns');
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message || 'Failed to create campaign', { variant: 'error' });
        }
      );
    } catch {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const getScheduleOfCountry = () => {
    try {
      Get(
        { country_id: selectedCountry?.id },
        API_URLS.getSchedulesByCountry,
        (resp) => {
          console.log('resp', resp);
          setCountrySchedule(resp?.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };
  useEffect(() => {
    if (selectedCountry) {
      getScheduleOfCountry();
    }
  }, [selectedCountry]);

  const checkCampaignDuplicate = () => {
    try {
      Get(
        { name: campaignName },
        API_URLS.checkDuplicateByName,
        (resp) => {
          console.log('rfdsfdsesp', resp);
          if (resp?.data?.exists === true) {
            setIsCampaignDuplicate(true);
          } else {
            setIsCampaignDuplicate(false);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } catch {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (campaignName ) {
      checkCampaignDuplicate();
    }
  }, [campaignName]);


  return (
    <>
      {moveToSequence ? (
        <CreateSequence
          sequenceId={(sequenceId) => {
            console.log('sequenceId', sequenceId);
            setSequenceId(sequenceId);
          }}
          onCreateCampaign={() => CreateCampaign()}
          onBack = {()=>setMoveToSequence(false)}
        />
      ) : (
        <>
          <Typography className="font-extrabold text-3xl pl-4">Create Campaign</Typography>
          <div className="container  rounded-2xl bg-white ">
            <div className="flex items-center w-3/4 border border-slate-200 rounded mt-6 ml-6 ">
              <span className="mr-4   ml-2 font-bold">Campaign Name</span>
              <div
                className="py-4 pr-4 pl-6 rounded mt-2 font-medium text-start bg-white"
                style={{ color: '#00C1FE', width: '100%' }}
              >
                <TextField
                  className="rounded-full"
                  id="name"
                  name="name"
                  value={campaignName ? campaignName : ''}  
                  placeholder="Enter Campaign Name"
                  onChange={(e) => setCampaignName(e.target.value)}
                  fullWidth
                  sx={{
                    borderRadius: '0.5rem',
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#00C1FE',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#00C1FE',
                      },
                    },
                    '& .MuiInputBase-root': {
                      '&.Mui-focused': {
                        boxShadow: '0 0 0 1px #00C1FE',
                      },
                    },
                  }}
                />
              </div>
            </div>

            <hr className="w-fit  mt-4 bg-slate-800 h-[2px] " />
            <div className="grid grid-cols-1 gap-4 bg-white ">
              <div className="grid grid-cols-1 gap-4 bg-white m-6 border-slate-200 border-2 rounded-md">
                <Typography className="font-bold text-2xl ml-10 mt-4">Set Time For New Leads</Typography>
                <div className="grid grid-cols-2 gap-4 bg-white mx-6">
                  <div className="m-6">
                    <label htmlFor="outlined-textfield" style={{ fontWeight: 'bold' }}>
                      Select Country
                    </label>
                    <Autocomplete
                      fullWidth
                      size="normal"
                      options={countires}
                      value={selectedCountry}
                      getOptionLabel={(option) => option.country}
                      onChange={(event, newCountry) => {
                        if (newCountry) {
                          setSelectedCountry(newCountry);
                        } else {
                          setSelectedCountry(null);
                        }
                      }}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="m-6">
                    <label htmlFor="sector_id" style={{ fontWeight: 'bold' }}>
                      Select Sector
                    </label>
                    <Autocomplete
                      options={sectors}
                      getOptionLabel={(option) => option.sector}
                      onChange={(event, newSector) => {
                        if (newSector) {
                          setSelectedSector(newSector);
                        } else {
                          setSelectedSector(null);
                        }
                      }}
                      value={selectedSector}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Sector"
                          fullWidth
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <hr className="w-3/4  mx-auto" />
                <CreateSchedule
                  onSubmit={(schedules) => {
                    if(countrySchedule){
                      updateSchedule(schedules);
                      setSchedules(schedules);

                    }else{
                      addSchedule(schedules);
                      setSchedules(schedules);
                    }
                  }}
                  existingSchedule={countrySchedule}
                />
                <hr className="w-3/4  mx-auto my-10" />
                <div className="grid grid-cols-2 gap-4 bg-white mx-6">
                  <div className="grid grid-cols-1  gap-4  m-6">
                    <TextField
                      id="bcc"
                      name="bcc"
                      value={BCC}
                      type="string"
                      fullWidth
                      size="medium"
                      placeholder="Add BCC"
                      onChange={(e) => setBCC(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4 bg-white mx-6">
                  <div className="grid grid-cols-1  gap-4  m-6">
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={bdUsers}
                      size="small"
                      disableCloseOnSelect
                      value={selectedUsers}
                      getOptionLabel={(option) => option?.first_name + ' ' + option?.last_name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <CheckBox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option?.first_name + ' ' + option?.last_name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select BD"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              height: '56px', // Adjust height
                              fontSize: '1.2rem', // Adjust font size
                            },
                          }}
                        />
                      )}
                      onChange={(event, newUser) => {
                        if (newUser) {
                          console.log('bdusers', newUser);
                          setSelectedUsers(newUser);
                        } else {
                          setSelectedUsers(null);
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="flex justify-end mr-10 mb-10 ">
                  <button className="border border-black rounded-lg  px-6 py-4  mr-4 text-lg font-medium" onClick={()=>{
                    setCampaignName('');
                    setSelectedCountry(null);
                    setSelectedSector(null);
                    setBCC('');
                    setSelectedUsers([]);
                    setSchedules([]);
                  }}>
                    Cancel
                  </button>
                  <Button
                    type="submit"
                    className="border px-10 py-4 rounded-lg mr-4 text-lg font-medium text-white"
                    style={{ backgroundColor: '#00C1FE', border: '2px' }}
                    onClick={() => {
                      // if (!campaignName || !BCC || selectedUsers?.length === 0 || schedules?.length === 0) {
                      //   enqueueSnackbar('Please fill all the fields before moving to the next step', {
                      //     variant: 'warning',
                      //   });
                      // } else if (isCampaignDuplicate) {
                      //   enqueueSnackbar('Campaign with the same name already exists', {
                      //     variant: 'warning',
                      //   });
                      // } else {
                      //   setMoveToSequence(true);
                      // }
                      setMoveToSequence(true);
                    }}
                  >
                    Next Step
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CreateCampaign;
