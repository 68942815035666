import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Slide, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Add, Close } from '@mui/icons-material';
import { CompanyForm, JobpostForm } from '.';
import { Post } from 'src/actions/API/apiActions';
import { notify } from 'src/components/notify';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';
const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

export default function AddCompanyDialog({ open, onClose }) {
  const [compsnyValues, setCompsnyValues] = useState();
  const [formErrors, setFormErrors] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessages, setErrorMessages] = React.useState([]);
 const user_id = 1
  const addCompany = () => {
    if (compsnyValues === undefined) {
      setErrorMessages(['Please Must be fill out  Company Name, Website , LinkedId, Sector']);
      return;
    }
    if (formErrors) {
      setErrorMessages(['Please Must be fill out  Company Name, Website , LinkedId, Sector']);
      return;
    }

    try {
      compsnyValues.user_id =user_id
      Post(
        compsnyValues,
        API_URLS.addCompany,
        (resp) => {
          console.log('resp',resp);
          // notify('Job post added', 'success', 1000);
          enqueueSnackbar('Company added', { variant: 'success' });
          onClose();

        },
        (error) => {
          console.log('error', error);
          // Assuming error contains a response with a message field
          const errorMessage = error.response?.data?.message || 'Error in adding company';
          enqueueSnackbar(errorMessage, { variant: 'error' });
        }
      );
    } catch (error) {
      notify('Failed to add company', 'error', 1000);
    }
  };

  console.log('compsnyValues', compsnyValues);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      hideBackdrop={false}
      disableBackdropClick
      disableEscapeKeyDown
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          ml: 'auto',
          mt: 'auto',
          mr: '2rem',
          width: '900px',
          height: '70%',
        },
      }}
      aria-describedby="alert-dialog-slide-description"
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: '#F4F9FD',
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" className="ml-8">
            Add Company
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ backgroundColor: '#F4F9FD' }}>
        <CompanyForm
          onSubmit={(values) => {
            setCompsnyValues(values);
          }}
          isError={(isValid) => {
            setFormErrors(isValid);
          }}
          onUpdate={(values) => {
            setCompsnyValues({ ...compsnyValues, ...values });
          }}
        />
        <br />
        {errorMessages.length > 0 && (
          <div style={{ color: 'red', marginBottom: '20px' }}>
            {errorMessages.map((msg, index) => (
              <div key={index}>{msg}</div>
            ))}
          </div>
        )}
        <br />
        <div className="flex items-center justify-center mt-4">
          <Button
            variant="outlined"
            size="large"
            startIcon={<Add />}
            className="text-white border-[#00C1FE]  bg-[#00C1FE] hover:border-[#00C1FE] hover:bg-[#00C1FE]"
            style={{ outline: '1px solid rgba(0, 0, 0, 0.12)', backdropFilter: 'blur(4px)', marginRight: '1rem' }}
            onClick={() => {
              addCompany();
            }}
          >
            Add
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
