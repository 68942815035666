import { Icon } from '@iconify/react';
import { Box, Tooltip, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { Get } from 'src/actions/API/apiActions';
import { CSVLink } from 'react-csv'; // Import CSVLink from react-csv
import { enqueueSnackbar } from 'notistack';
import { API_URLS } from 'src/constants/apiURLs';
import { formatDistanceToNow } from 'date-fns';


function UnsubEmailsTable({ fromDate, toDate, campaign }) {
  // table states
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [unsubEmails, setUnsubEmails] = useState([]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'person_name',
        header: 'Name',
        minWidth: 170,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        enableClickToCopy: true,
        minWidth: 170,
      },
      {
        accessorKey: 'email',
        header: 'Status',
        enableClickToCopy: true,
        minWidth: 170,
        Cell: ({ cell }) => (
          <Box component="span">
            <>
              <Typography
                variant="body2"
                style={{
                  color: 'white',
                  backgroundColor: '#ff8266',
                  padding: '5px',
                  borderRadius: '5px',
                  width: '100px',
                  textAlign: 'center',
                }}
              >
                Unsubscribed
              </Typography>
            </>
          </Box>
        ),
      },
      {
        accessorKey: 'user_name',
        header: 'Lead Expert',
        minWidth: 170,
      },
      {
        accessorKey: 'createdAt',
        header: 'Date',
        minWidth: 170,
        Cell: ({ cell }) => {
          const date = new Date(cell.getValue());
          return <Typography variant="body2">{formatDistanceToNow(date, { addSuffix: true })}</Typography>;
        },
      },
    ],
    [] // Recalculate when counters change
  );

  const getRecentBouncedEmails = () => {
    try {
      setIsLoading(true);
      Get(
        {
          fromDate: fromDate,
          toDate: toDate,
          campaignId: campaign?.id,
        },
        API_URLS.getRecentUnSubscribedEmails,
        (resp) => {
          console.log('resp', resp);
          let data = resp?.data;
          data = data.map((row) => {
            row.person_name = `${row?.person?.first_name} ${row?.person?.last_name}`;
            row.user_name = `${row?.user?.first_name} ${row?.user?.last_name}`;
            return row;
          });
          setUnsubEmails(data);
          setIsLoading(false);
        },
        (error) => {
          console.log('error', error);
          setIsError(true);
          setIsLoading(false);
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
      setIsError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRecentBouncedEmails();
  }, [campaign, fromDate, toDate]);

  // // Convert data to CSV
  const csvData = unsubEmails?.map((email) => ({
    Name: email.person.first_name + ' ' + email.person.last_name,
    Email: email.email,
    Status: 'Un Subscribed',
    Addedby: email.user.first_name + ' ' + email.user.last_name,
    Date: new Date(email.createdAt).toLocaleDateString(),
  }));
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ marginLeft: '1rem' }} variant="h4">
           Unsubscribed Emails
        </Typography>
        <CSVLink data={csvData} style={{ marginLeft: '10px' }} filename="unsub-emails.csv">
          <Tooltip title="Download" arrow>
            <Icon icon="ic:outline-download" style={{ fontSize: '25px' }} />
          </Tooltip>
        </CSVLink>
      </Box>

      <div style={{ margin: '1rem' }}>
        <MaterialReactTable
          columns={columns}
          data={unsubEmails}
          enableColumnActions={false}
          enableColumnFilters={false}
          enableTopToolbar={false}
          defaultColumn={{
            size: 50,
          }}
          manualPagination
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          muiTableBodyRowProps={{ hover: false }}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            sorting,
          }}
          muiTableProps={{
            sx: {
              borderRadius: '30px',
            },
          }}
        />
      </div>
    </>
  );
}

export default UnsubEmailsTable;
