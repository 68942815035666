import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Container, Typography } from '@mui/material';
// components

// sections

// ----------------------------------------------------------------------

export default function DashboardPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard | BD </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h3" sx={{ mb: 5 }}>
          Hello Sir, Welcome back
        </Typography>
        <Typography variant="h5" fontFamily="revert" fontStyle="revert" sx={{ mb: 5 }}>
          Empower your team, connect with customers, and drive business growth with effective administration
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}></Grid>
        </Grid>
      </Container>
    </>
  );
}
