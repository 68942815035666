import React from 'react';
import { CompanyDetails } from 'src/sections/leads/companies';

function CompanyDetailsPage() {
    return (
      <div>
        <CompanyDetails />
      </div>
    );
}

export default CompanyDetailsPage;