import { Box, Dialog, DialogContent, Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import HTMLEditor from './HTMLEditor';
import { Get, Post } from 'src/actions/API/apiActions';
import { useSnackbar } from 'notistack';
import { API_URLS } from 'src/constants/apiURLs';

const EditDisclaimer = ({ open, onClose }) => {
  const [emailDisclaimer, setEmailDisclaimer] = useState(null);
  const [template, setTemplate] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const getAllTemplates = useCallback(() => {
    try {
      Get(
        {},
        API_URLS.getTemplates,
        (resp) => {
          setTemplate(resp?.data?.templates[0]);
        },
        (error) => {
          enqueueSnackbar('Failed to load templates', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    getAllTemplates()
  }, [getAllTemplates]);

  const handleUpdateDisclaimer = () => {
    try {
      Post(
        { disclaimer: emailDisclaimer },
        API_URLS.updateTemplateDisclaimer,
        (resp) => {
          // setTemplate(resp[0])
          enqueueSnackbar('Email disclaimer updated succesfully for all templates', { variant: 'success' });
          onClose();
          getAllTemplates()
        },
        (error) => {
          enqueueSnackbar('Failed to update disclaimer', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} className="text-lg m-4">
              <HTMLEditor
                showTags={false}
                editorLabel="Email Disclaimer"
                htmlText={template?.disclaimer}
                onEditorStateChange={(html) => {
                  setEmailDisclaimer(html?.data);
                }}
              />
            </Grid>
          </Grid>
          <Box className="flex justify-end mt-4 mr-4">
            <button
              className="mt-4 mr-4 border-2 border-[#00C1FE] bg-white text-[#00C1FE] px-4 py-1 rounded-md hover:bg-[#00C1FE] hover:text-white"
              onClick={() => onClose()}
            >
              Cancle
            </button>
            <button
              className="px-2 py-2 mt-4 text-white bg-[#00C1FE] hover:bg-[#00C1FE]   text-sm rounded-md"
              onClick={() => handleUpdateDisclaimer()}
            >
              Update Disclaimer
            </button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditDisclaimer;
