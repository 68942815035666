import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {  Add, Refresh, FilterList, Search } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { notify } from 'src/components/notify';
import {
  AddCompanyDialog,
  CompanyCard,
  CompanyDetailsCard,
  PersonCard,
  ShowJobpostsResults,
  ShowPersonResults,
} from './components';
import { Alert, Box, CircularProgress, Grid, Pagination, TablePagination, Typography } from '@mui/material';
import PersonDetailsDialog from './PersonDetailsDialog';
import { CreateLeadDialog } from '.';
import _  from 'lodash';
import AddJobPostToLocalStorage from './components/AddJobPostDialogToLocalStorage';
import { FilterDialog } from './components/FilterDialog';
import { roleContext } from 'src/pages/leads/MainLeadGenerationPage';
import LeadStats from './LeadStats';
import MultiDatePicker from 'src/components/MultiDatePicker';

export const PersonContext = createContext();
export const JobPostLinkContext = createContext();
// Styled button component with conditional styling
const CustomButton = styled(Button)(({ theme, active }) => ({
  height: '50px',
  size: 'medium',
  outline: '1px solid rgba(0, 0, 0, 0.12)',
  border: '1px solid #00C1FE',
  borderRadius: '5px',
  marginRight: '10px',
  color: active ? '#ffffff' : '#00C1FE',
  backgroundColor: active ? '#00C1FE' : '#ECF6FE',
  '&:hover': {
    backgroundColor: active ? '#00C1FE' : '#ECF6FE',
  },
}));

export default function LeadGenerationSection({ onCompaniesClick, onJobsClick, onPeopleClick }) {
  // get role context
  const [activeIndex, setActiveIndex] = useState(null);
  const role = useContext(roleContext);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [personsLoading, setPersonsLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [persons, setPersons] = useState([]);
  const [openPersonDialog, setOpenPersonDialog] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [openLeadForm, setOpenLeadForm] = useState(false);
  const [searchString, setSearchString] = useState('');
  // const [mainCompaniesResullts, setMainCompaniesResults] = useState([]);
  const [searchResultsLoading, setSearchResultsLoading] = useState(false);
  const [mainPersonsResults, setMainPersonsResults] = useState([]);
  const [mainJobPostsResults, setMainJobPostsResults] = useState([]);
  const [companySearchString, setCompanySearchString] = useState('');
  const [secondaryCompaniesResults, setSecondaryCompaniesResults] = useState([]);
  const [openAddJobPostDialog, setOpenAddJobPostDialog] = useState(false);
  const [companySearchLoading, setCompanySearchLoading] = useState(false);
  const [openAddCompanyDialog, setOpenAddCompanyDialog] = useState(false);
  const [showButtonsInCompanyCard, setShowButtonsInCompanyCard] = useState(false);
  const [isJobPostFound, setIsJobPostFound] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(0);
  // const [totalJobPosts, setTotalJobPosts] = useState(0);
  // const [totalPeople, setTotalPeople] = useState(0);
  const [openFiltersDialog, setOpenFiltersDialog] = useState(false);
  const [stats, setStats] = useState();
  const [filters, setFilters] = useState(null);
  const [findPeople, setFindPeople] = useState(null);
  const [formattedDates, setFormattedDates] = useState([null, null]);

  // Person Pagination
  const [personPage, setPersonPage] = useState(0);
  const [personPageSize, setPersonPageSize] = useState(10);
  const [withContactInfo, setWithContactInfo] = useState(null);

  // paginate persons
  const startIndex = personPage * personPageSize;
  const endIndex = startIndex + personPageSize;
  const paginatedPersons = persons.slice(startIndex, endIndex);
  const [personSearchString, setPersonSearchString] = useState();

  // clear person pagination on component unmount
  useEffect(() => {
    return () => {
      setPersonPage(0);
      setPersonPageSize(10);
    };
  }, []);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setCompanySearchString(value);
    setSelectedCompany(null);
    setSearchString('');
  };
  const handlePageChange = (event, value) => {
    console.log('value', value, event.target.value);
    setCurrentPage(value);
  };
  const getAllCompanies = useCallback(() => {
    setLoading(true);
    try {
      Get(
        {
          country_id: filters?.country?.id,
          sector_id: filters?.sector?.id,
          page: currentPage,
          size: pageSize,
          findPeople: findPeople,
          startDate: formattedDates[0] || null,
          endDate: formattedDates[1] || null,
          withContactInfo: withContactInfo,
          // level: partial ? 2 : 3,
        },
        API_URLS.getAllCompanies,
        (res) => {
          console.log('res', res);
          const response = res?.data;
          setStats(response?.stats);
          setCompanies(response?.companies);
          // setTotalJobPosts(response?.jobPostsCount);
          // setTotalPeople(response?.peopleCount);
          setTotalPages(response?.totalPages);
          setTotal(response?.totalCount);
          setLoading(false);
        },
        (error) => {
          console.log(error);
          notify('Error in fetching companies', 'error', 1000);
          setLoading(false);
        }
      );
    } catch (error) {
      notify('Error in fetching companies', 'error', 1000);
      setLoading(false);
    }
  }, [currentPage, pageSize, filters, findPeople, formattedDates, withContactInfo]);

  console.log('formattedDates', formattedDates);

  useEffect(() => {
    getAllCompanies();
  }, [getAllCompanies]);

  // call get all companies every 1 minute
  useEffect(() => {
    if (role === 'admin') {
      // const interval = setInterval(() => {
      getAllCompanies();
      // }, 60000);
      // return () => clearInterval(interval);
      // } else {
      // return;
    }
  }, [getAllCompanies, role]);

  const getPersonsOfCompany = useCallback(() => {
    setPersonsLoading(true);
    try {
      Get(
        { company_id: selectedCompany?.id },
        API_URLS.getPersonByCompanyId,
        (res) => {
          console.log('res', res);
          setPersons(res?.data);
          setPersonsLoading(false);
        },
        (error) => {
          console.log(error);
          notify('Error in fetching persons', 'error', 1000);
          setPersonsLoading(false);
        }
      );
    } catch (error) {
      notify('Error in fetching persons', 'error', 1000);
      setPersonsLoading(false);
    }
  }, [selectedCompany]);
  useEffect(() => {
    if (selectedCompany) {
      getPersonsOfCompany();
    }
  }, [selectedCompany]);

  const debouncedSearch = useCallback(
    _.debounce(() => {
      if (searchString?.trim()) {
        setSearchResultsLoading(true);
        try {
          Get(
            { searchTerm: searchString },
            API_URLS.searchJobPosts,
            (res) => {
              console.log('res', res);
              setCompanySearchString(null);
              setMainJobPostsResults(res?.data)
              setSearchResultsLoading(false)
            },
            (error) => {
              console.log(error);
              notify('Error in fetching companies', 'error', 1000);
              setSearchResultsLoading(false);
            }
          );
        } catch (error) {
          console.error(error);
          notify('Error in fetching companies', 'error', 1000);
          setSearchResultsLoading(false);
        }
      } else {
        setMainJobPostsResults([]);
      }
    }, 300),
    [searchString]
  );

  useEffect(() => {
    debouncedSearch();

    // Cleanup function to cancel debounce if searchString changes
    return debouncedSearch.cancel;
  }, [searchString, debouncedSearch]);

  const findCompany = (id) => {
    return new Promise((resolve, reject) => {
      try {
        Get(
          { personId: id },
          API_URLS.getCompanyOfPerson,
          (res) => {
            console.log('res', res);
            resolve(res?.data?.company);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      } catch (error) {
        reject(error);
      }
    });
  };

  const findPerson = (id) => {
    return new Promise((resolve, reject) => {
      try {
        Get(
          { jobId: id },
          API_URLS.getPersonOfJobPost,
          (res) => {
            console.log('res', res);
            resolve(res?.data);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      } catch (error) {
        reject(error);
      }
    });
  };

  const findCompanyOfJobPost = (id) => {
    return new Promise((resolve, reject) => {
      try {
        Get(
          { jobId: id },
          API_URLS.getCompanyOfJobpost,
          (res) => {
            console.log('res', res);
            resolve(res?.data);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      } catch (error) {
        reject(error);
      }
    });
  };

  const debouncedCompanySearch = useCallback(
    _.debounce(() => {
      if (companySearchString?.trim()) {
        setCompanySearchLoading(true);
        try {
          Get(
            { searchTerm: companySearchString },
            API_URLS.searchCompanies,
            (res) => {
              console.log('res', res);
              setSecondaryCompaniesResults(res?.data);
              setSelectedCompany(null);
              setCompanySearchLoading(false);
            },
            (error) => {
              console.log(error);
              notify('Error in fetching companies', 'error', 1000);
              setCompanySearchLoading(false);
            }
          );
        } catch (error) {
          console.error(error);
          notify('Error in fetching companies', 'error', 1000);
          setCompanySearchLoading(false);
        }
      } else {
        setSecondaryCompaniesResults([]);
      }
    }, 300),
    [companySearchString]
  );

  useEffect(() => {
    debouncedCompanySearch();
    setCompanies(companies);
    // Cleanup function to cancel debounce if companySearchString changes
    return debouncedCompanySearch.cancel;
  }, [companySearchString, debouncedCompanySearch]);

  console.log('mainCompafdfniesResullts', secondaryCompaniesResults);
  useEffect(() => {
    if (persons?.length === 0) {
      setShowButtonsInCompanyCard(true);
    } else {
      setShowButtonsInCompanyCard(false);
    }
  }, [persons]);

  const checkDuplicateJobPost = () => {
    try {
      Get(
        { postingLink: searchString },
        API_URLS.checkDuplicateByPostingLink,
        (resp) => {
          console.log(resp);
          if (resp?.data?.length > 0) {
            setIsJobPostFound(true);
          } else {
            setIsJobPostFound(false);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (searchString) {
      checkDuplicateJobPost();
    }
  }, [searchString]);

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  
  const debouncedPersonSearch = useCallback(
    _.debounce(() => {
      if (personSearchString?.trim()) {
        setSearchResultsLoading(true);
        try {
          Get(
            { searchTerm: personSearchString },
            API_URLS.searchPersons,
            (res) => {
              console.log('res', res);
              setMainPersonsResults(res?.data);
              setCompanySearchString(null);
              setSearchString(null)
              setSearchResultsLoading(false)
            },
            (error) => {
              console.log(error);
              notify('Error in fetching persons', 'error', 1000);
              setSearchResultsLoading(false);
            }
          );
        } catch (error) {
          console.error(error);
          notify('Error in fetching persons', 'error', 1000);
          setSearchResultsLoading(false);
        }
      } else {
        setMainJobPostsResults([]);
      }
    }, 300),
    [personSearchString]
  );

  useEffect(() => {
    debouncedPersonSearch();

    // Cleanup function to cancel debounce if searchString changes
    return debouncedPersonSearch.cancel;
  }, [personSearchString, debouncedPersonSearch]);

  const handlePersonSearch= (value) => {
    if(value === '') {
      getPersonsOfCompany();
    }
    const tempPersons =[];
    persons.filter((person) => {
      if (person?.full_name?.toLowerCase()?.includes(value?.toLowerCase())) {
        tempPersons.push(person);
      } else if (person?.current_title?.toLowerCase()?.includes(value?.toLowerCase())) {
        tempPersons.push(person);
      }
    })
    setPersons(tempPersons);
  }


  return (
    <>
      <div style={{ backgroundColor: '#ECF6FE' }}>
        <Box display="flex" justifyContent="flex-end" flexWrap="wrap" paddingTop="5px" marginRight="10px">
          <Button
            variant="outlined"
            style={{
              outline: '1px solid rgba(0, 0, 0, 0.12)',
              backdropFilter: 'blur(4px)',
              border: '1px solid #00C1FE',
              color: '#00C1FE',
              height: '50px',
              marginRight: '10px',
            }}
            onClick={() => {
              getAllCompanies();
              setSelectedCompany(null);
              debouncedSearch();
            }}
            size="medium"
          >
            <Refresh /> &nbsp; Refresh
          </Button>
          <MultiDatePicker
            onChange={(dateRange) => {
              setFormattedDates(dateRange);
            }}
            numberOfMonths={2}
            height="50px"
          />
          {role === 'admin' && (
            <>
              <Button
                style={{
                  outline: '1px solid rgba(0, 0, 0, 0.12)',
                  backdropFilter: 'blur(2px)',
                  backgroundColor: withContactInfo ? '#00C1FE' : '#ECF6FE',
                  color: withContactInfo ? 'white' : '#00C1FE',
                  border: '1px solid #00C1FE',
                  height: '50px',
                  marginRight: '10px',
                  '&:hover': {
                    backgroundColor: '#00C1FE',
                    color: 'white',
                  },
                }}
                size="medium"
                // variant={withContactInfo ? 'contained' : 'outlined'}
                onClick={() => setWithContactInfo(!withContactInfo)}
              >
                <Icon icon="fluent:contact-card-28-filled" style={{ fontSize: '24px' }} /> &nbsp; Contact Info
              </Button>
              <div>
                <CustomButton
                  active={findPeople === false ? true : false}
                  onClick={() => {
                    if (findPeople === false) {
                      setFindPeople(null);
                    } else {
                      setFindPeople(false);
                    }
                  }}
                >
                  <Icon icon="mdi:user-off" style={{ fontSize: '20px' }} /> &nbsp; No People
                </CustomButton>
              </div>
              <CustomButton
                active={findPeople === true ? true : false}
                onClick={() => {
                  if (findPeople === true) {
                    setFindPeople(null);
                  } else {
                    setFindPeople(true);
                  }
                }}
              >
                <Icon icon="mdi:user-check" style={{ fontSize: '22px' }} /> &nbsp; With People
              </CustomButton>
              <Button
                variant="outlined"
                style={{
                  outline: '1px solid rgba(0, 0, 0, 0.12)',
                  backdropFilter: 'blur(4px)',
                  marginRight: '10px',
                  backgroundColor: '#ECF6FE',
                  border: '1px solid #00C1FE',
                  color: '#00C1FE',
                  height: '50px',
                }}
                onClick={() => setOpenFiltersDialog(true)}
                size="medium"
              >
                <FilterList /> &nbsp; Filters
              </Button>
            </>
          )}

          <Button
            variant="outlined"
            startIcon={<Add />}
            style={{
              outline: '1px solid rgba(0, 0, 0, 0.12)',
              backdropFilter: 'blur(4px)',
              marginRight: '1rem',
              padding: '10px 10px',
              border: '1px solid #00C1FE',
              backgroundColor: '#00C1FE',
              color: 'white',
              width: '180px',
              height: '50px',
            }}
            onClick={() => setOpenAddCompanyDialog(true)}
            size="medium"
          >
            Create Company
          </Button>
        </Box>
        <br />
        {role === 'admin' && (
          <LeadStats
            onCompanyClick={(sectorId, countryId) => {
              onCompaniesClick(sectorId, countryId);
            }}
            onJobClick={(sectorId, countryId) => onJobsClick(sectorId, countryId)}
            onPersonClick={(sectorId, countryId) => onPeopleClick(sectorId, countryId)}
            startDate={formattedDates[0]}
            endDate={formattedDates[1]}
            withContactInfo={withContactInfo}
            findPeople={findPeople}
          />
        )}
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <Icon
                    icon="mingcute:search-line"
                    style={{ fontSize: '24px', fontWeight: 'bold', marginRight: '8px' }}
                  />
                ),
              }}
              variant="outlined"
              placeholder="Search (Companies)"
              sx={{
                backgroundColor: 'white',
                borderRadius: '0.3rem',
                marginLeft: '5px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {},
                  '&:hover fieldset': {
                    borderColor: '#00C1FE',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#00C1FE',
                  },
                },
                '& .MuiInputBase-root': {
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 1px #00C1FE',
                  },
                },
              }}
              onChange={handleSearchChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <Icon
                    icon="mingcute:search-line"
                    style={{ fontSize: '24px', fontWeight: 'bold', marginRight: '8px' }}
                  />
                ),
              }}
              variant="outlined"
              placeholder="Search (Jobs)"
              onChange={(e) => {
                const value = e.target.value;
                setSearchString(value);
                setSelectedCompany(null);
                setCompanySearchString(null);
              }}
              sx={{
                backgroundColor: 'white',
                borderRadius: '0.3rem',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {},
                  '&:hover fieldset': {
                    borderColor: '#00C1FE',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#00C1FE',
                  },
                },
                '& .MuiInputBase-root': {
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 1px #00C1FE',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <Icon
                    icon="mingcute:search-line"
                    style={{ fontSize: '24px', fontWeight: 'bold', marginRight: '8px' }}
                  />
                ),
              }}
              onChange={(e) => {
                const value = e.target.value;
                setPersonSearchString(value);
              }}
              variant="outlined"
              placeholder="Search ( People)"
              sx={{
                backgroundColor: 'white',
                borderRadius: '0.3rem',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {},
                  '&:hover fieldset': {
                    borderColor: '#00C1FE',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#00C1FE',
                  },
                },
                '& .MuiInputBase-root': {
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 1px #00C1FE',
                  },
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{
              maxHeight: '700px', // Adjust this value as needed for your layout
              overflowY: 'auto', // Enable scrolling
              overflowX: 'hidden', // Prevent horizontal scrolling
            }}
          >
            {companySearchLoading ? (
              <CircularProgress style={{ marginTop: '20px' }} />
            ) : (
              <>
                {/* <Typography variant="h6" style={{ marginTop: '10px' }}>
                  {secondaryCompaniesResults?.length} Companies found!
                </Typography> */}
                {secondaryCompaniesResults?.length > 0
                  ? secondaryCompaniesResults.map((company) => (
                      <>
                        <CompanyCard
                          key={company.id}
                          company={company}
                          onClick={() => {
                            setSelectedCompany(company);
                            setCompanySearchString('');
                          }}
                        />
                      </>
                    ))
                  : companySearchString && (
                      <>
                        <Alert severity="info" style={{ marginTop: '10px' }}>
                          <Typography variant="h6">No companies match your search!</Typography>
                        </Alert>
                      </>
                    )}
              </>
            )}

            {!companySearchString && (
              <>
                {loading ? (
                  <CircularProgress />
                ) : (
                  companies?.length > 0 && (
                    <>
                      <div className="flex justify-between items-center"></div>
                      {/* <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '20px',
                        }}
                      >
                        <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
                        <TextField
                          select
                          // label="Page Size"
                          value={pageSize}
                          onChange={handlePageSizeChange}
                          SelectProps={{
                            native: true,
                          }}
                          variant="standard"
                          size="small"
                          sx={{
                            width: '80px',
                            // mt: '20px',
                            backgroundColor: '#ECF6FE',
                            borderRadius: '0.4rem',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {},
                              '&:hover fieldset': {
                                borderColor: 'transparent',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        >
                          {[5, 10, 20, 50, 100].map((option) => (
                            <option
                              key={option}
                              value={option}
                              style={{
                                backgroundColor: '#ECF6FE',
                                borderRadius: '0.4rem',
                              }}
                            >
                              {option}
                            </option>
                          ))}
                        </TextField>
                      </div> */}
                      {companies?.map((company) => (
                        <CompanyCard key={company?.id} company={company} onClick={() => setSelectedCompany(company)} />
                      ))}
                      {/* select page Size */}
                    </>
                  )
                )}
              </>
            )}
            {companies?.length === 0 && !companySearchString && (
              <>
                <Alert severity="info">
                  <Typography variant="h6">No companies found!</Typography>
                </Alert>
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            {mainPersonsResults?.length > 0 && personSearchString && (
              <>
                {searchResultsLoading ? (
                  <CircularProgress />
                ) : (
                  mainPersonsResults?.length > 0 && (
                    <>
                      <h2 className="text-xl font-bold mt-1">{mainPersonsResults?.length} Persons found!</h2>
                      <br />
                      {mainPersonsResults?.map((person) => (
                        <ShowPersonResults key={person.id} person={person} company={person.company} />
                      ))}
                    </>
                  )
                )}
              </>
            )}
            {personSearchString && mainPersonsResults?.length === 0 && (
              <Alert severity="info">
                <Typography variant="h6">No persons match your search!</Typography>
              </Alert>
            )}

            <br />
            {mainJobPostsResults?.length > 0 && (
              <>
                {searchResultsLoading ? (
                  <CircularProgress />
                ) : (
                  mainJobPostsResults?.length > 0 && (
                    <>
                      <h2 className="text-xl font-bold p-2">{mainJobPostsResults?.length} Job posts found!</h2>
                      {mainJobPostsResults?.map((jobPost) => (
                        <ShowJobpostsResults
                          key={jobPost.id}
                          jobpost={jobPost}
                          person={jobPost?.person}
                          company={jobPost?.company}
                        />
                      ))}
                    </>
                  )
                )}
              </>
            )}
            {searchString && mainJobPostsResults?.length === 0 && (
              <Alert severity="info">
                <Typography variant="h6">No job posts match your search!</Typography>
              </Alert>
            )}

            {selectedCompany && (
              <>
                <CompanyDetailsCard
                  company={selectedCompany}
                  showButtons={showButtonsInCompanyCard}
                  onReload={() => getPersonsOfCompany()}
                />
                <div className="mt-8 flex w-full">
                  <div className="flex justify-between ">
                    <Icon icon="ic:round-person" width="2rem" height="2rem" style={{ color: 'black' }} /> &nbsp;
                    <h2 className="text-xl font-bold">Company Persons</h2>
                  </div>
                  &nbsp;
                  <TextField
                    size="small"
                    InputProps={{
                      startAdornment: <Search sx={{ color: 'black', mr: 1 }} />,
                    }}
                    variant="outlined"
                    placeholder="Search Person"
                    onChange={(e) => handlePersonSearch(e.target.value)}
                    sx={{
                      width: '50%',
                      margin: '0 0 0 auto',
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {},
                        '& fieldset': {},
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />
                  <Button
                    variant="outlined"
                    style={{
                      margin: 'auto',
                    }}
                    onClick={() => {
                      getPersonsOfCompany();
                    }}
                  >
                    <Refresh />
                  </Button>
                  <div className="flex justify-end mr-[46px] ml-4">
                    <Typography variant="h6"> About {persons?.length} results </Typography>
                  </div>
                  {/* <Button
                    variant="outlined"
                    className="text-[#00C1FE] border-[#00C1FE] hover:bg-blue-50 w-38 hover:border-[#00C1FE]"
                    style={{ outline: '1px solid rgba(0, 0, 0, 0.12)', backdropFilter: 'blur(4px)' }}
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                      setShowButtonsInCompanyCard(true);
                    }}
                  >
                    No suitable match?
                  </Button> */}
                </div>
                {personsLoading ? (
                  <CircularProgress className="px-2 py-2" />
                ) : persons.length > 0 ? (
                  <>
                    {/* handle manual pagination for persons */}
                    <TablePagination
                      component="div"
                      count={persons?.length}
                      page={personPage}
                      onPageChange={(e, newPage) => setPersonPage(newPage)}
                      rowsPerPage={personPageSize}
                      onRowsPerPageChange={(e) => setPersonPageSize(e.target.value)}
                      rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '20px',
                        flexDirection: 'column',
                        gap: '10px',
                      }}
                    >
                      {paginatedPersons?.map((person) => (
                        <PersonCard
                          key={person.id}
                          person={person}
                          onClick={() => {
                            setSelectedPerson(person);
                            setOpenPersonDialog(true);
                          }}
                        />
                      ))}
                    </div>
                    <TablePagination
                      component="div"
                      count={persons?.length}
                      page={personPage}
                      onPageChange={(e, newPage) => setPersonPage(newPage)}
                      rowsPerPage={personPageSize}
                      onRowsPerPageChange={(e) => setPersonPageSize(e.target.value)}
                      rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    />
                  </>
                ) : (
                  <Alert severity="info" style={{ marginTop: '10px' }}>
                    <Typography variant="h6">No persons found!</Typography>
                  </Alert>
                )}
              </>
            )}
            {!searchString && !selectedCompany && (
              <Alert severity="info">
                <Typography variant="h6">Select a company to view details</Typography>
              </Alert>
            )}
          </Grid>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <p style={{ marginLeft: '15px' }}>{total}</p>
            <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
            <TextField
              select
              // label="Page Size"
              value={pageSize}
              onChange={handlePageSizeChange}
              SelectProps={{
                native: true,
              }}
              variant="standard"
              size="small"
              sx={{
                width: '80px',
                // mt: '20px',
                backgroundColor: '#ECF6FE',
                borderRadius: '0.4rem',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {},
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                  },
                },
                '& .MuiInputBase-root': {
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 1px #00C1FE',
                  },
                },
              }}
            >
              {[5, 10, 20, 50, 100].map((option) => (
                <option
                  key={option}
                  value={option}
                  style={{
                    backgroundColor: '#ECF6FE',
                    borderRadius: '0.4rem',
                  }}
                >
                  {option}
                </option>
              ))}
            </TextField>
          </div>
        </Grid>
        <div
          style={{
            background: '#ECF6FE',
            padding: '1rem',
            overflowY: 'scroll',
            overflowX: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
          }}
        >
          {/* <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1rem',
            borderRadius: '0.3rem',
          }}
        >
          <Button
            variant="outlined"
            className="text-[#00C1FE] border-[#00C1FE] bg-[white] hover:bg-blue-50 hover:border-[#00C1FE] mr-4 h-[52px] w-20 ml-4"
            style={{ outline: '1px solid rgba(0, 0, 0, 0.12)', backdropFilter: 'blur(4px)' }}
            onClick={() => setOpenAddJobPostDialog(true)}
          >
            Add
          </Button>
        </div> */}

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '1rem',
              alignItems: 'center',
              padding: '1rem',
              // background: 'white',
              borderRadius: '0.3rem',
              // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
          >
            {role === 'admin' && (
              <>
                {filters && filters?.country && (
                  <div className="flex items-center bg-[#008000] text-white px-2 py-1 rounded-lg mr-4">
                    <p>{filters?.country?.country}</p>
                    <button
                      onClick={() => setFilters({ ...filters, country: null })}
                      className="ml-2 border"
                      style={{
                        border: '1px solid white',
                        backgroundColor: 'red',
                        height: '20px',
                        width: '20px',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                      }}
                    >
                      x
                    </button>
                  </div>
                )}
                {filters && filters?.sector && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: '#008000',
                      color: 'white',
                      padding: '0.5rem',
                      borderRadius: '0.5rem',
                      gap: '0.5rem',
                    }}
                  >
                    <p>{filters?.sector?.sector}</p>
                    <button
                      onClick={() => setFilters({ ...filters, sector: null })}
                      className="ml-2 border"
                      style={{
                        border: '1px solid white',
                        backgroundColor: 'red',
                        height: '20px',
                        width: '20px',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                      }}
                    >
                      x
                    </button>
                  </div>
                )}
                &nbsp; &nbsp; &nbsp;
              </>
            )}
          </div>

          {openPersonDialog && (
            <PersonContext.Provider value={selectedPerson}>
              <PersonDetailsDialog open={openPersonDialog} onClose={() => setOpenPersonDialog(false)} />
            </PersonContext.Provider>
          )}
          {openLeadForm && <CreateLeadDialog open={openLeadForm} onClose={() => setOpenLeadForm(false)} />}
          {openAddJobPostDialog && (
            <JobPostLinkContext.Provider value={searchString}>
              <AddJobPostToLocalStorage
                open={openAddJobPostDialog}
                onClose={() => setOpenAddJobPostDialog(false)}
                postingLink={searchString}
                isJobPostFound={isJobPostFound}
              />
            </JobPostLinkContext.Provider>
          )}
          {openAddCompanyDialog && (
            <AddCompanyDialog open={openAddCompanyDialog} onClose={() => setOpenAddCompanyDialog(false)} />
          )}

          {openFiltersDialog && (
            <FilterDialog
              open={openFiltersDialog}
              onClose={() => setOpenFiltersDialog(false)}
              onApply={(applyFilters) => {
                setFilters(applyFilters);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}
