import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Typography, Box, Grow, Checkbox, Divider, Button } from '@mui/material';
import { Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { enqueueSnackbar } from 'notistack';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

export default function ShowEmailsDialog({ open, onClose, emails, onUpdation }) {
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [askConfirmation, setAskConfirmation] = useState(false);
  const [askToBounce, setAskToBounce] = useState(false);

  const handleCheckboxChange = (email) => {
    setSelectedEmails((prevSelected) => {
      if (prevSelected.includes(email.email_id)) {
        // Remove email from the array if it's already selected
        return prevSelected.filter((selected) => selected !== email.email_id);
      } else {
        // Add email to the array
        return [...prevSelected, email.email_id];
      }
    });
  };

  const updateEmailStatus = async (status) => {
    try {
        Post(
            {
                emails: selectedEmails,
                status : status
            },
            API_URLS.updateEmailStatus,
            (resp) =>{
                enqueueSnackbar('Emails status updated successfully', { variant: 'success' });
                onClose();
                onUpdation()
            },
            (error) =>{
                enqueueSnackbar('Error updating emails status', { variant: 'error' });
            }
        )
    } catch (error) {
        enqueueSnackbar('Somethign went wrong.', { variant: 'error' });
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '80%',
          minHeight: '20vh',
        },
      }}
      aria-describedby="alert-dialog-slide-description"
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <DialogTitle>
        <Box display="flex">
          <Typography variant="h4" className="ml-8 text-center">
            Person Email(s)
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <br />
        {!askConfirmation && !askToBounce ? (
          <>
            {emails && emails?.length > 0 ? (
              emails.map((email, index) => (
                <Box key={email?.id}>
                  <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ paddingY: '8px' }}>
                    {/* Checkbox on the left */}
                    <Checkbox
                      checked={selectedEmails.includes(email?.email_id)}
                      onChange={() => handleCheckboxChange(email)}
                    />

                    {/* Email address in the center */}
                    <Typography variant="body1" sx={{ flexGrow: 1, textAlign: 'center' }}>
                      {email.email_id}
                    </Typography>

                    {/* Status on the right */}
                    <Typography
                      variant="body2"
                      style={{
                        backgroundColor:
                          email?.status === 'verified'
                            ? '#b3ffb3'
                            : email?.status === 'not-verified'
                            ? '#7FE0FE'
                            : '#FFC9C9',
                        color:
                          email?.status === 'verified' ? 'green' : email?.status === 'not-verified' ? '#00b0e6' : 'red',
                        padding: '8px 10px',
                        fontWeight: 'bold',
                        marginLeft: '70px',
                      }}
                    >
                      {email.status}
                    </Typography>
                  </Box>
                  {/* Divider between email rows */}
                  {index < emails.length - 1 && <Divider />}
                </Box>
              ))
            ) : (
              <Typography variant="body1">No emails available.</Typography>
            )}
            <br />
            <Box display="flex" justifyContent="flex-end">
              <Button
                disabled={selectedEmails?.length === 0}
                color="primary"
                sx={{
                  width: '150px',
                  height: '40px',
                  border: '2px solid #00C1FE',
                  borderRadius: '10px',
                  color: '#ffffff',
                  backgroundColor: '#00C1FE',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#00C1FE',
                  },
                  '&:disabled': {
                    backgroundColor: 'rgba(0, 193, 254, 0.5)', // Dimmed background for disabled state
                    color: '#ffffff', // Text color remains white
                  },
                }}
                onClick={() => setAskConfirmation(true)}
              >
                Mark as verified
              </Button>
              <Button
                disabled={selectedEmails?.length === 0}
                color="primary"
                sx={{
                  width: '150px',
                  height: '40px',
                  border: '2px solid #00C1FE',
                  borderRadius: '10px',
                  marginLeft: '10px',
                  color: '#ffffff',
                  backgroundColor: '#00C1FE',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#00C1FE',
                  },
                  '&:disabled': {
                    backgroundColor: 'rgba(0, 193, 254, 0.5)', // Dimmed background for disabled state
                    color: '#ffffff', // Text color remains white
                  },
                }}
                onClick={() => setAskToBounce(true)}
              >
                Mark as bounced
              </Button>
            </Box>
          </>
        ) : (
          <>
            {askConfirmation && !askToBounce && (
              <>
                <Typography variant="h6" className="mt-8">
                  Are you sure you want to mark these emails as verified?
                </Typography>
                <br />
                <div className="flex justify-around flex-wrap mt-12">
                  <Button
                    onClick={() => setAskConfirmation(false)}
                    style={{
                      border: '2px solid black',
                      borderRadius: '10px',
                      width: '100px',
                      padding: '8px 16px',
                      textAlign: 'center',
                    }}
                  >
                    No
                  </Button>
                  <Button
                    style={{
                      backgroundColor: '#FA8282',
                      color: 'white',
                      borderRadius: '10px',
                      width: '100px',
                      padding: '8px 16px',
                      textAlign: 'center',
                    }}
                    onClick={() => updateEmailStatus('verified')}
                  >
                    Yes
                  </Button>
                </div>
              </>
            )}
            {askToBounce && !askConfirmation && (
              <>
                <Typography variant="h6" className="mt-8">
                  Are you sure you want to mark these emails as bounced?
                </Typography>
                <br />
                <div className="flex justify-around flex-wrap mt-12">
                  <Button
                    onClick={() => setAskToBounce(false)}
                    style={{
                      border: '2px solid black',
                      borderRadius: '10px',
                      width: '100px',
                      padding: '8px 16px',
                      textAlign: 'center',
                    }}
                  >
                    No
                  </Button>
                  <Button
                    style={{
                      backgroundColor: '#FA8282',
                      color: 'white',
                      borderRadius: '10px',
                      width: '100px',
                      padding: '8px 16px',
                      textAlign: 'center',
                    }}
                    onClick={() => updateEmailStatus('bounced')}
                  >
                    Yes
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </DialogContent>

      <br />
    </Dialog>
  );
}
