import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { PersonContext } from '../LeadGenerationSection';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import { Add, Email, Phone } from '@mui/icons-material';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { notify } from 'src/components/notify';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { AddJobPostDialog } from '.';
import { roleContext } from 'src/pages/leads/MainLeadGenerationPage';

const PersonDetailsCard = () => {
  const role = useContext(roleContext);
  const person = useContext(PersonContext);
  console.log('person', person);
  const [jobPosts, setJobPosts] = useState([]);
  const [jobPostsLoading, setJobPostsLoading] = useState(false);
  const [openAddJobPostDialog, setOpenAddJobPostDialog] = useState(false);

  const getJobPostsByPersonId = useCallback(() => {
    setJobPostsLoading(true);
    try {
      Get(
        { person_id: person?.id },
        API_URLS.getJobPostsByPersonId,
        (res) => {
          console.log('res', res);
          setJobPosts(res?.data);
          setJobPostsLoading(false);
        },
        (error) => {
          console.log(error);
          notify('Error in fetching job posts', 'error', 1000);
          setJobPostsLoading(false);
        }
      );
    } catch (error) {
      notify('Error in fetching job posts', 'error', 1000);
      setJobPostsLoading(false);
    }
  }, [person]);

  useEffect(() => {
      getJobPostsByPersonId();
  }, [getJobPostsByPersonId]);

  function formatTimestamp(isoString) {
    const date = new Date(isoString);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };

    return date.toLocaleString('en-US', options);
  }

  return (
    <div>
      <div className="shadow-md rounded-md p-2">
        <div className="flex justify-between p-4 bg-white cursor-pointer transition-colors duration-300 mt-4">
          <div className="flex items-center">
            <div className="flex-shrink-0 w-16 h-16 border-4 border-gray-300 rounded-full flex items-center justify-center">
              <div className="w-14 h-14 bg-gray-100 rounded-full flex items-center justify-center">
                {person?.avator !== null ? (
                  <img src={person?.avator} alt="Profile" className="w-50 h-50 rounded-full object-cover" />
                ) : (
                  <div className="w-14 h-14 bg-gray-100 rounded-full flex items-center justify-center">
                    {person?.first_name.charAt(0)}
                  </div>
                )}
              </div>
            </div>
            <div className="ml-4">
              <p className="text-lg font-semibold text-[#00C1FE]">
                {person?.first_name} {person?.last_name}
              </p>
              <p className="font-semibold text-lg">
                <sub>{person?.current_title}</sub>
              </p>
            </div>
          </div>
          <div>
            <Button
              variant="outlined"
              startIcon={<Add />}
              className="text-white border-[#00C1FE] min-w-[100px] w-full bg-[#00C1FE] hover:border-[#00C1FE] hover:bg-[#00C1FE]"
              style={{ outline: '1px solid rgba(0, 0, 0, 0.12)', backdropFilter: 'blur(4px)', marginRight: '4px' }}
              onClick={() => setOpenAddJobPostDialog(true)}
            >
              Add Post
            </Button>
          </div>
        </div>

        <div className="mt-2 mx-2">
          {role === 'admin' && (
            <>
              <div className="text-lg font-semibold">Contact Information</div>
              <div className="grid grid-cols-2 gap-4 mt-2">
                {person?.email?.length > 0 &&
                  person?.email?.map((email, index) => (
                    <>
                      {email.email_id && email.email_id !== '' && (
                        <Tooltip
                          key={`email-${index}`}
                          title={email.email_id}
                          style={{ backgroundColor: '#E7F3F7', height: '30px', width: '250px' }}
                        >
                          <div className="flex items-center p-2 bg-white border rounded-md shadow-md">
                            <Email className="icon-link" style={{ marginRight: '8px' }} />
                            <a href={`mailto:${email?.email_id}`} className="truncate">
                              {email?.email_id}
                            </a>
                          </div>
                        </Tooltip>
                      )}
                    </>
                  ))}

                {person?.phone_number?.length > 0 &&
                  person?.phone_number?.map((phone, index) => (
                    <>
                      {phone.phone_number && phone.phone_number !== '' && (
                        <Tooltip
                          key={`phone-${index}`}
                          title={phone.phone_number}
                          style={{ backgroundColor: '#E7F3F7', height: '30px', width: '250px' }}
                        >
                          <div className="flex items-center p-2 bg-white border rounded-md shadow-md">
                            <Phone className="icon-link" style={{ marginRight: '8px' }} />
                            <a href={`tel:${phone.phone_number}`} className="truncate">
                              {phone.phone_number}
                            </a>
                          </div>
                        </Tooltip>
                      )}
                    </>
                  ))}
              </div>
            </>
          )}
          {/* LinkedIn */}
          {person?.profile_url && (
            <div className="flex items-center mt-2">
              <Tooltip
                title={person?.profile_url}
                style={{ backgroundColor: '#E7F3F7', height: '30px', width: '250px' }}
              >
                <a
                  href={person?.profile_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="tooltip-link flex items-center p-2 bg-white border rounded-md shadow-md"
                >
                  <LinkedInIcon className="icon-link" style={{ fill: '#0072ea', marginRight: '8px' }} />
                  <span className="ml-1 truncate">{person?.profile_url}</span>
                </a>
              </Tooltip>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-left mt-4 text-lg font-semibold">
        <Icon icon="ep:avatar" width="1rem" height="1rem" className="text-black mt-1 mr-2" />
        <p>The Previous Job History</p>
      </div>
      <br />
      {jobPostsLoading ? (
        <CircularProgress />
      ) : jobPosts?.length > 0 ? (
        jobPosts.map((item) => (
          <div key={item.id} className="my-2 p-2 bg-white rounded-md border border-gray-400">
            <p>
              <a href={item?.job_posting_link} target="_blank" rel="noreferrer" className="text-[#00C1FE] text-xl">
                {item.job_title}
              </a>
              <br />
              {formatTimestamp(item.createdAt)}
            </p>
          </div>
        ))
      ) : (
        <h6 className="text-[#00C1FE]">No job posts found</h6>
      )}

      {openAddJobPostDialog && (
        <AddJobPostDialog
          open={openAddJobPostDialog}
          onClose={() => {
            setOpenAddJobPostDialog(false);
            getJobPostsByPersonId();
          }}
          person={person}
        />
      )}
    </div>
  );
};

export default PersonDetailsCard;
