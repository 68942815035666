import {
  Alert,
  Autocomplete,
  Box,
  Button,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Post, Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { Add, FilterList } from '@mui/icons-material';
import { EmailTemplate, PreviewTemplate, SignatureSection, TemplateCard } from './components';

const TemplatesMainSection = () => {
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [previewTemplate, setPreviewTemplate] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [toggleTemplates, setToggleTemplates] = useState('templates');

  const filterTemplates = [
    {
      id: 'BD Lead',
      label: 'New Leads Templates',
    },
    {
      id: 'Data Lead',
      label: 'Old Leads Templates',
    },
  ];

  const { enqueueSnackbar } = useSnackbar();

  const getAllTemplates = useCallback(() => {
    try {
      Get(
        { leadType: selectedFilter?.id },
        API_URLS.getTemplates,
        (res) => {
          console.log('restemp', res);
          setTemplates(res?.data);
        },
        (error) => {
          console.log(error);
          enqueueSnackbar('Error in fetching Templates', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Error in fetching Templates', { variant: 'error' });
    }
  }, [enqueueSnackbar, selectedFilter]);

  useEffect(() => {
    getAllTemplates();
  }, [getAllTemplates]);

  const addTemplate = (values, actions) => {
    try {
      Post(
        values,
        API_URLS.createTemplate,
        (resp) => {
          enqueueSnackbar('Template Created Successfully', { variant: 'success' });
          actions.resetForm();
          getAllTemplates()
        },
        (error) => {
          enqueueSnackbar('Error in creating Template', { variant: 'error' });
        }
      );
    } catch {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  return (
    <>
      <div className="h-full m-4">
        <div className="grid grid-cols-5 bg-white  items-center justify-center rounded-xl mx-2">
          <div className="grid grid-cols-subgrid gap-4 col-span-3">
            <Box className=" my-6 grid col-start-3 col-end-3">
              <ToggleButtonGroup
                color="primary"
                exclusive
                aria-label="Platform"
                className="rounded-lg py-1 items-center justify-center"
                style={{ backgroundColor: '#e1f7fc' }}
                onChange={(event, newValue) => {
                  setToggleTemplates(newValue);
                }}
              >
                <ToggleButton
                  value="templates"
                  style={{
                    backgroundColor: toggleTemplates === 'templates' ? 'white' : '#EEF7FF',
                    border: 'none',
                  }}
                  className="hover:bg-white rounded-3xl"
                >
                  Templates
                </ToggleButton>
                <ToggleButton
                  value="signatures"
                  style={{
                    backgroundColor: toggleTemplates === 'signatures' ? 'white' : '#EEF7FF',
                    border: 'none',
                  }}
                  className="hover:bg-white rounded-3xl"
                >
                  Signatures
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </div>
        </div>

        <br />
        {toggleTemplates === 'signatures' ? (
          <SignatureSection />
        ) : (
          <div className="flex flex-col md:flex-row gap-4 bg-white p-2">
            <div className="w-full md:w-1/3 ">
              <Button
                startIcon={<Add />}
                className="w-full px-6 py-4 text-white text-lg rounded-md bg-[#00C1FE] hover:bg-[#00C1FE]"
                onClick={() => setPreviewTemplate(false)}
              >
                Create New Template
              </Button>

              <Box className="w-full mt-4">
                <Autocomplete
                  options={filterTemplates}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => setSelectedFilter(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Filter Templates"
                      variant="outlined"
                      size="medium"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <FilterList style={{ color: '#00C1FE' }} />
                            {params.InputProps.startAdornment}
                          </>
                        ),
                      }}
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {},
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  )}
                />
              </Box>

              <div className="mt-4 rounded-lg">
                <div className="flex flex-col">
                  {templates?.templates?.length > 0 ? (
                    templates?.templates?.map((templateData) => (
                      <TemplateCard
                        key={templateData.id}
                        templateData={templateData}
                        onClick={() => {
                          setSelectedTemplate(templateData);
                          setPreviewTemplate(true);
                        }}
                      />
                    ))
                  ) : (
                    <Alert severity="info" style={{ marginTop: '10px' }}>
                      <Typography variant="h6">No Templates Found</Typography>
                    </Alert>
                  )}
                </div>
              </div>
            </div>

            <div className="w-full md:w-2/3">
              {!previewTemplate ? (
                <EmailTemplate onSubmit={(values, actions) => addTemplate(values, actions)} />
              ) : (
                <PreviewTemplate templateSubject={selectedTemplate?.subject} templateContent={selectedTemplate?.body} />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TemplatesMainSection;
