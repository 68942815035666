import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Slide, Typography } from '@mui/material';
import React from 'react';
import { Add, Close } from '@mui/icons-material';
import { PersonForm } from '.';
import { Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

export default function AddPersonDialog({ open, onClose, company, onPersonAdded }) {
  console.log('company', company);
  const [personValues, setPersonValues] = React.useState();
  const { enqueueSnackbar } = useSnackbar();
  const [formErrors, setFormErrors] = React.useState();
  const [errorMessages, setErrorMessages] = React.useState([]);
  const user_id = 1;
  const companyId = 1;

  const addPerson = () => {
    if (!company) {
      return;
    }
    if (personValues === undefined) {
      setErrorMessages(['Please Must be fill out Profile URL,First name,Title, Sector']);
      return;
    }

    if (formErrors) {
      setErrorMessages(['Please Must be fill out Profile URL,First name,Title, Sector']);
      return;
    }
    try {
      // company.id = company_id;
      personValues.user_id = user_id;
      personValues.company_id = company?.id;
      Post(
        personValues,
        API_URLS.addPerson,
        (res) => {
          console.log('res', res);

          enqueueSnackbar('Person added', { variant: 'success' });
          onClose();
          onPersonAdded();
        },
        (error) => {
          console.log('error', error);
          // Assuming error contains a response with a message field
          const errorMessage = error.response?.data?.message || 'Error in adding person';
          enqueueSnackbar(errorMessage, { variant: 'error' });
        
        }
      );
    } catch (error) {
      enqueueSnackbar('Failed to add person', { variant: 'error' });
    }
  };


  // console.log("formerrors", formErrors , personValues)
  console.log('personValuess', personValues);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      hideBackdrop={false}
      disableBackdropClick
      disableEscapeKeyDown
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          ml: 'auto',
          mt: 'auto',
          mr: '2rem',
          width: '900px',
          height: '70%',
        },
      }}
      aria-describedby="alert-dialog-grow-description"
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: '#F4F9FD',
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" className="ml-8">
            Add Person
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ backgroundColor: '#F4F9FD' }}>
        <PersonForm
          onSubmit={(values,fullName, actions) => {
            setPersonValues({ values,fullName });
          }}
          isError={(isValid) => {
            setFormErrors(isValid);
          }}
          onUpdate={(values) => {
            setPersonValues({ ...personValues, ...values });
          }}
        />
        <br />
        {errorMessages?.length > 0 && (
          <div style={{ color: 'red', marginBottom: '20px' }}>
            {errorMessages?.map((msg, index) => (
              <div key={index}>{msg}</div>
            ))}
          </div>
        )}
        <br />
        <div className="flex items-center justify-center mt-4">
          <Button
            variant="outlined"
            size="large"
            startIcon={<Add />}
            className="text-white border-[#00C1FE]  bg-[#00C1FE] hover:border-[#00C1FE] hover:bg-[#00C1FE]"
            style={{ outline: '1px solid rgba(0, 0, 0, 0.12)', backdropFilter: 'blur(4px)', marginRight: '1rem' }}
            onClick={() => {
              addPerson();
           
            }}
          >
            Add
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
