import { Box, Card, CardContent } from '@mui/material';
import React from 'react';

function JobPostCard({ job, onSelect }) {
    console.log(job);
  return (
    <>
      <Card style={{marginBottom:"5px"}}>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 4,
            }}
          >
            <img
              src={job?.company?.logo}
              alt="company avatar"
              height={80}
              width={80}
              style={{
                border: '1px solid #3f51b5',
                padding: 2,
                borderRadius: '50%',
                cursor: 'pointer',
              }}
              onClick={() => {
                onSelect(job);
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <h3
                style={{
                  color: '#3f51b5',
                  fontSize: 18,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  onSelect(job);
                }}
              >
                {job?.job_title}
              </h3>
              <p> {job?.company?.name}</p>
              <p> {job?.city}</p>
            </Box>
          </Box>
          <br />
          <hr
            style={{
              color: 'gray',
              backgroundColor: 'gray',
              height: 1,
              borderColor: 'gray',
              width: '85%',
              margin: 'auto',
            }}
          />
        </CardContent>
      </Card>
    </>
  );
}

export default JobPostCard;