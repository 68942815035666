import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grow, Typography } from '@mui/material';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

function AddEmailsFromSpreadSheetDialog({ open, onClose, onSubmit, isSubmittingEmails, isProcessingData }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '80%',
            minHeight: '20vh',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle>
          <Box display="flex">
            <Typography variant="h4" className="ml-8 text-center">
              Add Bulk Emails
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          {!isSubmittingEmails && !isProcessingData ? (
            <>
              <Typography variant="h6" className="mt-8">
                Are you sure , you want to submit these emails as verified?
              </Typography>
              <div className="flex justify-around flex-wrap mt-12">
                <Button
                  onClick={onClose}
                  style={{
                    border: '2px solid black',
                    borderRadius: '10px',
                    width: '100px',
                    padding: '8px 16px',
                    textAlign: 'center',
                  }}
                >
                  No
                </Button>
                <Button
                  style={{
                    backgroundColor: '#FA8282',
                    color: 'white',
                    borderRadius: '10px',
                    width: '100px',
                    padding: '8px 16px',
                    textAlign: 'center',
                  }}
                  onClick={() => onSubmit()}
                >
                  Yes
                </Button>
              </div>
            </>
          ) : (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Typography variant="h6" className="mt-8">
                Adding Emails...
              </Typography>
              <CircularProgress thickness={2} sx={{ marginTop: '10px' }} />
            </Box>
          )}
          {isProcessingData && (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Typography variant="h6" className="mt-8">
                Processing data...
              </Typography>
              <CircularProgress thickness={2} sx={{ marginTop: '10px' }} />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddEmailsFromSpreadSheetDialog;
