import { Close } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Slide, Typography } from '@mui/material';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { enqueueSnackbar } from 'notistack';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

export default function CompanyJobPostsDialog({ open, onClose, company }) {
  const [jobPosts, setJobPosts] = useState([]);
  const [jobPostsLoading, setJobPostsLoading] = useState(false);

  function formatTimestamp(isoString) {
    const date = new Date(isoString);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
    return date.toLocaleString('en-US', options);
  }

  function formatJobDetails(job) {
    const details = [];
    if (job.experience_level) details.push(` ${job.experience_level}`);
    if (job.job_type) details.push(`${job.job_type}`);
    if (job.remote) details.push(` ${job.remote}`);
    if (job.salary) details.push(` ${job.salary}`);
    return details.join(', ');
  }

  const getJobPostsOfCompany = () => {
    try {
      setJobPostsLoading(true);
      Get(
        {
          company_id: company?.id,
        },
        API_URLS.getJobPostsByCompanyId,
        (resp) => {
          setJobPosts(resp?.data);
        //   enqueueSnackbar('Job posts of the company fetched successfully', { variant: 'success' });
          setJobPostsLoading(false);
        },
        (error) => {
          enqueueSnackbar('Failed to get job posts of the company', { variant: 'error' });
          setJobPostsLoading(false);
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
      setJobPostsLoading(false);
    }
  };

  useEffect(() => {
    getJobPostsOfCompany();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={false}
        disableBackdropClick
        disableEscapeKeyDown
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            ml: 'auto',
            mt: 'auto',
            mr: '2rem',
            width: '900px',
            height: '70%',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#F4F9FD',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4" className="ml-8">
              Company Job Posts
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>

        <br />
        <DialogContent>
          {jobPostsLoading ? (
            <CircularProgress />
          ) : jobPosts?.length > 0 ? (
            jobPosts.map((item) => (
              <div key={item.id} className="my-2 p-2 bg-white rounded-md border border-gray-400">
                <p>
                  <a href={item?.job_posting_link} target="_blank" className="text-[#00C1FE] text-xl">
                    {item?.job_title}
                  </a>
                  <br />
                  <span className="text-gray-600">{formatJobDetails(item)}</span>
                  <br />
                  {formatTimestamp(item.createdAt)}
                </p>
              </div>
            ))
          ) : (
            <h5 className="text-[#00C1FE]">No job posts found</h5>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
