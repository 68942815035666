import React, { useEffect, useState } from 'react';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { Block, Campaign, DataUsage, EventNote, People, Person2Outlined, Settings } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import EventNoteIcon from '@mui/icons-material/EventNote';
import NavSection from 'src/components/nav-section/NavSection';
import { Icon } from '@iconify/react';

const isValidJSONObject = (obj) => obj && typeof obj === 'object' && !Array.isArray(obj);


// Fetch the features from localStorage and parse them.
const getFeatures = () => {
  const features = localStorage.getItem('features')
  try {
    return JSON.parse(features);
  } catch (error) {
    console.error('Error parsing features:', error);
    return [];
  }
};

const SidebarMenu = ({ userRole }) => {
  const [featuresAssigned, setFeaturesAssigned] = useState([]);

  useEffect(() => {
    // Fetch the features from local storage on component mount
    setFeaturesAssigned(getFeatures());
  }, []);

  const jobFinderAvailable = featuresAssigned.includes('Job Finder');
  const emailFinderAvailable = featuresAssigned.includes('Email Finder');

  const navConfig = [
    {
      title: 'Dashboard',
      path: '/leadExpert/app',
      icon: <AnalyticsIcon />,
    },
    // Conditionally render Lead Generation if any child is available
    (jobFinderAvailable || emailFinderAvailable) && {
      title: 'Lead Generation',
      path: '/leadExpert/leads',
      icon: <DataUsage />,
      children: [
        jobFinderAvailable && {
          title: 'Job Finder',
          path: '/leadExpert/findJobs',
          icon: <Settings />,
        },
        emailFinderAvailable && {
          title: 'Email Finder',
          path: '/leadExpert/findEmails',
          icon: <EventNote />,
        },
      ].filter(Boolean), // Filter out any null or undefined values
    },
  ].filter(Boolean); // Filter out any null or undefined values at the top level

  const navConfigAdmin = [
    {
      title: 'Dashboard',
      path: '/admin/app',
      icon: <AnalyticsIcon />,
    },
    {
      title: 'Lead Generation',
      path: '/admin/leads',
      icon: <DataUsage />,
    },
    {
      title: 'Candidates',
      path: '/admin/candidates',
      icon: <Person2Outlined />,
    },
    {
      title: 'All Campaigns',
      path: '/admin/campaigns',
      icon: <Campaign />,
      children: [
        {
          title: 'Campaign Settings',
          path: '/admin/create-campaign',
          icon: <SettingsIcon />,
        },
        {
          title: 'Templates',
          path: '/admin/templates',
          icon: <EventNoteIcon />,
        },
        {
          title: 'Black List',
          path: '/admin/blacklist',
          icon: <Block />,
        },
      ],
    },
    {
      title: 'Users',
      path: '/admin/users',
      icon: <People />,
    },
    {
      title: 'Resourcing',
      path: '/admin/resourcing',
      icon: <People />,
    },
  ];

  const navConfigACM = [
    {
      title: 'Dashboard',
      path: '/acm/app',
      icon: <Icon icon="clarity:dashboard-line" style={{fontSize:"20px"}}/>,
    },
    {
      title: 'Invoices',
      path: '/acm/invoices',
      icon: <Icon icon="material-symbols:bottom-sheets" style={{fontSize:"20px"}} />,
    },
    {
      title: 'Manage Roles',
      path: '/acm/roles',
      icon: <Icon icon="oui:app-users-roles" style={{fontSize:"20px"}} />,
    },
    {
      title: 'Manage Clients',
      path: '/acm/clients',
      icon: <Icon icon="ph:users"  style={{fontSize:"20px"}} />,
    },
  ];

  const defaultConfig = [
    {
      title: 'Not Found',
      path: '404',
    },
  ];

  // Render the NavSection component with the appropriate configuration
  return (
    <NavSection
      data={
        userRole === 'admin'
          ? navConfigAdmin
          : userRole === 'leadExpert'
          ? navConfig
          : userRole === 'accounts'
          ? navConfigACM
          : defaultConfig
      }
    />
  );
};

export default SidebarMenu;
