import React, { useState } from 'react';
import { formatDistanceToNow } from 'date-fns';

const MAX_LENGTH = 30; // Maximum length for title and subject before truncation

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return formatDistanceToNow(date, { addSuffix: true });
};

const truncateText = (text, maxLength) => {
  if (!text || text.length <= maxLength) return text;
  return text.slice(0, maxLength) + '...';
};

export default function TemplateCard({ templateData, onClick }) {
  const [isExpanded, setIsExpanded] = useState(false); // Default to false to show truncated text

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      onClick={onClick}
      className="m-2 rounded-lg p-2 bg-[#e1f7fc] border-[#00C1FE] border-[1px] h-28 cursor-pointer w-full"
      style={{ maxWidth: '100%' }}
    >
      <div className="flex flex-col h-full">
        <div className="flex items-center text-xs mb-1">
          <div className="font-bold mr-2">Title:</div>
          <p className="text-ellipsis overflow-hidden flex-1" style={{ fontSize: '0.85rem' }}>
            {truncateText(templateData?.title, MAX_LENGTH)}
          </p>
        </div>
        <div className="flex flex-col items-start text-xs mb-1">
          <div className="font-bold mr-2">Subject:</div>
          <p
            className={`overflow-hidden flex-1 ${isExpanded ? '' : 'text-ellipsis'} `}
            style={{ fontSize: '0.85rem', maxHeight: isExpanded ? 'none' : '1.2em' }}
          >
            {isExpanded ? templateData?.subject : truncateText(templateData?.subject, MAX_LENGTH)}
          </p>
          {templateData?.subject.length > MAX_LENGTH && (
            <button
              onClick={handleToggle}
              className="text-blue-500 mt-1 text-xs"
              style={{ cursor: 'pointer' }}
            >
              {isExpanded ? 'Show less' : 'Show more'}
            </button>
          )}
        </div>
        <div className="flex items-center text-xs">
          <p className="text-ellipsis overflow-hidden flex-1 font-bold">{formatDate(templateData?.createdAt)}</p>
        </div>
      </div>
    </div>
  );
}
