import { Icon } from '@iconify/react';
import { Box, Card, Typography } from '@mui/material';
import React from 'react';

function ScrapperStatsCard({ mainLabel, leftLabel, rightLabel, mainNumber, leftNumber, rightNumber }) {
  return (
    <Card
      sx={{
        py: 2,
        marginLeft: '5px',
        boxShadow: 0,
        display: 'flex',
        height: '150px',
        flexDirection: 'column',
        textAlign: 'center',
        backgroundColor: 'white',
        borderRadius: '12px',
        border: '1px solid #EFEFF3',
        padding: '40px',
      }}
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box display="flex" flexDirection="column" alignItems="center" mx={1} justifyContent="center" width="120px">
          <Typography variant="body" sx={{ fontSize: '14px', mb: 1, fontWeight: 'bold' }}>
            {mainLabel}
          </Typography>
          <Typography variant="subtitle1" sx={{ fontSize: '15px', fontWeight: 'bold' }}>
            {mainNumber}
          </Typography>
        </Box>

        <Icon icon="pepicons-pop:equal" style={{ fontSize: '18px', marginTop: '18px' }} />
        <Box display="flex" flexDirection="column" alignItems="center" mx={1} justifyContent="center" width="120px">
          <Typography variant="body" sx={{ fontSize: '14px', mb: 1, fontWeight: 'bold' }}>
            {leftLabel}
          </Typography>
          <Typography variant="subtitle1" sx={{ fontSize: '15px', fontWeight: 'bold' }}>
            {leftNumber}
          </Typography>
        </Box>
        <Icon icon="tabler:plus" style={{ fontSize: '18px', marginTop: '18px' }} />

        <Box display="flex" flexDirection="column" alignItems="center" mx={1} justifyContent="center" width="120px">
          <Typography variant="body" sx={{ fontSize: '14px', mb: 1, fontWeight: 'bold' }}>
            {rightLabel}
          </Typography>
          <Typography variant="subtitle1" sx={{ fontSize: '15px', fontWeight: 'bold' }}>
            {rightNumber}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

export default ScrapperStatsCard;
