import { Box, Typography } from '@mui/material';
import React from 'react';
import { Morning } from './images';

function ResourcingCandidateSearch(props) {
  const showGreetings = () => {
    // Get the current time
    const now = new Date();
    const hours = now.getHours(); // Get the current hour (0-23)

    let greeting;

    // Determine the greeting based on the time of day
    if (hours >= 5 && hours < 12) {
      greeting = 'Good Morning,';
    } else if (hours >= 12 && hours < 17) {
      greeting = 'Good Afternoon,';
    } else if (hours >= 17 && hours < 21) {
      greeting = 'Good Evening,';
    } else {
      greeting = 'Good night,';
    }
    return greeting;
  };

  const message = showGreetings();
  return (
    <div>
      <div
        style={{
          width: '95%',
          margin: 'auto',
          backgroundColor: 'white',
          border: '10px solid white',
          borderRadius: '10px',
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <img src={Morning} alt="morning" height="350px" width="350px" />
          <Typography  sx={{ marginTop : "20px" , fontSize:"32px" }}>{message} John</Typography>
        </Box>
      </div>
    </div>
  );
}

export default ResourcingCandidateSearch;
