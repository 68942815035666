import React from 'react';
import { Roles } from 'src/sections/acm/role-section';

function ACMManageRoles(props) {
    return (
        <div>
            <Roles />
        </div>
    );
}

export default ACMManageRoles;