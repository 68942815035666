import React, { useEffect, useState } from 'react';
import { Editor } from 'primereact/editor';

export default function QuillEditor({ htmlValue, onTextChange }) {
  const [text, setText] = useState();

  useEffect(() => {
    setText(htmlValue);
  }, [htmlValue]);
  return (
    <div className="card">
      <Editor
        value={text}
        onTextChange={(e) => {
          setText(e.textValue);
          onTextChange(e.htmlValue);
        }}
        style={{ height: '500px' }}
        
      />
    </div>
  );
}

// docs url https://primereact.org/editor/