import React, { useState } from 'react';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, Typography } from '@mui/material';
import { Icon } from '@iconify/react';

function LITable(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page whenever rows per page changes
  };

  const Lis = [
    {
      li: 'abc.http.com',
      status: 'pending',
    },
    {
      li: 'abc.http.com',
      status: 'pending',
    },
    {
      li: 'abc.http.com',
      status: 'pending',
    },
    {
      li: 'abc.http.com',
      status: 'pending',
    },
  ];

  return (
    <div>
      <Paper
        sx={{
          width: '100%',
          overflowX: 'auto',
          position: 'relative',
          display: 'block',
          maxWidth: '100%',
          backgroundColor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
          border: '1px solid #D9D9D9',
          borderRadius: '10px',
          '& td, & th': {
            whiteSpace: 'nowrap',
            borderBottom: '1px solid #D9D9D9',
          },
        }}
      >
        <TableContainer>
          <Table aria-labelledby="tableTitle">
            <TableHead>
              <TableRow style={{ backgroundColor: '#E1F7FC' }}>
                <TableCell
                  style={{
                    color: '#00C1FE',
                    fontWeight: 'bold',
                    fontSize: '20px',
                  }}
                >
                  LIs
                </TableCell>
                <TableCell
                  style={{
                    color: '#00C1FE',
                    fontWeight: 'bold',
                    fontSize: '20px',
                  }}
                >
                  {' '}
                  Status
                </TableCell>
                <TableCell
                  style={{
                    color: '#00C1FE',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  Actions
                </TableCell>
                <TableCell>
                  <Button
                    sx={{
                      background: '#00C1FE',
                      color: '#fff',
                      width: '110px',
                      height: '40px',
                      border: '1px solid #00C1FE',
                      '&:hover': {
                        background: '#00C1FE',
                        color: 'white',
                      },
                      borderRadius: '5px',
                      padding: '5px',
                    }}
                  >
                    Add LI
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Lis.map((li) => (
                <TableRow>
                  <TableCell>{li.li}</TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        backgroundColor: '#FFEFCA',
                        color: '#FDC748',
                        padding: '5px',
                        width: '105px',
                        margin: '5px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        display: 'flex', // Flexbox layout
                        alignItems: 'center', // Align items horizontally in the center
                        justifyContent: 'center', // Center content horizontally
                      }}
                    >
                      <Icon icon="fluent-mdl2:radio-bullet" style={{ marginRight: '5px', fontSize: '20px' }} />{' '}
                      {li.status}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" justifyContent="space-around">
                      <Button
                        sx={{
                          backgroundColor: '#E1F7FC',
                          height: '34px',
                          width: '15px',
                          '&:hover': {
                            backgroundColor: '#E1F7FC',
                          },
                          borderRadius: '5px',
                        }}
                      >
                        {' '}
                        <Tooltip title="Reject LI" arrow placement="top">
                          <Icon
                            icon="bitcoin-icons:cross-filled"
                            style={{ fontSize: '22px', color: '#0EC4FE', fontWeight: 'bold' }}
                          />
                        </Tooltip>
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: '#E1F7FC',
                          height: '34px',
                          width: '15px',
                          '&:hover': {
                            backgroundColor: '#E1F7FC',
                          },
                          borderRadius: '5px',
                        }}
                      >
                        {' '}
                        <Tooltip title="Accept LI" arrow placement="top">
                          <Icon icon="mdi:tick" style={{ fontSize: '22px', color: '#0EC4FE', fontWeight: 'bold' }} />
                        </Tooltip>
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: '#E1F7FC',
                          height: '34px',
                          width: '15px',
                          '&:hover': {
                            backgroundColor: '#E1F7FC',
                          },
                          borderRadius: '5px',
                        }}
                      >
                        {' '}
                        <Tooltip title="Edit LI" arrow placement="top">
                          <Icon
                            icon="material-symbols-light:edit-outline"
                            style={{ fontSize: '22px', color: '#0EC4FE', fontWeight: 'bold' }}
                          />
                        </Tooltip>
                      </Button>
                    </Box>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalRows} // The total number of records (from server)
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

export default LITable;
