import { Button, Typography, Grid, Autocomplete, TextField, Box, ToggleButtonGroup, ToggleButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Visibility } from '@mui/icons-material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { API_URLS } from 'src/constants/apiURLs';
import { Get } from 'src/actions/API/apiActions';
import HTMLEditor from './HTMLEditor';
import { PreviewTemplate } from '.';

const EmailTemplate = ({ onSubmit }) => {
  const [sectors, setSectors] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [subject, setSubject] = useState(null);
  const [templateBody, setTemplateBody] = useState();

  const dataOptions = [
    {
      id: 'BD Lead',
      label: 'BD Lead',
    },
    {
      id: 'Data Lead',
      label: 'Data Lead',
    },
  ];

  const getAllSectors = () => {
    try {
      Get(
        {},
        API_URLS.getAllSectors,
        (response) => {
          setSectors(response.data);
        },
        (error) => {
          console.log('error', error);
        }
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getAllSectors();
  }, []);

  const initialValue = {
    leadType: '',
    sector_id: '',
    title: '',
    subject: '',
    body: null,
    selectedTag: null,
  };

  const defaultValues = initialValue;
  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    subject: Yup.string().required('Subject is required'),
    sector_id: Yup.string().required('Sector is required'),
    leadType: Yup.string().required('DataType is required'),
    body: Yup.string().required('Body is required'),
  });
  return (
    <>
      <div >
        {showPreview ? (
          <PreviewTemplate templateSubject={subject} templateContent={templateBody} />
        ) : (
          <>
            <Formik
              enableReinitialize
              initialValues={defaultValues}
              validationSchema={ValidationSchema}
              onSubmit={(values, actions) => {
                console.log('tyyutu', values);
                onSubmit(values, actions);
              }}
            >
              {({ errors, values, setFieldValue, touched, handleBlur, handleSubmit }) => (
                <Form>
                  <br />

                  <Grid container spacing={2} mb={1}>
                    <div className="bg-white shadow-md w-full ml-4 pb-6 ">
                      <div className="flex justify-between  pb-4">
                        <div>
                          <Typography className="font-bold text-xl ml-4">Template Details</Typography>
                        </div>
                        <div className="">
                          <Button
                            startIcon={<Visibility />}
                            className="bg-[#e1f7fc] text-[#00C1FE] px-2 mr-6"
                            onClick={() => setShowPreview(!showPreview)}
                          >
                            Preview
                          </Button>
                        </div>
                      </div>
                      <Grid item xs={12} sm={12} md={12} lg={12} className="mx-6">
                        <TextField
                          id="title"
                          name="title"
                          value={values.title}
                          type="string"
                          onChange={(e) => {
                            setFieldValue('title', e.target.value);
                          }}
                          placeholder="Enter Title"
                          size="medium"
                          error={touched.title && Boolean(errors.title)}
                          helperText={touched.title && errors.title}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>
                    </div>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="mx-6">
                      <label htmlFor="sector_id" style={{ fontWeight: 'bold' }}>
                        Sector
                      </label>
                      <Autocomplete
                        options={sectors}
                        getOptionLabel={(option) => option.sector}
                        onChange={(event, value) => {
                          setFieldValue('sector_id', value ? value.id : null);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Sector"
                            onBlur={handleBlur}
                            error={touched.sector_id && Boolean(errors.sector_id)}
                            helperText={touched.sector_id && errors.sector_id}
                            fullWidth
                            sx={{
                              backgroundColor: 'white',
                              borderRadius: '0.3rem',
                              '& .MuiOutlinedInput-root': {
                                '&:hover fieldset': {
                                  borderColor: '#00C1FE',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#00C1FE',
                                },
                              },
                              '& .MuiInputBase-root': {
                                '&.Mui-focused': {
                                  boxShadow: '0 0 0 1px #00C1FE',
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="mx-6">
                      <label htmlFor="subject">Lead Type</label>
                      <Autocomplete
                        id="leadType"
                        size="medium"
                        options={dataOptions}
                        getOptionLabel={(option) => option?.label}
                        onChange={(event, newValue) => {
                          setFieldValue('leadType', newValue.id || null);
                        }}
                        renderInput={(params) => (
                          <TextField
                            id="leadType"
                            {...params}
                            variant="outlined"
                            error={touched.leadType && Boolean(errors.leadType)}
                            helperText={touched.leadType && errors.leadType}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} className="mx-6">
                      <label htmlFor="subject">Subject</label>
                      <TextField
                        id="subject"
                        name="subject"
                        value={values.subject}
                        type="string"
                        onChange={(e) => {
                          setFieldValue('subject', e.target.value);
                          setSubject(e.target.value);
                        }}
                        size="medium"
                        error={touched.subject && Boolean(errors.subject)}
                        helperText={touched.subject && errors.subject}
                        fullWidth
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#00C1FE',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#00C1FE',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #00C1FE',
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="mx-6">
                      <Box className="w-full content-start m-2">
                        <HTMLEditor
                          showTags={true}
                          editorLabel="Email Body"
                          htmlText={values?.body || null}
                          insertTag={values.selectedTag}
                          onEditorStateChange={(html) => {
                            setFieldValue('body', html?.data);
                            setTemplateBody(html?.data);
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <div className=" flex justify-end mt-4 ">
                    <Button
                      className="py-2 text-white text-lg rounded-md bg-[#00C1FE] hover:bg-[#00C1FE] mr-4"
                      onClick={handleSubmit}
                    >
                      Add Template
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
      </div>
    </>
  );
};

export default EmailTemplate;
