import React from 'react';
import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { allServices } from 'src/constants/constants';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

const AddNewService = ({ open, onClose }) => {
  //   const [filteredServices, setFilteredServices] = useState([]);
  //   const [services, setServices] = useState([]);
  //   const getServices = useCallback(() => {
  //     try {
  //       Post(
  //         {},
  //         Post_GetAllServices_URL,
  //         (resp) => {
  //           setServices(resp);
  //           let newServices = services.filter((service) => {
  //             resp.forEach((s) => service.label !== s.serviceName);
  //           });
  //           setFilteredServices(newServices);
  //         },
  //         (error) => {}
  //       );
  //     } catch (error) {}
  //   }, []);
  const initialValues = {
    serviceName: '',
    minId: '',
    maxId: '',
  };
  //   const [maxIdRecord, setMaxIdRecord] = useState(null);
  const maxIdRecord = 0;
  const services = [['service1', 'service2']];
  //   const getMaxServiceId = useCallback(() => {
  //     try {
  //       Post(
  //         {},
  //         Post_GetMaxId_URL,
  //         (resp) => {
  //           setMaxIdRecord(resp?.maxId);
  //         },
  //         (error) => {
  //           notify('Can not get Max Is record', 'error', 1000);
  //         }
  //       );
  //     } catch (error) {
  //       notify('Something went wrong at server', 'error', 1000);
  //     }
  //   }, [notify]);
  //   useEffect(() => {
  //     if (open) {
  //       getMaxServiceId();
  //       getServices();
  //     }
  //   }, [open, getMaxServiceId, getServices]);

  const handleAddService = (values, actions) => {
    console.log('service addded');
    // try {
    //   Post(
    //     values,
    //     Post_AddService_URL,
    //     (resp) => {
    //       notify('Service added', 'success', 1000);
    //       onClose();
    //     },
    //     (error) => {
    //       if (error?.message?.includes('406')) {
    //         notify('Invalid Id Range', 'error', 1000);
    //       } else if (error?.message?.includes('409')) {
    //         notify('This service is already been added', 'error', 1000);
    //       } else {
    //         notify('Failed to  add service', 'error', 1000);
    //       }
    //     }
    //   );
    // } catch (error) {
    //   notify('Something went wrong at server', 'error', 1000);
    // }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          minHeight: '25vh',
        },
      }}
      aria-describedby="alert-dialog-slide-description"
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
        },
      }}
    >
      <DialogTitle style={{ backgroundColor: '#D1F3FA', width: '100%' }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">Add New Service</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            handleAddService(values, actions);
          }}
        >
          {({ values, errors, getFieldProps, setFieldValue, touched, handleSubmit, isSubmitting }) => (
            <Form>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Autocomplete
                    id="serviceName"
                    options={allServices || []}
                    size="large"
                    fullWidth
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newService) => {
                      setFieldValue('serviceName', newService?.label);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Service"
                        fullWidth
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          marginBottom: '3px',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#00C1FE',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#00C1FE',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #00C1FE',
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="minId"
                    type="number"
                    placeholder={`Enter start client number (you can start from ${maxIdRecord + 1})`}
                    size="medium"
                    fullWidth
                    {...getFieldProps('minId')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      marginBottom: '3px',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="maxId"
                    type="number"
                    placeholder={`Enter last client number (suggested client number ${maxIdRecord + 1000})`}
                    size="medium"
                    fullWidth
                    {...getFieldProps('maxId')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      marginBottom: '3px',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Box display="flex" justifyContent="flex-end" marginTop="1rem">
                <Button
                  onClick={onClose}
                  size="medium"
                  sx={{
                    backgroundColor: '#A3A3A3',
                    fontSize: '16px',
                    color: 'white',
                    marginLeft: '1rem',
                    '&:hover': {
                      color: 'white !important',
                      backgroundColor: '#A3A3A3 !important',
                    },
                  }}
                >
                  Cancle
                </Button>
                <Button
                  onClick={handleSubmit}
                  size="medium"
                  sx={{
                    backgroundColor: '#0ec4fe',
                    fontSize: '16px',
                    color: 'white',
                    marginLeft: '1rem',
                    '&:hover': {
                      color: 'white !important',
                      backgroundColor: '#0ec4fe !important',
                    },
                  }}
                >
                  Add Service
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
        {services && (
          <>
            <Typography variant="h6">List of added Services</Typography>
            <Box display="flex" marginTop="15px" marginLeft="1rem">
              <ul style={{ fontWeight: '600' }}>
                {allServices?.map((service) => {
                  return <li style={{ paddingBottom: '10px' }}>{service?.label}</li>;
                })}
              </ul>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default AddNewService;
