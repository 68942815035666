import { Icon } from '@iconify/react';
import { Box, TextField, FormControlLabel, Checkbox, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';

const CustomDropdown = ({ options, selectedOptions, onSelect, anchorEl, onClose, open }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = (option) => {
    const newSelectedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];
    onSelect(newSelectedOptions);
  };

  const handleSearchSelection = (option) => {
    const newSelectedOptions = [option, ...selectedOptions];
    onSelect(newSelectedOptions);
    setSearchTerm('');
    setFilteredOptions([]);
  };

  const handleShowResults = () => {
    onSelect(selectedOptions);
    onClose(); // Close the dropdown after selecting options
  };

  const handleReset = () => {
    onSelect([]);
    setSearchTerm('');
    setFilteredOptions([]);
    onClose(); // Close the dropdown after resetting options
  };

  useEffect(() => {
    if (searchTerm) {
      const filteredOptions = options.filter((option) =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredOptions(filteredOptions);
    } else {
      setFilteredOptions([]);
    }
  }, [searchTerm, options]);

  // Combine selected options and remaining options
  const combinedOptions = [...new Set([...selectedOptions, ...options])];

  // Display the first 5 options with selected options shown first
  const displayedOptions = combinedOptions
    .filter((option) => selectedOptions.includes(option) || !filteredOptions.includes(option))
    .slice(0, 5);

  return (
    <Box
      sx={{
        height: '350px',
        width: '320px',
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '16px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        position: 'absolute',
        top: anchorEl ? anchorEl.getBoundingClientRect().bottom : 0,
        left: anchorEl ? anchorEl.getBoundingClientRect().left : 0,
        zIndex: 1000,
        overflowY: 'auto',
        marginTop: '5px',
        display: open ? 'block' : 'none' // Show or hide the dropdown based on open prop
      }}
    >
      <TextField
        InputProps={{
          startAdornment: (
            <Icon icon="mingcute:search-line" style={{ fontSize: '24px', fontWeight: 'bold', marginRight: '8px' }} />
          ),
        }}
        variant="outlined"
        placeholder="Search..."
        onChange={handleSearch}
        value={searchTerm}
        size="small"
        sx={{
          width: '100%',
          backgroundColor: 'white',
          borderRadius: '0.3rem',
          marginBottom: '10px',
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: '#00C1FE',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#00C1FE',
            },
          },
          '& .MuiInputBase-root': {
            '&.Mui-focused': {
              boxShadow: '0 0 0 1px #00C1FE',
            },
          },
        }}
      />
      {searchTerm ? (
        <ul>
          {filteredOptions.map((option) => (
            <li
              key={option}
              onClick={() => handleSearchSelection(option)}
              style={{ cursor: 'pointer', padding: '5px 0' }}
            >
              {option}
            </li>
          ))}
        </ul>
      ) : (
        <Box>
          {displayedOptions.map((option) => (
            <Box key={option}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedOptions.includes(option)}
                    onChange={() => handleCheckboxChange(option)}
                    sx={{
                      color: '#B0BEC5', // Default color
                      '&.Mui-checked': {
                        color: '#00C1FE', // Color when checked
                      },
                    }}
                  />
                }
                label={option}
              />
            </Box>
          ))}
        </Box>
      )}
      <hr className="divider" />
      <Box display="flex" justifyContent="flex-end" marginTop="10px">
        <Button
          onClick={() => {
            if (selectedOptions.length > 0) {
              handleReset();
            } else {
              onClose(); // Close the dropdown without resetting
            }
          }}
          variant="outlined"
          size="small"
          sx={{ color: 'grey', fontSize: '10px' }}
        >
          {selectedOptions.length > 0 ? 'Reset' : 'Cancel'}
        </Button>
        <Button
          onClick={handleShowResults}
          variant="contained"
          size="small"
          sx={{
            color: 'white',
            backgroundColor: '#00C1FE',
            borderRadius: '5px',
            padding: '5px',
            marginLeft: '5px',
            fontSize: '12px',
            '&:hover': {
              backgroundColor: '#00C1FE',
            },
          }}
        >
          Show Results
        </Button>
      </Box>
    </Box>
  );
};

export default CustomDropdown;
