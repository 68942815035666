import { Box, Button, Card, CardContent, Grid, Popover, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../../../App.css';
import { AddPersonDialog, AssociateJobToCompanyDialog, CompanyJobPostsDialog } from '.';
import {
  CAFlag,
  company_direct,
  company_icon,
  company_sr,
  database_icon,
  followers_icon,
  internet_icon,
  jobs_icon,
  linkedin_icon,
  UAEFlag,
} from './svg';
import { UKFlag, USFlag } from 'src/assets/images';
import { Icon } from '@iconify/react';

export default function CompanyDetailsCard({ company, onReload }) {
  const [openAddPersonDialog, setOpenAddPersonDialog] = useState(false);
  const [associateJobPostForm, setAssociateJobPostForm] = useState(false);
  const [loadPersons, setLoadPersons] = useState(false);
  const [openJobPostsDialog, setOpenJobPostsDialog] = useState(false);
  // const [openCompanyDialog, setOpenCompanyDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (loadPersons) {
      onReload();
    }
  }, [loadPersons, onReload]);

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={2} lg={1}>
              {/* if there is company logo and its src contains http in it show logo other wise show company initials */}
              {company?.logo && company?.logo.includes('http') ? (
                <img
                  src={company.logo}
                  alt="company logo"
                  height={100}
                  width={100}
                  style={{
                    objectFit: 'contain',
                    borderRadius: '50%',
                    border: '1px solid #ccc',
                    cursor: 'pointer',
                  }}
                  onClick={() => window.open(company?.profile_url, '_blank')}
                />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '120px',
                    width: '120px',
                    borderRadius: '50%',
                    border: '1px solid #ccc',
                    backgroundColor: '#f3f3f3',
                    fontSize: '2rem',
                    color: '#333',
                    cursor: 'pointer',
                  }}
                  onClick={() => window.open(company?.profile_url, '_blank')}
                >
                  {/* {company?.name?.split(' ')[0].charAt(0) + company?.name?.split(' ')[1].charAt(0)} */}
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={11}>
              <Box display="flex" flexDirection="column" justifyContent="flex-start">
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    variant="h5"
                    component="h2"
                    onClick={() => {
                      setOpenJobPostsDialog(true);
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    {company?.name?.split(' ').length > 1
                      ? company?.name?.split(' ')[0] + ' ' + company?.name?.split(' ')[1]
                      : company?.name}
                  </Typography>
                  <Box flexGrow={1} />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '5px',
                      marginRight: '5px',
                      cursor: 'pointer',
                    }}
                  >
                    <Tooltip title="Followers" placement="top">
                      <img src={followers_icon} alt="email" height={15} width={15} />
                    </Tooltip>
                    &nbsp;&nbsp;
                    <p
                      style={{
                        fontSize: '15px',
                      }}
                    >
                      {company?.follower_count}
                    </p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '5px',
                      marginRight: '5px',
                    }}
                  >
                    <Tooltip title="CRM Count" placement="top">
                      <img src={database_icon} alt="email" height={15} width={15} />
                    </Tooltip>
                    &nbsp;&nbsp;
                    <p
                      style={{
                        fontSize: '15px',
                      }}
                    >
                      {company?.peopleCount}
                    </p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '5px',
                      marginRight: '5px',
                    }}
                  >
                    <Tooltip title="Associcated on Linkedin" placement="top">
                      <img src={linkedin_icon} alt="email" height={15} width={15} />
                    </Tooltip>
                    &nbsp;&nbsp;
                    <p
                      style={{
                        fontSize: '15px',
                      }}
                    >
                      {company?.staff_count}
                    </p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '5px',
                      // borderRadius: '5px',
                      // border: '1px solid #ccc',
                      marginRight: '5px',
                    }}
                  >
                    <Tooltip title="Jobs found" placement="top">
                      <img src={jobs_icon} alt="email" height={15} width={15} />
                    </Tooltip>
                    &nbsp;&nbsp;
                    <p
                      style={{
                        fontSize: '15px',
                      }}
                    >
                      {company?.jobPostsCount}
                    </p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '5px',
                      // borderRadius: '5px',
                      // border: '1px solid #ccc',
                      marginRight: '5px',
                    }}
                  >
                    <Tooltip title="Company Size" placement="top">
                      <img src={company_icon} alt="email" height={15} width={15} />
                    </Tooltip>
                    &nbsp;&nbsp;
                    <p
                      style={{
                        fontSize: '15px',
                      }}
                    >
                      {company?.actual_range}
                    </p>
                  </div>
                  {/* </Box> */}
                  <button
                    style={{
                      background: '#00C1FE',
                      width: '100px',
                      height: '50px',
                      color: 'white',
                      padding: '5px',
                      borderRadius: '5px',
                    }}
                    onClick={() => setAssociateJobPostForm(true)}
                  >
                    + Add Job
                  </button>
                  &nbsp;&nbsp;
                  <button
                    style={{
                      background: '#00C1FE',
                      width: '150px',
                      height: '50px',
                      color: 'white',
                      padding: '5px',
                      borderRadius: '5px',
                    }}
                    onClick={() => setOpenAddPersonDialog(true)}
                  >
                    + Add Person
                  </button>
                </Box>
                <p>{company?.industry + ', ' + company?.address}</p>
                <p>{company?.tagline?.substring(0, 90)}...</p>
              </Box>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" mt={2} gap={2}>
            <div
              style={{
                background: '#EBF7FA',
                width: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '5px',
                borderRadius: '5px',
              }}
            >
              {console.log('hjdfdfdf', company)}
              <img
                src={
                  company?.country_id === 1
                    ? UKFlag
                    : company?.country_id === 2
                    ? USFlag
                    : company?.country_id === 3
                    ? CAFlag
                    : UAEFlag
                }
                alt="email"
                height={20}
                width={20}
              />
              &nbsp;&nbsp;
              <p
                style={{
                  fontSize: '15px',
                }}
              >
                {company?.country?.country}
              </p>
            </div>
            <div
              style={{
                background: '#EBF7FA',
                width: '70px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '5px',
                borderRadius: '5px',
              }}
            >
              <img src={company?.sector_id === 1 ? company_direct : company_sr} alt="email" height={20} width={20} />
              &nbsp;&nbsp;
              <p
                style={{
                  fontSize: '15px',
                }}
              >
                {company?.sector_id === 1 ? '360' : 'S&R'}
              </p>
            </div>
            <div
              style={{
                background: '#EBF7FA',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '5px',
                borderRadius: '5px',
              }}
            >
              <img src={linkedin_icon} alt="email" height={20} width={20} />
              &nbsp;&nbsp;
              <p
                style={{
                  fontSize: '15px',
                  cursor: company?.profile_url?.includes('http') ? 'pointer' : 'not-allowed',
                }}
                onClick={() => window.open(company?.profile_url, '_blank')}
              >
                {company?.profile_url?.substring(0, 50)}...
              </p>
            </div>
            <div
              style={{
                background: '#EBF7FA',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '5px',
                borderRadius: '5px',
              }}
            >
              <img src={internet_icon} alt="email" height={20} width={20} />
              &nbsp;&nbsp;
              <p
                style={{
                  fontSize: '15px',
                  cursor: company?.profile_url?.includes('http') ? 'pointer' : 'not-allowed',
                }}
                onClick={() => {
                  if (company?.website?.includes('http')) window.open(company?.website, '_blank');
                }}
              >
                {company?.website?.includes('http') ? company?.website?.substring(0, 50) + '...' : 'Not Available'}
              </p>
            </div>
            <Button
              onClick={handleClick}
              style={{
                background: '#EBF7FA',
                borderRadius: '5px',
                padding: '5px',
                height: '50px',
                width: '150px',
              }}
            >
              Contact Info &nbsp; <Icon icon="ep:arrow-down" />
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              // sx={{
              //   width: '180px',
              // }}
            >
              <Typography sx={{ p: 2 }}>
                {company?.phone_number === '' ? 'Phone number : N/A' : company?.phone_number}
              </Typography>
              <Typography sx={{ p: 2 }}>
                {company?.company_email === '' ? 'Email : N/A' : company?.company_email}
              </Typography>
            </Popover>
          </Box>
        </CardContent>
      </Card>
      {openAddPersonDialog && (
        <AddPersonDialog
          open={openAddPersonDialog}
          onClose={() => setOpenAddPersonDialog(false)}
          company={company}
          onPersonAdded={() => setLoadPersons(true)}
        />
      )}
      {associateJobPostForm && (
        <AssociateJobToCompanyDialog
          open={associateJobPostForm}
          onClose={() => setAssociateJobPostForm(false)}
          company={company}
        />
      )}

      {openJobPostsDialog && (
        <CompanyJobPostsDialog
          open={openJobPostsDialog}
          onClose={() => setOpenJobPostsDialog(false)}
          company={company}
        />
      )}
    </>
  );
}
