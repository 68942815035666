import React, { useEffect, useState } from 'react';

import { Grid, Autocomplete, TextField, FormControlLabel, FormGroup, Checkbox, Box } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
// import UploadImage from 'src/components/UploadImage';

import { industries } from 'src/constants/constants';
import { makeStyles } from '@mui/styles';
import parse from 'autosuggest-highlight/parse';
import MultiValuedInput from 'src/constants/MultiValuedInput';
import { API_URLS } from 'src/constants/apiURLs';
import { Get } from 'src/actions/API/apiActions';

const formatLabel = (str) => {
  const formattedStr = str
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase()) // Capitalize each word's first character
    .replace(/^./, (char) => char.toUpperCase()); // Capitalize the first character of the entire string
  return formattedStr;
};

const useStyles = makeStyles((theme) => ({
  checkbox: {
    '&$checked': {
      color: '#00C1FE', // color of the checkbox when checked
    },
  },
  checked: {},
  label: {
    color: '#000', // color of the label text
  },
}));

const PersonForm = ({ onSubmit, onUpdate, isError, addedPerson }) => {
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [options, setOptions] = useState(industries);
  const [inputValue, setInputValue] = useState('');
  // const [value, setValue] = React.useState(null);
  const handleIndustryChange = (industry) => {
    setSelectedIndustry(industry);
  };

  const currentDate = (value) => {
    if (!value) return value;
    // Remove all non-digit characters
    const current = new Date().toISOString().split('T')[0];
    return current;
  };

  const initialValues = {
    profile_url: '',
    full_name: '',
    first_name: '',
    last_name: '',
    date_of_birth: currentDate(''),
    avator: '',
    headline: '',
    industry: '',
    SR_specified_industry: '',
    current_title: '',
    languages: '',
    skills: '',
    summary: '',
    company_id: '1',
    user_id: '1',
    sector_id: null,
  };

  const textAreaFields = ['summary', 'skills'];
  const ValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(/^[A-Za-z\s'-]*$/, 'First Name must only contain alphabets, spaces, hyphens, and apostrophes')
      .required('First Name is required'),
    last_name: Yup.string().matches(
      /^[A-Za-z\s'-]*$/,
      'Last Name must only contain alphabets, spaces, hyphens, and apostrophes'
    ),
    // .required('Last Name is required'),
    profile_url: Yup.string()
      .required('LinkedIn is required')
      .url('Enter Valid URL')
      .test('notEmpty', 'LinkedIn URL is required', (value) => value.trim() !== ''),
    current_title: Yup.string().required('Person Job current Title is required'),
    sector_id: Yup.number().required('Sector is required'),
    // avator: Yup.string().required('Avator is required'),
  });
  const classes = useStyles();
  React.useEffect(() => {
    if (inputValue === '') {
      setOptions(industries);
      return;
    }

    const filteredOptions = industries.filter((option) => option.toLowerCase().includes(inputValue.toLowerCase()));

    setOptions(filteredOptions);
  }, [inputValue]);
  const getAllSectors = () => {
    try {
      Get(
        {},
        API_URLS.getAllSectors,
        (response) => {
          setSectors(response.data);
        },
        (error) => {
          console.log('error', error);
        }
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getAllSectors();
  }, []);
  const renderSRSectorOptions = (setFieldValue) => (
    <>
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkbox}
                classes={{
                  checked: classes.checked,
                }}
                checked={selectedIndustry === 'Staffing and Recruiting'}
                onChange={() => {
                  handleIndustryChange('Staffing and Recruiting');
                  setFieldValue('industry', 'Staffing and Recruiting');
                  onUpdate({ industry: 'Staffing and Recruiting' });
                }}
              />
            }
            label="Staffing and Recruiting"
            className={classes.label}
          />
          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkbox}
                classes={{
                  checked: classes.checked,
                }}
                checked={selectedIndustry === 'Human Resources Services'}
                onChange={() => {
                  handleIndustryChange('Human Resources Services');
                  setFieldValue('industry', 'Human Resources Services');
                  onUpdate({ industry: 'Human Resources Services' });
                }}
              />
            }
            label="Human Resources Services"
            className={classes.label}
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          options={industries}
          getOptionLabel={(option) => option}
          onChange={(event, value) => {
            setFieldValue('SR_specified_industry', value);
            onUpdate({ SR_specified_industry: value });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select S&R Industry"
              fullWidth
              sx={{
                backgroundColor: 'white',
                borderRadius: '0.3rem',
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#00C1FE',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#00C1FE',
                  },
                },
                '& .MuiInputBase-root': {
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 1px #00C1FE',
                  },
                },
              }}
            />
          )}
        />
      </Grid>
    </>
  );

  const renderDirectSectorOptions = (setFieldValue) => (
    <Grid item xs={12}>
      <FormGroup row>
        {industries?.slice(0, 8).map((option) => (
          <Grid item xs={6} key={option}>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checkbox}
                  classes={{
                    checked: classes.checked,
                  }}
                  checked={selectedIndustry === option}
                  onChange={() => {
                    handleIndustryChange(option);
                    setFieldValue('industry', option);
                    onUpdate({ industry: option });
                  }}
                />
              }
              label={option}
              className={classes.label}
            />
          </Grid>
        ))}
        {industries?.length > 8 && (
          <Grid item xs={12}>
            <Autocomplete
              id="direct-industry-dropdown"
              getOptionLabel={(option) => option}
              filterOptions={(x) => x}
              options={options}
              autoComplete
              includeInputInList
              filterSelectedOptions
              noOptionsText="No industries"
              onChange={(event, newValue) => {
                handleIndustryChange(newValue);
                setOptions(newValue ? [newValue, ...options] : options);
                // setValue(newValue);
                setFieldValue('industry', newValue);
                onUpdate({ industry: newValue });
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                setSelectedIndustry(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Industry"
                  fullWidth
                  InputLabelProps={{ classes: { root: classes.label } }}
                />
              )}
              renderOption={(props, option) => {
                const matches = [];
                const parts = parse(
                  option,
                  matches.map((match) => [match.offset, match.offset + match.length])
                );

                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                        {parts.map((part, index) => (
                          <Box key={index} component="span" sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                            {part.text}
                          </Box>
                        ))}
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            />
          </Grid>
        )}
      </FormGroup>
    </Grid>
  );
  // useEffect(() => {
  //   const fullName = `${values.first_name} ${values.last_name}`.trim();
  //   setFieldValue('full_name', fullName);
  // }, [values.first_name, values.last_name]);
  return (
    <Formik
      initialValues={addedPerson ? addedPerson : initialValues}
      validationSchema={ValidationSchema}
      validate={(values) => {
        const errors = {};
        try {
          ValidationSchema.validateSync(values, { abortEarly: false });
        } catch (validationError) {
          validationError.inner.forEach((error) => {
            errors[error.path] = error.message;
          });
        }
        isError(Object.keys(errors).length > 0);
        return errors;
      }}
      onSubmit={(values, actions) => {
        // Combine firstName and lastName into fullName
        const fullName = `${values.firstName} ${values.lastName}`;
        console.log('Full Name:', fullName);
        onSubmit(values, fullName, actions);
      }}
    >
      {({ values, errors, touched, handleBlur, setFieldValue, handleSubmit, isSubmitting }) => (
        <Form>
          {console.log('', values)}
          {console.log('errpr', errors)}
          <Grid container spacing={2}>
            {Object.keys(initialValues).map((key) => (
              <Grid item xs={12} key={key}>
                {key === 'avator' ? (
                  <>
                    <label htmlFor={key} style={{ fontWeight: 'bold' }}>
                      {formatLabel(key)}
                    </label>
                    <TextField
                      type="text"
                      id={key}
                      name={key}
                      placeholder={`Enter ${formatLabel(key)}`}
                      value={values[key]}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue(key, e.target.value);
                        onUpdate({ [key]: e.target.value }); // Send updated value to parent
                      }}
                      error={touched[key] && Boolean(errors[key])}
                      helperText={touched[key] && errors[key]}
                      fullWidth
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  </>
                ) : key === 'date_of_birth' ? (
                  <>
                    <label htmlFor={key} style={{ fontWeight: 'bold' }}>
                      {formatLabel(key)}
                    </label>
                    <TextField
                      type="date"
                      id={key}
                      name={key}
                      placeholder={`Enter ${formatLabel(key)}`}
                      value={values[key]}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue(key, e.target.value);
                        onUpdate({ [key]: e.target.value }); // Send updated value to parent
                      }}
                      error={touched[key] && Boolean(errors[key])}
                      helperText={touched[key] && errors[key]}
                      fullWidth
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  </>
                ) : key === 'profile_url' ? (
                  <>
                    <label htmlFor={key} style={{ fontWeight: 'bold' }}>
                      {`LinkedIn ${formatLabel(key)}`}
                    </label>
                    <TextField
                      type="text"
                      id={key}
                      name={key}
                      placeholder={`Enter ${formatLabel(key)}`}
                      value={values[key]}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue(key, e.target.value);
                        onUpdate({ [key]: e.target.value }); // Send updated value to parent
                      }}
                      error={touched[key] && Boolean(errors[key])}
                      helperText={touched[key] && errors[key]}
                      fullWidth
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  </>
                ) : textAreaFields.includes(key) ? (
                  <>
                    <label htmlFor={key} style={{ fontWeight: 'bold' }}>
                      {formatLabel(key)}
                    </label>
                    <TextField
                      type="text"
                      id={key}
                      name={key}
                      placeholder={`Enter ${formatLabel(key)}`}
                      value={values[key]}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue(key, e.target.value);
                        onUpdate({ [key]: e.target.value }); // Send updated value to parent
                      }}
                      error={touched[key] && Boolean(errors[key])}
                      helperText={touched[key] && errors[key]}
                      fullWidth
                      multiline // This prop makes the TextField a TextArea
                      minRows={4} // Minimum number of rows for the TextArea
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  </>
                ) : (
                  ['first_name', 'last_name', 'headline', 'current_title'].includes(key) && (
                    <>
                      <label htmlFor={key} style={{ fontWeight: 'bold' }}>
                        {formatLabel(key)}
                      </label>
                      <TextField
                        type="text"
                        id={key}
                        name={key}
                        placeholder={`Enter ${formatLabel(key)}`}
                        value={values[key]}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(key, e.target.value);
                          onUpdate({ [key]: e.target.value }); // Send updated value to parent
                        }}
                        error={touched[key] && Boolean(errors[key])}
                        helperText={touched[key] && errors[key]}
                        fullWidth
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#00C1FE',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#00C1FE',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #00C1FE',
                            },
                          },
                        }}
                      />
                    </>
                  )
                )}
              </Grid>
            ))}

            <Grid item xs={12}>
              <MultiValuedInput
                label="Languages"
                placeholder="Languages"
                onChange={(languages) => {
                  setFieldValue('languages', languages);
                  onUpdate({ languages: languages }); // Send updated value to parent
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="sector_id" style={{ fontWeight: 'bold' }}>
                Sector
              </label>
              <Autocomplete
                options={sectors}
                getOptionLabel={(option) => option.sector}
                onChange={(event, value) => {
                  setFieldValue('sector_id', value ? value.id : null);
                  setFieldValue('industry', '');
                  setFieldValue('SR_specified_industry', '');
                  setSelectedSector(value);
                  onUpdate({ sector_id: value ? value.id : null });
                  // onUpdate({ industry: '' });
                  // onUpdate({ SR_specified_industry: '' });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Sector"
                    onBlur={handleBlur}
                    error={touched.sector_id && Boolean(errors.sector_id)}
                    helperText={touched.sector_id && errors.sector_id}
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>

            {selectedSector?.sector === 'S&R'
              ? renderSRSectorOptions(setFieldValue)
              : selectedSector?.sector === 'Direct'
              ? renderDirectSectorOptions(setFieldValue)
              : null}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default PersonForm;
