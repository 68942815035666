import React, { useEffect, useState } from 'react';
import { Grid, Autocomplete, TextField, FormControlLabel, FormGroup, Checkbox, Box } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { API_URLS } from 'src/constants/apiURLs';
import { Get } from 'src/actions/API/apiActions';
import { industries } from 'src/constants/constants';
import { companySizes } from 'src/constants/constants';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import './MyComponent.css';
import parse from 'autosuggest-highlight/parse';
import { makeStyles } from '@mui/styles';

const formatLabel = (str) => {
  const formattedStr = str
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase()) // Capitalize each word's first character
    .replace(/^./, (char) => char.toUpperCase()); // Capitalize the first character of the entire string
  return formattedStr;
};

const useStyles = makeStyles((theme) => ({
  checkbox: {
    '&$checked': {
      color: '#00C1FE', // color of the checkbox when checked
    },
  },
  checked: {},
  label: {
    color: '#000', // color of the label text
  },
}));

const validatePhoneNumber = (number) => {
  const phoneNumber = parsePhoneNumberFromString(number, 'US');
  return phoneNumber ? phoneNumber.isValid() : false;
};

const formatPhoneNumber = (number) => {
  const phoneNumber = parsePhoneNumberFromString(number, 'US');
  return phoneNumber ? phoneNumber.formatInternational() : number;
};

const CompanyForm = ({ onSubmit, addedValues, onUpdate, isError }) => {
  // const [phone, setPhone] = React.useState('');
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [options, setOptions] = React.useState(industries);
  //states for testing the search as you type autocomplete
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const formatPhoneNumber = (value) => {
    if (!value) return value;
    // Remove all non-digit characters
    const phoneNumber = value.replace(/\D/g, '');

    // Format the phone number with spaces
    const formattedPhoneNumber = phoneNumber.replace(/(\d{2})(\d{3})(\d{5})/, '$1 $2 $3');

    return formattedPhoneNumber.trim();
  };

  const handleIndustryChange = (industry) => {
    setSelectedIndustry(industry);
  };

  const initialValues = {
    id: null,
    logo: '',
    name: '',
    headline: '',
    phone_number: formatPhoneNumber(''),
    company_email: '',
    industry: '',
    SR_specified_industry: '',
    company_size_range: '',
    exact_company_size: '',
    description: '',
    website: '',
    linkedin: '',
    founded: '',
    followers: '',
    specialities: '',
    user_id: '1',
    sector_id: null,
  };

  const ValidationSchema = Yup.object().shape({
    name: Yup.string()
    .required('Company Name is required')
    .matches(/^[A-Za-z\s'!@#$%^&*()_\-+=,.<>?/|`~]*$/, 'Name contains invalid characters'),
    // .test('notEmpty', 'Company Name is required', (value) => value.trim() !== ''),
    website: Yup.string()
      .required('Business website URL is required')
      .url('Enter Valid URL')
      .test('notEmpty', 'Company Business website URL is required', (value) => value.trim() !== ''),
    linkedin: Yup.string()
      .required('LinkedIn is required')
      .url('Enter Valid URL')
      .test('notEmpty', 'LinkedIn URL is required', (value) => value.trim() !== ''),
    // company_size_range: Yup.string().required('Company Size is required'),
    company_email: Yup.string().email('Email must be a valid email'),
    // .required('Business email is required')
    // .test('notEmpty', 'Email Cannot be empty', (value) => value.trim() !== ''),

    description: Yup.string().matches(
      /^[A-Za-z\s'-]*$/,
      'Description only contain alphabets, spaces, hyphens, and apostrophes'
    ),

    specialities: Yup.string().matches(
      /^[A-Za-z\s'-]*$/,
      'Specialities must only contain alphabets, spaces, hyphens, and apostrophes'
    ),

    sector_id: Yup.number().required('Sector  is required'),
  });

  const textAreaFields = ['description', 'specialities'];
  const numberFeilds = ['exact_company_size', 'followers'];
  const classes = useStyles();
  React.useEffect(() => {
    if (inputValue === '') {
      setOptions(industries);
      return;
    }

    const filteredOptions = industries.filter((option) => option.toLowerCase().includes(inputValue.toLowerCase()));

    setOptions(filteredOptions);
  }, [inputValue]);

  const getAllSectors = () => {
    try {
      Get(
        {},
        API_URLS.getAllSectors,
        (response) => {
          setSectors(response.data);
        },
        (error) => {
          console.log('error', error);
        }
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getAllSectors();
  }, []);

  const renderSRSectorOptions = (setFieldValue) => (
    <>
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkbox}
                classes={{
                  checked: classes.checked,
                }}
                checked={selectedIndustry === 'Staffing and Recruiting'}
                onChange={() => {
                  handleIndustryChange('Staffing and Recruiting');
                  setFieldValue('industry', 'Staffing and Recruiting');
                  onUpdate({ industry: 'Staffing and Recruiting' });
                }}
              />
            }
            label="Staffing and Recruiting"
            className={classes.label}
          />
          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkbox}
                classes={{
                  checked: classes.checked,
                }}
                checked={selectedIndustry === 'Human Resources Services'}
                onChange={() => {
                  handleIndustryChange('Human Resources Services');
                  setFieldValue('industry', 'Human Resources Services');
                  onUpdate({ industry: 'Human Resources Services' });
                }}
              />
            }
            label="Human Resources Services"
            className={classes.label}
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          options={industries}
          getOptionLabel={(option) => option}
          onChange={(event, value) => {
            setFieldValue('SR_specified_industry', value);
            onUpdate({ SR_specified_industry: value });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select S&R Industry"
              fullWidth
              sx={{
                backgroundColor: 'white',
                borderRadius: '0.3rem',
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#00C1FE',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#00C1FE',
                  },
                },
                '& .MuiInputBase-root': {
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 1px #00C1FE',
                  },
                },
              }}
            />
          )}
        />
      </Grid>
    </>
  );

  const renderDirectSectorOptions = (setFieldValue) => (
    <Grid item xs={12}>
      <FormGroup row>
        {industries?.slice(0, 8).map((option) => (
          <Grid item xs={6} key={option}>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checkbox}
                  classes={{
                    checked: classes.checked,
                  }}
                  checked={selectedIndustry === option}
                  onChange={() => {
                    handleIndustryChange(option);
                    setFieldValue('industry', option);
                    onUpdate({ industry: option });
                  }}
                />
              }
              label={option}
              className={classes.label}
            />
          </Grid>
        ))}
        {industries?.length > 8 && (
          <Grid item xs={12}>
            <Autocomplete
              id="direct-industry-dropdown"
              getOptionLabel={(option) => option}
              filterOptions={(x) => x}
              options={options}
              autoComplete
              includeInputInList
              filterSelectedOptions
              noOptionsText="No industries"
              onChange={(event, newValue) => {
                handleIndustryChange(newValue);
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                setFieldValue('industry', newValue);
                onUpdate({ industry: newValue });
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                setSelectedIndustry(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Industry"
                  fullWidth
                  InputLabelProps={{ classes: { root: classes.label } }}
                />
              )}
              renderOption={(props, option) => {
                const matches = [];
                const parts = parse(
                  option,
                  matches.map((match) => [match.offset, match.offset + match.length])
                );

                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                        {parts.map((part, index) => (
                          <Box key={index} component="span" sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                            {part.text}
                          </Box>
                        ))}
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            />
          </Grid>
        )}
      </FormGroup>
    </Grid>
  );
  return (
    <Formik
      initialValues={addedValues ? addedValues : initialValues}
      validationSchema={ValidationSchema}
      validate={(values) => {
        const errors = {};
        try {
          ValidationSchema.validateSync(values, { abortEarly: false });
        } catch (validationError) {
          validationError.inner.forEach((error) => {
            errors[error.path] = error.message;
          });
        }
        isError(Object.keys(errors).length > 0);
        return errors;
      }}
      onSubmit={(values, actions) => {
        onSubmit(values, actions);
      }}
    >
      {({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => (
        <Form>
          {console.log('errfdfdsors', errors)}
          <Grid container spacing={2}>
            {Object.keys(initialValues).map((key) =>
              key === 'id' ? null : (
                <Grid item xs={12} key={key}>
                  {key === 'company_size_range' ? (
                    <>
                      <label htmlFor={key} style={{ fontWeight: 'bold' }}>
                        Company Size Range
                      </label>
                      <Autocomplete
                        id={key}
                        name={key}
                        value={values[key] ? { size: values[key] } : null}
                        getOptionLabel={(option) => option.size}
                        onBlur={handleBlur}
                        onChange={(event, newValue) => {
                          setFieldValue(key, newValue?.size);
                          onUpdate({ [key]: newValue?.size });
                        }}
                        options={companySizes}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(touched[key] && errors[key])}
                            helperText={touched[key] && errors[key]}
                            // error={Boolean(touched.username && errors.username)}

                            fullWidth
                            sx={{
                              backgroundColor: 'white',
                              borderRadius: '0.3rem',
                              '& .MuiOutlinedInput-root': {
                                '&:hover fieldset': {
                                  borderColor: '#00C1FE',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#00C1FE',
                                },
                              },
                              '& .MuiInputBase-root': {
                                '&.Mui-focused': {
                                  boxShadow: '0 0 0 1px #00C1FE',
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </>
                  ) : key === 'phone_number' ? (
                    <>
                      <label htmlFor={key} style={{ fontWeight: 'bold' }}>
                        {formatLabel(key)}
                      </label>
                      <div style={{ width: '100%' }}>
                        <PhoneInput
                          style={{ width: '100%' }}
                          inputStyle={{
                            height: '55px',
                            width: '100%',
                          }}
                          international
                          mask="999-999-9999"
                          defaultCountry="US"
                          value={values[key]}
                          // onBlur={handleBlur}
                          onChange={(value) => {
                            const formattedValue = formatPhoneNumber(value);
                            setFieldValue('phone_number', formattedValue);

                            onUpdate({ [key]: formattedValue });
                          }}
                          error={touched[key] && Boolean(errors[key])}
                          helperText={touched[key] && errors[key]}
                          // fullWidth

                          inputComponent={TextField}
                          inputProps={{
                            id: { key },
                            name: { key },

                            variant: 'outlined',

                            fullWidth: true,
                            sx: {
                              backgroundColor: 'white',
                              borderRadius: '0.3rem',
                              '& .MuiOutlinedInput-root': {
                                '&:hover fieldset': {
                                  borderColor: '#00C1FE',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#00C1FE',
                                },
                              },
                              '& .MuiInputBase-root': {
                                '&.Mui-focused': {
                                  boxShadow: '0 0 0 1px #00C1FE',
                                },
                              },
                            },
                          }}
                        />
                      </div>
                    </>
                  ) : key === 'logo' ? (
                    <>
                      <label htmlFor={key} style={{ fontWeight: 'bold' }}>
                        {formatLabel(key)}
                      </label>
                      <TextField
                          type="text"
                          id={key}
                          name={key}
                          placeholder={`Enter ${formatLabel(key)}`}
                          value={values[key]}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue(key, e.target.value);
                            onUpdate({ [key]: e.target.value }); // Send updated value to parent
                          }}
                          error={Boolean(touched[key] && errors[key])}
                          helperText={touched[key] && errors[key]}
                          fullWidth
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                    </>
                  ) : numberFeilds.includes(key) ? (
                    <>
                      <label htmlFor={key} style={{ fontWeight: 'bold' }}>
                        {formatLabel(key)}
                      </label>
                      <TextField
                        type="number"
                        id={key}
                        name={key}
                        placeholder={`Enter ${formatLabel(key)} Must be Number`}
                        value={values[key]}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(key, e.target.value);
                          onUpdate({ [key]: e.target.value }); // Send updated value to parent
                        }}
                        error={Boolean(touched[key] && errors[key])}
                        helperText={touched[key] && errors[key]}
                        fullWidth
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#00C1FE',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#00C1FE',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #00C1FE',
                            },
                          },
                        }}
                      />
                    </>
                  ) : textAreaFields.includes(key) ? (
                    <>
                      <label htmlFor={key} style={{ fontWeight: 'bold' }}>
                        {formatLabel(key)}
                      </label>
                      <TextField
                        type="text"
                        id={key}
                        name={key}
                        placeholder={`Enter ${formatLabel(key)}`}
                        value={values[key]}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(key, e.target.value);
                          onUpdate({ [key]: e.target.value }); // Send updated value to parent
                        }}
                        error={touched[key] && Boolean(errors[key])}
                        helperText={touched[key] && errors[key]}
                        fullWidth
                        multiline // This prop makes the TextField a TextArea
                        minRows={4} // Minimum number of rows for the TextArea
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#00C1FE',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#00C1FE',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #00C1FE',
                            },
                          },
                        }}
                      />
                    </>
                  ) : (
                    [
                      'name',
                      'headline',
                      'company_email',
                      'exact_company_size',
                      'website',
                      'linkedin',
                      'founded',
                      'followers',
                    ].includes(key) && (
                      <>
                        <label htmlFor={key} style={{ fontWeight: 'bold' }}>
                          {formatLabel(key)}
                        </label>
                        <TextField
                          type="text"
                          id={key}
                          name={key}
                          placeholder={`Enter ${formatLabel(key)}`}
                          value={values[key]}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue(key, e.target.value);
                            onUpdate({ [key]: e.target.value }); // Send updated value to parent
                          }}
                          error={Boolean(touched[key] && errors[key])}
                          helperText={touched[key] && errors[key]}
                          fullWidth
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </>
                    )
                  )}
                </Grid>
              )
            )}
            <Grid item xs={12}>
              <label htmlFor="sector_id" style={{ fontWeight: 'bold' }}>
                Sector
              </label>
              <Autocomplete
                options={sectors}
                getOptionLabel={(option) => option.sector}
                onChange={(event, value) => {
                  setFieldValue('sector_id', value ? value.id : null);
                  setFieldValue('industry', '');
                  setFieldValue('SR_specified_industry', '');
                  setSelectedSector(value);
                  onUpdate({ sector_id: value ? value.id : null });
                  // onUpdate({ industry: '' });
                  // onUpdate({ SR_specified_industry: '' });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Sector"
                    onBlur={handleBlur}
                    error={touched.sector_id && Boolean(errors.sector_id)}
                    helperText={touched.sector_id && errors.sector_id}
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            {selectedSector?.sector === 'S&R'
              ? renderSRSectorOptions(setFieldValue)
              : selectedSector?.sector === 'Direct'
              ? renderDirectSectorOptions(setFieldValue)
              : null}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyForm;
