import { Icon } from '@iconify/react';
import React, { useEffect, useMemo, useState } from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import Footer from 'react-multi-date-picker/plugins/range_picker_footer';

function ReactMultiDatePicker({ onChange, style }) {
  const [value, setValue] = useState([]);

  const previousMonth = new DateObject().subtract(1, 'month');

  const formattedDates = useMemo(() => {
    return value.map((date) => {
      const { year, month, day } = date;
      return `${year}-${String(month.number).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    });
  }, [value]);

  useEffect(() => {
    onChange && onChange(formattedDates); // Call onChange if it's provided
  }, [formattedDates, onChange]);

  const getDisplayValue = () => {
    if (value?.length === 0) {
      return 'Select date';
    }
    if (value?.length === 1) {
      return `${formattedDates[0]} - Select date`;
    }
    return `${formattedDates[0]} - ${formattedDates[1]}`;
  };

  return (
    <div>
      <DatePicker
        value={value}
        onChange={setValue} // Update state on date range selection
        range
        numberOfMonths={2} // Display 2 months
        currentDate={previousMonth} // Set the first visible month as the previous month
        plugins={[<Footer position="bottom" />]}
        render={(value, openCalendar) => {
          const displayValue = getDisplayValue(); // Get the appropriate display value

          return (
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <Icon
                icon="mdi:calendar-outline"
                style={{
                  position: 'absolute',
                  right: '10px',
                  fontSize: '20px',
                  color: '#00C1FE',
                  cursor: 'pointer',
                }}
                onClick={openCalendar} // Open calendar on icon click
              />
              <input
                value={displayValue} // Show appropriate display value
                onFocus={openCalendar} // Open the calendar when input is focused
                readOnly // Make input read-only to prevent manual editing
                style={style}
              />
            </div>
          );
        }}
      />
    </div>
  );
}

export default ReactMultiDatePicker;
