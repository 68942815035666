import { Cancel, IndeterminateCheckBox } from '@mui/icons-material';
import { Alert, Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useState } from 'react';
import { BlockedDomains, SuppressionList } from './components';

function BlacklistSection(props) {
  const [toggleButton, setToggleButton] = useState('suppression-list');

  return (
    <div>
      <div className="h-full m-4">
        <div className="grid grid-cols-5 bg-white items-center justify-center rounded-xl mx-2">
          <div className="grid grid-cols-subgrid gap-4 col-span-3">
            <Box className="my-6 grid col-start-4 col-end-4 ">
              <ToggleButtonGroup
                color="primary"
                exclusive
                aria-label="Platform"
                className="py-1 items-center justify-center"
                style={{ backgroundColor: '#e1f7fc' }}
                onChange={(event, newValue) => {
                  setToggleButton(newValue);
                }}
              >
                <ToggleButton
                  value="suppression-list"
                  style={{
                    backgroundColor: toggleButton === 'suppression-list' ? 'white' : '#EEF7FF',
                    border: 'none',
                    width: '200px', // Adjust the width as needed
                  }}
                  className="hover:bg-white rounded-3xl"
                >
                  <Cancel /> &nbsp; Suppression List
                </ToggleButton>
                <ToggleButton
                  value="blocked-domains"
                  style={{
                    backgroundColor: toggleButton === 'blocked-domains' ? 'white' : '#EEF7FF',
                    border: 'none',
                    width: '200px', // Adjust the width as needed
                  }}
                  className="hover:bg-white rounded-3xl"
                >
                  <IndeterminateCheckBox /> &nbsp; Blocked Domains
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </div>
        </div>
        <br />
        <Alert severity="info">
          If you have a list of email addresses that should never receive any email campaigns or autoresponders from
          you, import them into this suppression list. Any email addresses you import via CSV or API into any list in
          this brand in future will not be imported if they appear in this suppression list.
        </Alert>
        <br />
        {toggleButton === 'suppression-list' ? <SuppressionList /> : <BlockedDomains />}
      </div>
    </div>
  );
}

export default BlacklistSection;
