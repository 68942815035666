import React from 'react';
import { TemplatesMainSection } from 'src/sections/admin/templates';

const TemplatesPage = () => {
  return (
    <div className="p-4 bg-[#ECF6FE] ">
      <TemplatesMainSection />
    </div>
  );
};

export default TemplatesPage;
