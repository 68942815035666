import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box, Typography } from '@mui/material';

// Define the PieChart component
export default function ScrapperPieChart({ title, subheader, chartData }) {
  const options = {
    chart: {
      type: 'pie', // Set chart type to 'pie'
      height: '320px', // Set the height of the chart
      backgroundColor: 'white', // Set background color
      style: {
        fontFamily: 'Arial, sans-serif', // Optional: Customize font
      },
      // plotBorderWidth: 1,
      plotShadow: true, // Enable shadow
      events: {
        load: function () {
          var chart = this;
          // Add a horizontal grey bar above the legend
          chart.renderer
            .rect(
              chart.legend.group.translateX - 10, // X position adjustment
              chart.legend.group.translateY - 15, // Y position adjustment to place the bar above the legend
              chart.legend.legendWidth + 30, // Width adjustment for the bar
              2 // Height of the bar
            )
            .attr({
              fill: '#cccccc', // Grey color
              zIndex: 3, // Ensure it's on top,
              marginTop: '1px',
            })
            .add();
        },
      },
    },
    title: {
      text: ' ',
      align: 'left',
    },
    subtitle: {
      text: subheader,
      align: 'left',
      style: {
        fontSize: '12px', // Adjusted subtitle font size
      },
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y}</b> ({point.percentage:.1f}%)', // Tooltip format
    },
    plotOptions: {
      pie: {
        allowPointSelect: true, // Allow points to be selected
        cursor: 'pointer', // Change cursor to pointer on hover
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.y} ({point.percentage:.1f}%)',
          style: {
            color: 'black',
            fontSize: '8px',
          },
        },
        showInLegend: true, // Show legend
        size: '80%',
      },
    },
    legend: {
      itemStyle: {
        fontSize: '10px', // Custom font size for legend labels
        color: '#333333', // Custom color for legend text // Bold text
      },
      symbolHeight: 12, // Adjust symbol height in the legend
      symbolWidth: 12, // Adjust symbol width in the legend
      symbolRadius: 6, // Adjust symbol border radius
      margin: 20, // Adjust margin between legend and chart
    },
    credits: {
      enabled: false, // Disable Highcharts credits
    },
    series: [
      {
        name: 'Share',
        colorByPoint: true, // Automatically assign colors by point
        data: chartData?.map((item) => ({
          name: item.name,
          y: item.value,
          color: item.color ? item.color : undefined, // Use provided color if available
        })),
      },
    ],
  };
  return (
    <div
      style={{
        backgroundColor: 'white', // Background color for the container
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Shadow for the container
        padding: '20px', // Padding around the chart
        borderRadius: '8px', // Rounded corners
        marginLeft: '5px',
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="16px">
        <Typography variant="h6" style={{ marginRight: 'auto' }}>
          {title}
        </Typography>
      </Box>

      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
