import { Box, Grid } from '@mui/material';
import React from 'react';
import PieChart from 'src/components/chart/PieChart';
import { HighchartsBarChart } from '../../campaigns/components';

function EmailBasedReportingGraphs({ emailDelivered, campaignBasedData }) {
  // Safely access the first element and parse numbers
  const deliveryData = emailDelivered?.[0] || {};
  const totalDeliveries = parseFloat(deliveryData.totalDeliveries) || 0;
  const totalBounces = parseFloat(deliveryData.totalBounces) || 0;

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Box sx={{ marginLeft: '10px' }}>
            {totalDeliveries > 0 || totalBounces > 0 ? (
              <PieChart
                title={`Email Analytics`}
                subheader={`Grand Total Emails Delivered: ${totalDeliveries}`}
                chartData={[
                  { label: 'Email Delivered', value: totalDeliveries },
                  { label: 'Emails Not Delivered', value: totalBounces },
                ]}
                chartColors={
                  totalDeliveries === 0 && totalBounces === 0
                    ? ['#D3D3D3', '#D3D3D3'] // Grey color if both values are zero
                    : ['#579FC8', '#CE5C56'] // Original colors otherwise
                }
              />
            ) : (
              <PieChart
                title={`Email Based Metrics`}
                subheader={`Grand Total Emails Delivered: 0`}
                chartData={[
                  { label: 'Email Delivered', value: 0 }, // Dummy value
                  { label: 'Emails Not Delivered', value: 0 }, // Dummy value
                ]}
                chartColors={['#D3D3D3', '#D3D3D3']} // Grey color if sendingAnalytics is null
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <HighchartsBarChart
            title="Email Sending Metrics"
            subheader="Event Metrics by Type"
            chartLabels={['Recipients', 'Opens', 'Clicks', 'Bounces', 'Spams', 'Un-Opened', 'Complaints']}
            chartData={campaignBasedData}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default EmailBasedReportingGraphs;
