import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  IconButton,
  Typography,
  Box,
  Grid,
  Slide,
  Checkbox,
  Tooltip,
  List,
  ListItem,
  Divider,
} from '@mui/material';
import { Close, Add } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Get } from 'src/actions/API/apiActions';
import { useSnackbar } from 'notistack';
import { API_URLS } from 'src/constants/apiURLs';
import _ from 'lodash';
import { Icon } from '@iconify/react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});
const style = {
  p: 0,
  width: '100%',
  maxWidth: 455,
  borderRadius: 1,
  border: '1px solid',
  borderColor: '#E2DDDD',
  backgroundColor: '#E1F7FC',
};
// Styled TextField with focused outline color
const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#00C1FE',
    },
    '&:hover fieldset': {
      borderColor: '#00C1FE',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00C1FE',
    },
  },
  marginTop: theme.spacing(1),
}));

export default function AddPersonEmailDialog({ open, onClose, onSave }) {
  const [emailFields, setEmailFields] = useState([{ email: '', is_default: true }]);
  const [businessEmails, setBusinessEmails] = useState([{ email: '', is_default: true, error: '' }]);
  const [warning, setWarning] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [searchString, setSearchString] = useState('');
  const [searchedResult, setSearchedResult] = useState(null);
  const [searchError, setSearchError] = useState('');

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      onClose();
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleAddEmailField = () => {
    setEmailFields([...emailFields, { email: '', is_default: false }]);
  };

  const handleAddBusinessEmail = () => {
    setBusinessEmails([...businessEmails, { email: '', is_default: false, error: '' }]);
  };

  const handleEmailChange = (index, event) => {
    const updatedEmailFields = [...emailFields];
    updatedEmailFields[index].email = event.target.value;
    setEmailFields(updatedEmailFields);
  };

  const handleBusinessEmailChange = (index, event) => {
    const updatedBusinessEmails = [...businessEmails];
    const email = event.target.value;
    updatedBusinessEmails[index].email = email;

    // Validate email format and set error if invalid
    if (!validateEmail(email) && email !== '') {
      updatedBusinessEmails[index].error = 'Invalid email format';
    } else {
      updatedBusinessEmails[index].error = '';
    }

    setBusinessEmails(updatedBusinessEmails);
  };

  const handleCheckboxChange = (index) => {
    const updatedEmailFields = emailFields.map((field, i) => ({
      ...field,
      is_default: i === index,
    }));
    setEmailFields(updatedEmailFields);
    setWarning(''); // Clear warning if any
  };

  const handleBusinessCheckboxChange = (index) => {
    const updatedBusinessEmails = businessEmails.map((field, i) => ({
      ...field,
      is_default: i === index,
    }));
    setBusinessEmails(updatedBusinessEmails);
    setWarning(''); // Clear warning if any
  };

  const handleSave = () => {
    const hasDefaultPersonal = emailFields.some((field) => field.is_default);
    const hasDefaultBusiness = businessEmails.some((field) => field.is_default);
    const hasInvalidBusinessEmail = businessEmails.some((field) => field.error !== '');

    if (emailFields.length > 1 && !hasDefaultPersonal) {
      setWarning('Please select a default personal email.');
      alert('Please select a default personal email.');
    } else if (businessEmails.length > 1 && !hasDefaultBusiness) {
      setWarning('Please select a default business email.');
      alert('Please select a default business email.');
    } else if (hasInvalidBusinessEmail) {
      setWarning('Please correct the invalid business email(s).');
      alert('Please correct the invalid business email(s).');
    } else {
      onSave(emailFields, businessEmails);
    }
  };

  

  const debouncedFindEmail = _.debounce((email) => {
    if (!validateEmail(email)) {
      setSearchError('Invalid email format');
      setSearchedResult(null);
      return;
    }
    setSearchError('');
    try {
      Get(
        {
          email_id: email, // pass the searchString into the payload
        },
        API_URLS.searchEmail,
        (resp) => {
          // console.log('1234', resp);
          if (resp?.data?.error) {
            setSearchedResult(null);
          } else {
            setSearchedResult(resp?.data);
          }
        },
        (error) => {
          console.log('error', error);
          enqueueSnackbar('Error in searching email', { variant: 'error' });
        }
      );
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, 300);

  const findEmail = useCallback(debouncedFindEmail, [debouncedFindEmail]);

  useEffect(() => {
    if (searchString) {
      findEmail(searchString); // pass the searchString into the debounced function
    }

    // Cleanup function to cancel debounce if necessary
    return findEmail.cancel;
  }, [searchString, findEmail]); // Dependency array ensures debounce is used properly

  // Function to format the date as "14 Aug, 2024"
  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  console.log('searchedResult', searchedResult);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={false}
      PaperProps={{
        sx: {
          ml: 'auto',
          mt: 'auto',
          mr: '2rem',
          width: '1200px',
          height: '85%',
        },
      }}
      aria-describedby="alert-dialog-slide-description"
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: '#F4F9FD' }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4">Add Contact Information</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <br />
        <Grid container spacing={2} sx={{ height: '100%', overflow: 'hidden' }}>
          {/* Search Bar Section */}
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <StyledTextField
              variant="outlined"
              placeholder="Search..."
              size="medium"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              error={!!searchError}
              helperText={searchError}
            />
            <br />
            {!searchedResult && searchString && !searchError && (
              <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" marginTop="2rem">
                <Icon icon="mdi:tick-circle-outline" style={{ fontSize: '150px', color: '#1FAF38' }} />
                <Typography variant="subtitle1" sx={{ color: '#1FAF38' }}>
                  Unique Email
                </Typography>
              </Box>
            )}
            {searchedResult && searchString && (
              <>
                <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" marginTop="2rem">
                  <Icon icon="radix-icons:cross-circled" style={{ fontSize: '150px', color: '#FA2F2F' }} />
                  <Typography variant="subtitle1" sx={{ color: '#FA2F2F' }}>
                    Already Exist
                  </Typography>
                </Box>
                <br />
                <List style={style}>
                  <ListItem>
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <Typography style={{ fontWeight: 'bold' }}>Person Name:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <a
                          href={searchedResult?.person?.profile_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: '#1976d2', textDecoration: 'none', fontWeight: 'initial' }}
                        >
                          {searchedResult?.person?.full_name}
                        </a>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider component="li" />

                  <ListItem>
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <Typography style={{ fontWeight: 'bold' }}>Company:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <a
                          href={searchedResult?.company?.profile_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: '#1976d2', textDecoration: 'none', fontWeight: 'initial' }}
                        >
                          {searchedResult?.company?.name}
                        </a>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider component="li" />

                  <ListItem>
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <Typography style={{ fontWeight: 'bold' }}>Lead Expert:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <span style={{ color: '#818283', fontWeight: 'initial' }}>
                          {searchedResult?.user?.first_name} {searchedResult?.user?.last_name}
                        </span>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider component="li" />

                  <ListItem>
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <Typography style={{ fontWeight: 'bold' }}>Date:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <span style={{ color: '#818283', fontWeight: 'initial' }}>
                          {formatDate(searchedResult?.email?.createdAt)}
                        </span>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider component="li" />

                  <ListItem>
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <Typography style={{ fontWeight: 'bold' }}>Email:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <span style={{ color: '#818283', fontWeight: 'initial' }}>
                          {searchedResult?.email?.email_id}
                        </span>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider component="li" />

                  <ListItem>
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <Typography style={{ fontWeight: 'bold' }}>Status:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <span
                          style={{
                            backgroundColor:
                            searchedResult?.email?.status === 'verified'
                                ? '#b3ffb3'
                                : searchedResult?.email?.status === 'not-verified'
                                ? '#7FE0FE'
                                : '#FFC9C9',
                            color:
                            searchedResult?.email?.status === 'verified'
                                ? 'green'
                                : searchedResult?.email?.status === 'not-verified'
                                ? '#00b0e6'
                                : 'red',
                            padding: '8px 10px',
                            fontWeight: 'bold',
                          }}
                        >
                          {searchedResult?.email?.status}
                        </span>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </>
            )}
          </Grid>

          {/* Email Fields Section */}
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            sx={{
              borderLeft: '3px solid #E2DDDD',
              marginLeft: '1rem',
            }}
          >
            {businessEmails?.map((field, index) => (
              <Box key={index} mb={2}>
                {/* Label */}
                <Typography variant="subtitle1" gutterBottom sx={{ marginLeft: '2rem' }}>{`Business Email ${
                  index + 1
                }`}</Typography>
                {/* Checkbox, TextField, and Add Icon in the same row */}
                <Box display="flex" alignItems="center">
                  <Tooltip arrow title="Set as default" placement="bottom">
                    <Checkbox
                      checked={field.is_default}
                      onChange={() => handleBusinessCheckboxChange(index)}
                      disabled={businessEmails.length === 1 || searchedResult}
                      sx={{
                        color: field.is_default ? '#00C1FE' : 'default',
                        '&.Mui-checked': {
                          color: '#00C1FE',
                        },
                      }}
                    />
                  </Tooltip>

                  <StyledTextField
                    variant="outlined"
                    value={field.email}
                    onChange={(e) => handleBusinessEmailChange(index, e)}
                    size="medium"
                    placeholder={`Enter Business Email ${index + 1}`}
                    error={!!field.error}
                    helperText={field.error}
                    disabled={searchedResult}
                  />
                  <IconButton onClick={handleAddBusinessEmail} sx={{ marginLeft: 1 }} disabled={searchedResult}>
                    <Tooltip arrow title="Add Email" placement="bottom">
                      <Add
                        style={{
                          backgroundColor: '#00C1FE',
                          color: 'white',
                          borderRadius: '50%',
                          padding: '10px',
                          fontSize: '40px',
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </Box>
              </Box>
            ))}

            {emailFields?.map((field, index) => (
              <Box key={index} mb={2}>
                {/* Label */}
                <Typography variant="subtitle1" gutterBottom sx={{ marginLeft: '2rem' }}>{`Personal Email ${
                  index + 1
                }`}</Typography>
                {/* Checkbox, TextField, and Add Icon in the same row */}
                <Box display="flex" alignItems="center">
                  <Tooltip arrow title="Set as default" placement="bottom">
                    <Checkbox
                      checked={field.is_default}
                      onChange={() => handleCheckboxChange(index)}
                      disabled={emailFields.length === 1 || searchedResult}
                      sx={{
                        color: field.is_default ? '#00C1FE' : 'default',
                        '&.Mui-checked': {
                          color: '#00C1FE',
                        },
                      }}
                    />
                  </Tooltip>

                  <StyledTextField
                    variant="outlined"
                    value={field.email}
                    onChange={(e) => handleEmailChange(index, e)}
                    size="medium"
                    placeholder={`Enter Personal Email ${index + 1}`}
                    disabled={searchedResult}
                  />
                  <IconButton onClick={handleAddEmailField} sx={{ marginLeft: 1 }} disabled={searchedResult}>
                    <Tooltip arrow title="Add Email" placement="bottom">
                      <Add
                        style={{
                          backgroundColor: '#00C1FE',
                          color: 'white',
                          borderRadius: '50%',
                          padding: '10px',
                          fontSize: '40px',
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </Box>
              </Box>
            ))}

            {warning && (
              <Typography variant="subtitle2" color="error">
                {warning}
              </Typography>
            )}
            <br />
            <Box mt={2} display="flex" justifyContent="flex-start" marginLeft="2.5rem">
              <Button
                color="primary"
                sx={{
                  width: '150px',
                  height: '45px',
                  border: '2px solid #00C1FE',
                  borderRadius: '5px',
                  color: '#ffffff',
                  backgroundColor: '#00C1FE',
                  '&:hover': {
                    backgroundColor: '#00C1FE',
                  },
                  marginTop: '1rem',
                }}
                onClick={handleSave}
                disabled={searchedResult}
              >
                Add Emails
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
