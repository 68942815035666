import { Helmet } from 'react-helmet-async';
import { AdminDashboard } from 'src/sections/admin/dashboard';
// ----------------------------------------------------------------------

export default function AdminDashboardPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard | Admin </title>
      </Helmet>
      <AdminDashboard />
      {/* <Container maxWidth="xxl">
        <Typography variant="h3" sx={{ mb: 5 }}>
          Hello Sir, Welcome back
        </Typography>
        <Typography variant="h5" fontFamily="revert" fontStyle="revert" sx={{ mb: 5 }}>
          Empower your team, connect with customers, and drive business growth with effective administration
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
          
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
         
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
          
          </Grid>
        </Grid>
      </Container> */}
    </>
  );
}
