import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
import { API_URLS } from 'src/constants/apiURLs';
// hooks
import useResponsive from 'src/hooks/useResponsive';
// components
import Logo from 'src/components/logo/Logo';
import Iconify from 'src/components/iconify';

import { RegisterForm } from 'src/sections/auth';
import { Post } from 'src/actions/API/apiActions';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

// sections
import { Link as RouterLink } from 'react-router-dom';
import logo from 'src/images/logo.png';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function RegisterPage() {
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // const handleRegisterUser = (values, actions) => {
  //   console.log("rertwetg", values)
  //   Post(
  //     values,
  //     API_URLS.createUser,
  //     (resp) => {
  //       actions.setSubmitting(false);
  //       actions.resetForm();
  //       enqueueSnackbar('Verification email sent to ' + values.email + '. Login to your email for verification', { variant: 'success' });
  //       navigate('/verifyEmail');
  //     },
  //     (error) => {
  //       actions.setSubmitting(false);
  //       enqueueSnackbar('Error in Registering User', { variant: 'error' });
  //       console.error('Error in registering user:', error);
  //     }
  //   );
  // };
  const handleRegisterUser = (values, actions) => {
    Post(
      values,
      API_URLS.createUser,
      (resp) => {
        actions.setSubmitting(false);
        actions.resetForm();
        enqueueSnackbar('Verification email sent to ' + values.email + '. Login to your email for verification', {
          variant: 'success',
        });
        navigate('/verifyEmail');
      },
      (error) => {
        actions.setSubmitting(false);
        if (error?.message?.includes('409')) {
          enqueueSnackbar('No more then one admin is allowed', { variant: 'error' });
        } else {
          enqueueSnackbar(error?.response?.data?.message || 'Cannot register User', { variant: 'error' });
        }
      }
    );
  };
 
  return (
    <>
      <Helmet>
        <title> Register |CRM </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi there, Welcome
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <div className=" flex justify-center items-center max-w-[500px] mx-auto mb-10">
              <Link to="/" component={RouterLink}>
                <img src={logo} alt="Logo" height={50} width={50} />
              </Link>
              <div>
                <h1 className=" ml-2 flex text-2xl font-medium text-[#00C1FE] items-center justify-center">
                  Ultimate Outsourcing
                </h1>
                <p className=" ml-6 flex items-center justify-center text-xs">
                  All Your Reqruitment Worries Outsourced
                </p>
              </div>
            </div>

            <Typography variant="h4" gutterBottom className="text-[#00C1FE]">
              Sign Up here
            </Typography>

            <RegisterForm onSubmit={(values, actions) => handleRegisterUser(values, actions)} />

            <Typography variant="body2" sx={{ mt: 5, display: 'flex', justifyContent: 'flex-end' }}>
              Already have an account? {''}
              <Link variant="subtitle2" href="login">
                Login
              </Link>
            </Typography>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
