import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { Get, Post } from 'src/actions/API/apiActions';
import { enqueueSnackbar } from 'notistack';
import { API_URLS } from 'src/constants/apiURLs';
import { MenuItem, Select, Typography, TextField, Button, Box } from '@mui/material';
import { TemplatePreview } from '.';

function CreateSequence({sequenceId , onCreateCampaign , onBack}) {
  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState('');
  const [sequenceName, setSequenceName] = useState('');
  const [sequenceDescription, setSequenceDescription] = useState('');
  const [steps, setSteps] = useState([]);
  
  const [isSequenceCreated, setIsSequenceCreated] = useState(false);
  const [selectedStepIndex, setSelectedStepIndex] = useState(null); // Track selected step index
const [createdSequence, setCreatedSequence] = useState();

  const stepTypes = ['outreach-email', 'follow-up-1', 'follow-up-2', 'follow-up-3'];

  const getAllTemplates = () => {
    try {
      Get(
        {},
        API_URLS.getTemplates,
        (resp) => {
          setTemplates(resp?.data?.templates);
        },
        (error) => {
          enqueueSnackbar('Failed to load templates ', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  useEffect(() => {
    getAllTemplates();
  }, []);

  const handleTemplateChange = (event) => {
    const selectedTemplate = event.target.value;
    setTemplate(selectedTemplate);

    if (selectedStepIndex !== null) {
      const updatedSteps = steps.map((step, index) => 
        index === selectedStepIndex ? { ...step, template_id: selectedTemplate.id } : step
      );
      setSteps(updatedSteps);
    }
  };

  const addStep = () => {
    if (steps.length < stepTypes.length) { // Limit addition based on step types
      const nextStepType = stepTypes[steps.length % stepTypes.length]; // Cycle through step types
      const newStep = {
        step_order: steps.length + 1,
        step_type: nextStepType,
        wait_time: null, // Initialize with the current date
        template_id: '',
        sequence_id: createdSequence?.id,
      };
      const updatedSteps = [...steps, newStep];
      setSteps(updatedSteps);
      setSelectedStepIndex(updatedSteps.length - 1); // Set the newly added step as selected
    } else {
      enqueueSnackbar('Cannot add more steps. Step limit reached.', { variant: 'info' });
    }
  };

  const handleStepClick = (index) => {
    setSelectedStepIndex(index);
    setTemplate('')
  };

  const createSequence = () => {
    if(!sequenceName) {
      enqueueSnackbar('Please enter sequence name', { variant: 'warning' });
      return;
    }
    try {
      Post(
        {
          name: sequenceName,
          description: sequenceDescription,
        },
        API_URLS.createSequence,
        (resp) => {
          enqueueSnackbar('Sequence created successfully, Define its steps now!!', { variant: 'success' });
          setIsSequenceCreated(true);
          setCreatedSequence(resp?.sequence);
          sequenceId(resp?.sequence?.id)
        },
        (error) => {
          enqueueSnackbar( error.response.data.message || 'Failed to create sequence', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const createSequenceSteps = async () => {
    if (steps.length === 0) {
      enqueueSnackbar('No steps to create', { variant: 'warning' });
      return;
    }
  
    try {
      // Create steps for the sequence
      const stepPromises = steps.map(step =>
        Post(
          {
            sequence_id: createdSequence?.id, // Use the existing sequence ID
            template_id: step.template_id,
            step_order: step.step_order,
            step_type: step.step_type,
            wait_time: step.wait_time,
          },
          API_URLS.createSequenceStep,
          (resp) =>{
            // enqueueSnackbar('Sequence steps created successfully!', { variant: 'success' });

          },
          (error)=>{
            enqueueSnackbar(  error.response.data.message ||'Failed to create sequence steps', { variant: 'warning' });

          }
        )
      );
  
      await Promise.all(stepPromises);
  
    } catch (error) {
      enqueueSnackbar('Failed to create sequence steps', { variant: 'error' });
    }
  };

  
  console.log("steps", createdSequence);
  return (
    <div className="container mx-auto px-4">
      <div className="w-full bg-white rounded-2xl border border-slate-200 p-4">
        <div className="flex items-center justify-between mb-4">
          <Icon icon="ic:round-arrow-back" style={{ fontSize: '30px', color: '#00C1FE' , cursor:"pointer" }}  onClick={() => onBack()}/>
        </div>

        <hr className="w-full bg-slate-800 h-[2px] mb-4" />

        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-4 p-4 rounded-md">
            <Typography variant="h6" className="text-[#00C1FE] mb-2">
              {isSequenceCreated ? 'Define Sequence Steps' : 'Create Sequence'}
            </Typography>
            {!isSequenceCreated && (
              <>
                <div className="flex flex-col gap-4">
                  <TextField
                    placeholder="Enter Sequence Name"
                    variant="outlined"
                    fullWidth
                    value={sequenceName}
                    onChange={(e) => setSequenceName(e.target.value)}
                    margin="normal"
                    sx={{
                      borderRadius: '0.5rem',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />
                  <TextField
                    placeholder="Enter Sequence Description (Optional)"
                    variant="outlined"
                    fullWidth
                    value={sequenceDescription}
                    onChange={(e) => setSequenceDescription(e.target.value)}
                    margin="normal"
                    multiline
                    rows={4}
                    sx={{
                      borderRadius: '0.5rem',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />
                </div>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <Button
                    variant="secondary"
                    onClick={() => createSequence()}
                    startIcon={<Icon icon="ic:baseline-add" />}
                    sx={{
                      fontSize: '18px',
                      borderRadius: '10px',
                      borderWidth: '3px',
                      color: 'white',
                      height: '54px',
                      backgroundColor: '#0ec4fe',
                      boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.2)',
                      borderColor: '#0ec4fe !important',
                      '&:hover': {
                        color: 'white !important',
                        borderColor: '#0ec4fe !important',
                        boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.2) !important',
                        backgroundColor: '#0ec4fe !important',
                      },
                    }}
                  >
                    Create Sequence
                  </Button>
                </Box>
              </>
            )}
            {isSequenceCreated && (
              <div className="flex flex-col items-center mt-6">
                <div className="w-48 h-16 bg-[#ECF6FE] flex items-center justify-center rounded-md shadow-md">
                  <p className="text-[#00C1FE] text-lg font-semibold">Add Sequence</p>
                </div>
                <div className="h-[1in] w-1 bg-[#E6EBF5] flex items-center justify-center relative mt-2">
                  <span
                    className="relative inline-flex items-center justify-center w-10 h-10 text-2xl font-bold text-[#00C1FE] cursor-pointer"
                    onClick={() => addStep()}
                  >
                    +
                  </span>
                </div>
                {steps?.map((step, index) => (
                  <React.Fragment key={index}>
                    <div
                      className="bg-light-gray p-4 mb-2 rounded-md border flex flex-col items-start relative bg-[#F7FCFF] w-full cursor-pointer"
                      onClick={() => handleStepClick(index)}
                    >
                      <div className="flex items-center w-full">
                        <div className="w-8 h-8 flex items-center justify-center text-black rounded-full mr-4">
                          {index + 1}.
                        </div>
                        <div className="flex-grow flex items-center">
                          <Icon
                            icon="line-md:email"
                            style={{ fontSize: '20px', color: '#00C1FE' }}
                            className="cursor-pointer"
                          />
                          <Typography className="ml-2"> {step?.step_type}</Typography>
                        </div>
                      </div>
                      {step.template_id && (
                        <Typography className="mt-2 text-sm text-gray-600 ml-16">
                        {templates.find((t) => t.id === step.template_id)?.title || 'None'}
                        </Typography>
                      )}
                    </div>
                    <div className="h-[1in] w-1 bg-[#E6EBF5] flex items-center justify-center relative mt-2">
                      <span
                        className="relative inline-flex items-center justify-center w-10 h-10 text-2xl font-bold text-[#00C1FE] cursor-pointer"
                        onClick={() => addStep()}
                      >
                        +
                      </span>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>

          <div className="col-span-8 p-4 rounded-md relative">
            <div className="absolute left-0 top-0 bottom-0 w-[2px] bg-[#00C1FE]"></div>

            <div className="ml-[2px] pl-4">
              <div className="flex items-center justify-between mb-4 bg-[#F5F5F5] p-2">
                <div className="flex items-center">
                  <Icon icon="ic:outline-email" style={{ color: '#00C1FE', fontSize: '25px' }} />
                  <Typography variant="h6" className="ml-2 text-[#00C1FE]">
                    {selectedStepIndex !== null ? steps[selectedStepIndex]?.step_type : 'Outreach Email'}
                  </Typography>
                </div>
                <Select
                  value={template}
                  onChange={handleTemplateChange}
                  displayEmpty
                  className="w-60"
                  sx={{
                    '.MuiSelect-select': {
                      color: '#FFFFFF',
                      padding: '10px 14px',
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#00C1FE',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#00C1FE',
                    },
                    '.MuiSelect-select.MuiSelect-outlined': {
                      backgroundColor: '#00C1FE',
                      border : "2px solid #00C1FE",
                    },
                    '.MuiSelect-icon': {
                      color: '#FFFFFF',
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    Select template
                  </MenuItem>
                  {templates?.map((template) => (
                    <MenuItem key={template.id} value={template}>
                      {template.title}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <TemplatePreview template={template} />
            </div>
          </div>
        </div>
        <div className="flex justify-end mr-10 mb-10 ">
          <button className="border border-black rounded-lg  px-6 py-4  mr-4 text-lg font-medium">Cancel</button>
          <Button
            type="submit"
            className="border  px-10 py-4 rounded-lg  mr-4 text-lg font-medium text-white"
            style={{ backgroundColor: '#00C1FE', border: '2px' }}
            onClick={() =>{ createSequenceSteps()
              onCreateCampaign()
            }}
          >
            Create Campaign
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CreateSequence;
