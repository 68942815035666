import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { CompanyForm, PersonForm } from '.';
import JobPostForm from './JobpostForm';
import { Icon } from '@iconify/react';
import { Patch, Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { notify } from 'src/components/notify';
import { useSnackbar } from 'notistack';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 95deg, #00C1FE 0%, #00C1FE 50%, #00C1FE 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 95deg, #00C1FE 0%, #00C1FE 50%, #00C1FE 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient( 95deg, #00C1FE 0%, #00C1FE 50%, #00C1FE 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient( 95deg, #00C1FE 0%, #00C1FE 50%, #00C1FE 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <Icon icon="heroicons:building-office-2-solid" width="1.5rem" height="1.5rem" style={{ color: 'white' }} />,
    2: <Icon icon="ic:round-person" width="1.5rem" height="1.5rem" style={{ color: 'white' }} />,
    3: <Icon icon="uis:bag" width="1.5rem" height="1.5rem" style={{ color: 'white' }} />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const steps = ['Add Company', 'Add Person', 'Add Job Post'];

export default function CustomizedSteppers({ onFinish }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [companyValues, setCompanyValues] = React.useState();
  const [personValues, setPersonValues] = React.useState();
  const [formErrors, setFormErrors] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [addedCompany, setAddedCompany] = React.useState();
  const [addedPerson, setAddedPerson] = React.useState();
  const { enqueueSnackbar } = useSnackbar()
  const [jobPostValues, setJobPostValues] = React.useState();

  const handleNext = async () => {
    if (formErrors) {
      setErrorMessages(['Please fill out all fields.']);
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    // Call the respective API based on the active step
    switch (activeStep) {
      case 0:
        if (addedCompany) {
          updateCompany();
          return;
        }
        await addCompany(); // Call your add company API function here

        break;
      case 1:
        if (addedPerson) {
          updatePerson();
          return;
        }
        if(!addedCompany) {
          return;
        }
        await addPerson(); // Call your add person API function here
        break;
      case 2:
        if(!addedPerson){
          onFinish()
          return
        }
        await addJobPost();
        onFinish(); // Call your add job post API function here
        break;
      default:
        break;
    }

    // Clear error messages when moving to the next step
    setErrorMessages([]);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderForm = () => {
    switch (activeStep) {
      case 0:
        return (
          <CompanyForm
            onSubmit={(values, actions) => {
              setCompanyValues(values);
            }}
            isError={(isValid) => {
              setFormErrors(isValid);
            }}
            onUpdate={(values) => {
              setCompanyValues({ ...companyValues, ...values });
            }}
            addedValues={addedCompany}
          />
        );
      case 1:
        return (
          <PersonForm
            onSubmit={(values, actions) => {
              setPersonValues(values);
            }}
            isError={(isValid) => {
              setFormErrors(isValid);
            }}
            onUpdate={(values) => {
              setPersonValues({ ...personValues, ...values });
            }}
            addedPerson={addedPerson}
          />
        );
      case 2:
        return (
          <JobPostForm
            onSubmit={(values, actions) => {
              setJobPostValues(values);
            }}
            isError={(isValid) => {
              setFormErrors(isValid);
            }}
            onUpdate={(values) => {
              setJobPostValues({ ...jobPostValues, ...values });
            }}
          />
        );
      default:
        break;
    }
  };

  const addCompany = () => {
    try {
      Post(
        companyValues,
        API_URLS.addCompany,
        (resp) => {
          console.log(resp);
          //   console.log('Company added', resp)
          setAddedCompany(resp);
          // notify('Company added', 'success', 1000);
          enqueueSnackbar('Company added', { variant: 'success' })
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      // notify('Failed to add company', 'error', 1000);
      enqueueSnackbar('Failed to add company', { variant: 'error' })
    }
  };

  const updateCompany = () => {
    if (!addedCompany) {
      return; // Exit if addedCompany is not set
    }

    try {
      const values = companyValues;
      values.id = addedCompany?.id;
      Patch(
        values,
        API_URLS.update,
        (resp) => {
          console.log(resp);
          setAddedCompany(resp.updatedCompany);
          // notify('Company added', 'success', 1000);
          enqueueSnackbar ('Company updated', { variant: 'success' })
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      notify('Failed to add company', 'error', 1000);
    }
  };

  const addPerson = () => {
    personValues.companyId = addedCompany?.id;
    try {
      Post(
        personValues,
        API_URLS.addPerson,
        (resp) => {
          setAddedPerson(resp);
          // notify('Person added', 'success', 1000);
          enqueueSnackbar('Person added', { variant: 'success' })
          console.log(resp);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      // notify('Failed to add person', 'error', 1000);
      enqueueSnackbar('Failed to add person', { variant: 'error' })
    }
  };

  const updatePerson = () => {
    if (!addedPerson) {
      return; // Exit if addedPerson is not set
    }

    try {
      const values = personValues;
      values.id = addedPerson?.id;
      Patch(
        values,
        API_URLS.updatePerson,
        (resp) => {
          console.log(resp);
          setAddedPerson(resp.updatedPerson);
          // notify('Company added', 'success', 1000);
          enqueueSnackbar('Person updated', { variant: 'success' })
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      // notify('Failed to add company', 'error', 1000);
      enqueueSnackbar('Failed to update person', { variant: 'error' })
    }
  };
  const addJobPost = () => {
    jobPostValues.personId = addedPerson?.id;
    try {
      Post(
        jobPostValues,
        API_URLS.addJobPostWithPersonId,
        (resp) => {
          console.log(resp);
          // notify('Job post added', 'success', 1000);
          enqueueSnackbar('Job post added', { variant: 'success' })
        },
        (error) => {
          console.log(error);
          // notify('Error in adding job post', 'error', 1000);
        }
      );
    } catch (error) {
      // notify('Failed to add job post', 'error', 1000);
      enqueueSnackbar('Failed to add job post', { variant: 'error' })
    }
  };

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {renderForm()}
        <br />
        {errorMessages.length > 0 && (
          <div style={{ color: 'red', marginBottom: '20px' }}>
            {errorMessages.map((msg, index) => (
              <div key={index}>{msg}</div>
            ))}
          </div>
        )}
        <div className={`flex ${activeStep === 0 ? 'justify-end' : 'justify-between'}`} style={{ marginTop: 20 }}>
          {activeStep !== 0 && (
            <Button
              onClick={handleBack}
              className="text-[#00C1FE] border-[#00C1FE] hover:bg-blue-50 w-24 hover:border-[#00C1FE] h-10"
              variant="outlined"
            >
              Back
            </Button>
          )}
          {activeStep !== steps.length - 1 ? (
            <Button
              onClick={handleNext}
              variant="filled"
              className="bg-[#00C1FE] hover:bg-[#00C1FE] text-white text-lg h-10 w-24"
            >
              Next
            </Button>
          ) : (
            <Button
              onClick={handleNext}
              variant="filled"
              className="bg-[#00C1FE] hover:bg-[#00C1FE] text-white text-lg h-10 w-24"
            >
              Finish
            </Button>
          )}
        </div>
      </div>
    </Stack>
  );
}
