import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

function EditPersonDialog({ open, onClose, onSubmit, person }) {
  const initialValues = {
    profile_url: person ? person?.profile_url : '',
    first_name: person ? person?.first_name : '',
    last_name: person ? person?.last_name : '',
    current_title: person ? person?.current_title : '',
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={false}
        PaperProps={{
          sx: {
            ml: 'auto',
            mt: 'auto',
            mr: '2rem',
            width: '900px',
            height: '85%',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle sx={{ backgroundColor: '#F4F9FD' }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4">Edit Person Info </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <br />
          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              const personId = person?.id;
              values.personId = personId;
              onSubmit(values, actions);
            }}
          >
            {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <label htmlFor="profile_url" style={{ fontWeight: 'bold' }}>
                      Profile URL
                    </label>
                    <TextField
                      type="text"
                      id="profile_url"
                      name="profile_url"
                      placeholder={`Enter Profile URL`}
                      value={values.profile_url}
                      onChange={(e) => {
                        setFieldValue('profile_url', e.target.value);
                      }}
                      fullWidth
                      sx={{
                        marginTop: '5px',
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label htmlFor="first_name" style={{ fontWeight: 'bold' }}>
                      First Name
                    </label>
                    <TextField
                      type="text"
                      id="first_name"
                      name="first_name"
                      placeholder={`Enter First Name`}
                      value={values.first_name}
                      onChange={(e) => {
                        setFieldValue('first_name', e.target.value);
                      }}
                      fullWidth
                      sx={{
                        marginTop: '5px',
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label htmlFor="last_name" style={{ fontWeight: 'bold' }}>
                      Last Name
                    </label>
                    <TextField
                      type="text"
                      id="last_name"
                      name="last_name"
                      placeholder={`Enter Last Name`}
                      value={values.last_name}
                      onChange={(e) => {
                        setFieldValue('last_name', e.target.value);
                      }}
                      fullWidth
                      sx={{
                        marginTop: '5px',
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label htmlFor="current_title" style={{ fontWeight: 'bold' }}>
                      Current Title
                    </label>
                    <TextField
                      type="text"
                      id="current_title"
                      name="current_title"
                      placeholder={`EnterCurrent Title`}
                      value={values.current_title}
                      onChange={(e) => {
                        setFieldValue('current_title', e.target.value);
                      }}
                      fullWidth
                      sx={{
                        marginTop: '5px',
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <div className="flex items-center justify-center mt-4">
                  <Button
                    variant="outlined"
                    size="large"
                    className="text-white border-[#00C1FE]  bg-[#00C1FE] hover:border-[#00C1FE] hover:bg-[#00C1FE]"
                    style={{
                      outline: '1px solid rgba(0, 0, 0, 0.12)',
                      backdropFilter: 'blur(4px)',
                      marginRight: '1rem',
                    }}
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default EditPersonDialog;
