import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { Post } from 'src/actions/API/apiActions';
import { useSnackbar } from 'notistack';
import { API_URLS } from 'src/constants/apiURLs';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 720,
  margin: 'auto',
  minHeight: '90vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const ClientUnsubscriptionPage = () => {
  const [email, setEmail] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleUnsubscription = () => {
    try {
      Post(
        { email_id: email },
        API_URLS.unSubscribeFromOurServices,
        (resp) => {
          setEmail(null);
          enqueueSnackbar('Unsubscribed successfully, Thanks', { variant: 'success' });
        },
        (error) => {
          enqueueSnackbar(error.response.data.message || 'Failed to unsubscribe', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong at server', { variant: 'error' });
    }
  };
  return (
    <>
      <Helmet>
        <title>Unsubscribe </title>
      </Helmet>

      <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Typography variant="h5" sx={{ marginBottom: '4rem' }}>
          We regret that you've decided to unsubscribe, we thank you for the time you spent with Ultimate Outsourcing
          Services. If you ever need our services again, please don't hesitate to reach out.
        </Typography>
        <br />
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <TextField
              size="medium"
              fullWidth
              variant="outlined"
              placeholder="Enter your Email"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                backgroundColor: 'white',
                borderRadius: '0.3rem',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {},
                  '&:hover fieldset': {
                    borderColor: '#00C1FE',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#00C1FE',
                  },
                },
                '& .MuiInputBase-root': {
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 1px #00C1FE',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Button
              sx={{ marginTop: '15px', padding: '10px' }}
              variant="outlined"
              color="secondary"
              size="medium"
              fullWidth
              onClick={() => handleUnsubscription()}
            >
              Unsubscribe
            </Button>
          </Grid>
        </Grid>
      </StyledContent>
    </>
  );
};

export default ClientUnsubscriptionPage;
