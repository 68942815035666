import { Box, Dialog, DialogContent, DialogTitle, IconButton, Slide, Typography } from '@mui/material';
import React from 'react';
import { LeadStepper } from './components';
import { Close } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

export default function CreateLeadDialog({ open, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      hideBackdrop={false}
      disableBackdropClick
      disableEscapeKeyDown
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          ml: 'auto',
          mt: 'auto',
          mr: '2rem',
          width: '900px',
          height: '90%',
        },
      }}
      aria-describedby="alert-dialog-slide-description"
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: '#F4F9FD',
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" className='ml-8'>Create Lead</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ backgroundColor: '#F4F9FD' }}>
        <LeadStepper onFinish={()=> onClose()} />
      </DialogContent>
    </Dialog>
  );
}
