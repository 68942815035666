import React, { useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Alert, Typography } from '@mui/material';
import './editor.css';

const HTMLEditor = ({ editorLabel, htmlText, onEditorStateChange, showTags }) => {
  const leadTagList = [
    '[First Name]',
    '[Last Name]',
    '[Company Name]',
    '[Title]',
    '[Email]',
    '[Recent Job Post]',
    '[Industry]',
  ];
  const BDTags = ['[BD Name]', '[BD Title]', '[BD Email]', '[BD Telephone]'];
  const [copiedTag, setCopiedTag] = useState(null);

  const editorRef = useRef(null);

  const insertTextAtCursor = (text) => {
    const editor = editorRef.current;
    if (editor) {
      const model = editor.model;
      const selection = model.document.selection;
  
      if (selection) {
        model.change((writer) => {
          // Get the position to insert text
          const position = selection.getFirstPosition();
  
          // Insert text at the current position
          writer.insertText(text, position);
  
          // Move the selection to the end of the inserted text
          writer.setSelection(writer.createPositionAt(position.getShiftedBy(text.length)));
        });
      }
    }
  };
  

  const handleCopyToClipboard = (tag) => {
    navigator.clipboard.writeText(tag);
    setCopiedTag(tag);
    setTimeout(() => setCopiedTag(null), 1000); // Reset copiedTag after 1.5 seconds
  };

  return (
    <div>
      <h3>{editorLabel ? editorLabel : ''}</h3>
      {/* <Button onClick={() => insertTextAtCursor('[I am here]')}>Find Cursor in editor</Button> */}
      {showTags && (
        <>
          <Typography variant="h6" >
            Lead Tags
          </Typography>
          <div className="tag-list-container">
            <div className="tags-container">
              {leadTagList.map((tag, index) => (
                <span
                  key={index}
                  onClick={() => {
                    handleCopyToClipboard(tag);
                    insertTextAtCursor(tag);
                  }}
                  className={`tag ${copiedTag === tag ? 'copied' : ''}`}
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
          <Typography variant="h6" >
            BD Tags
          </Typography>

          <div className="tag-list-container">
            <div className="tags-container">
              {BDTags.map((tag, index) => (
                <span
                  key={index}
                  onClick={() => {
                    handleCopyToClipboard(tag);
                    insertTextAtCursor(tag);
                  }}
                  className={`tag ${copiedTag === tag ? 'copied' : ''}`}
                >
                  {tag}
                </span>
              ))}
            </div>
            {copiedTag && <p className="copied-message">Copied Tag: {copiedTag}</p>}
            <br />
            <Alert severity="error">Note : Do not edit the tags in the editor.</Alert>
          </div>
        </>
      )}
      <CKEditor
        editor={ClassicEditor}
        data={htmlText || ''}
        onReady={(editor) => {
          editorRef.current = editor;
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          onEditorStateChange({ data });
        }}
      />
    </div>
  );
};

export default HTMLEditor;
