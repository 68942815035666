import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ScrapperStatsCard } from '.';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import ScrapperPieChart from './ScrapperPieChart';

function ScrapperReportingPage({ from_date, to_date, industry, country_id, sector_id }) {
  const [scrapperReporting, setScrapperReporting] = useState();
  const getReporting = () => {
    try {
      Get(
        {
          from_date: from_date,
          to_date: to_date,
          industry: industry,
          country_id: country_id,
          sector_id: sector_id,
        },
        API_URLS.getScrapperReporting,
        (resp) => {
          console.log(resp);
          setScrapperReporting(resp?.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReporting();
  }, [from_date, to_date, industry, country_id, sector_id]);

  const assignedLeadsReporting = [
    {
      name: 'Emails Added',
      value: scrapperReporting ? scrapperReporting?.emailAdded : 0,
      color: '#00C1FE',
    },
    {
      name: 'Emails Not Added',
      value: scrapperReporting ? scrapperReporting?.emailNotAdded : 0,
      color: '#7ADD8A',
    },
  ];
  const emailsFoundReporting = [
    {
      name: 'Verified',
      value: scrapperReporting ? scrapperReporting?.verifiedLeads : 0,
      color: '#337D72',
    },
    {
      name: 'Bounced',
      value: scrapperReporting ? scrapperReporting?.bouncedLeads : 0,
      color: '#FF6060',
    },
  ];
  return (
    <div>

      <Grid container spacing={0.5} marginTop='6px'>
        <Grid item xs={12} md={6} lg={6} paddingLeft="5px">
          <ScrapperStatsCard
            mainLabel="Assigned Leads"
            leftLabel="Emails Added"
            rightLabel="Emails Not Added"
            leftNumber={scrapperReporting ? scrapperReporting?.emailAdded : 0}
            mainNumber={scrapperReporting ? scrapperReporting?.assignedLeads : 0}
            rightNumber={scrapperReporting ? scrapperReporting?.emailNotAdded : 0}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <ScrapperStatsCard
            mainLabel="Emails Added"
            leftLabel="Verified Emails"
            rightLabel="Bounced Emails"
            leftNumber={scrapperReporting ? scrapperReporting?.verifiedLeads : 0}
            mainNumber={scrapperReporting ? scrapperReporting?.emailAdded : 0}
            rightNumber={scrapperReporting ? scrapperReporting?.bouncedLeads : 0}
          />
        </Grid>
      </Grid>
    
      <Grid container spacing={0.5}  marginTop='6px'>
        <Grid item xs={12} md={6} lg={6}>
          <ScrapperPieChart title="Assigned Leads Reporting" chartData={assignedLeadsReporting} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <ScrapperPieChart title="Emails Found Reporting" chartData={emailsFoundReporting} />
        </Grid>
      </Grid>
      
    </div>
  );
}

export default ScrapperReportingPage;
