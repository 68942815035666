import React from 'react';
import { ResourcingCandidateSearch } from 'src/sections/resourcing';

function ResourcingCandidatePage(props) {
    return (
        <div>
            <ResourcingCandidateSearch/>
        </div>
    );
}

export default ResourcingCandidatePage;