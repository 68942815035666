import React from 'react';
import { Users } from 'src/sections/admin/Users';

function UsersPage(props) {
    return (
        <div>
            <Users />
        </div>
    );
}

export default UsersPage;