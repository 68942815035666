import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
  Grow,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

export default function MarkAsNotFound({ open, onClose, personIds , onUpdation }) {
    console.log('personI4r324ds', personIds);
    const userId = localStorage.getItem('userId');

    const markAsNoEmailFound = () =>{
        try {
            Post(
                {
                    user_id: userId,
                    persons: personIds
                },
                API_URLS.markAsEmailNotFound,
                (resp) =>{
                    enqueueSnackbar('Persons marked as email not found', { variant: 'success' });
                    onClose();
                    onUpdation();
                },
                (error) =>{
                    enqueueSnackbar('Error marking persons as email not found.', { variant: 'error' });
                }
            )
        } catch (error) {
            enqueueSnackbar('Somethign went wrong.', { variant: 'error' });  
        }
    }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '80%',
          minHeight: '20vh',
        },
      }}
      aria-describedby="alert-dialog-slide-description"
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <DialogTitle>
        <Box display="flex">
          <Typography variant="h4" className="ml-8 text-center">
            Mark as email not found
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" className="mt-8">
          Are you sure , you want to mark the person(s) as email not found?
        </Typography>
        <div className="flex justify-around flex-wrap mt-12">
          <Button
            onClick={onClose}
            style={{
              border: '2px solid black',
              borderRadius: '10px',
              width: '100px',
              padding: '8px 16px',
              textAlign: 'center',
            }}
          >
            No
          </Button>
          <Button
            style={{
              backgroundColor: '#FA8282',
              color: 'white',
              borderRadius: '10px',
              width: '100px',
              padding: '8px 16px',
              textAlign: 'center',
            }}
            onClick={() => markAsNoEmailFound()}
          >
            Yes
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
