import { Dialog, DialogContent, Slide } from '@mui/material';
import PersonDetailsCard from './components/PersonDetailsCard';
import React from 'react';


const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

export default function PersonDetailsDialog({ open, onClose }) {


  return (
    <Dialog
      open={open}
      onClose={onClose}
      hideBackdrop={false}
      disableBackdropClick
      disableEscapeKeyDown
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          ml: 'auto',
          mt: 'auto',
          mr: '2rem',
          width: '900px',
          height: '70%',
        },
      }}
      aria-describedby="alert-dialog-slide-description"
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
        },
      }}
    >
      <DialogContent>
        <PersonDetailsCard />
      </DialogContent>
    </Dialog>
  );
}
