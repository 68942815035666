import { Alert, Box, Grid, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const PreviewTemplate = ({ templateSubject , templateContent }) => {
  const [previewWidth, setPreviewWidth] = useState('100%');
  const [previewType, setPreviewType] = useState('desktop');
  const [regards, setRegards] = useState('');
  //set regards
  useEffect(() => {
    setRegards(`<body> 
            Regards
            <div>Amna Yaseen</div>
            <div>Senior Business Development Executive</div>
            <div>Email: <span style="margin-left: 2.5rem;"> amna@ultimateoutsourcing.co.uk</span></div>
            <div><b>Tel: <span style="margin-left: 4rem;"> 03065495216 </span></b></div>
            <div><b>Tel US: <span style="margin-left: 1.9rem;">+1 347 480 4177</span></b></div>
            <div>Website: <a href="https://ultimateoutsourcing.co.uk/" target="_blank"
                style="margin-left: 2rem;">www.ultimateoutsourcing.co.uk</a> </div>
          </b> <img src="https://ultimateoutsourcing.co.uk/wp-content/uploads/2021/03/300.png" alt="Ultimate outsourcing LTD"
          style="padding-top: 2rem; padding-bottom: 2rem;"<br>
          <p><p><i><strong>Ultimate outsourcing – Home to the UK’s, Europe, UAE, US's &amp; Canada largest Candidate Sourcing Services.</strong></i></p><p>Ultimate Outsourcing is a limited liability company registered in Wyoming USA Under Filling ID: 2023-001264578. Ultimate Outsourcing is a limited company registered in England &amp; Wales with Registered Number 12980312.</p><p>Disclaimer:&nbsp;<br>This email and any files transmitted with it are confidential and intended solely for the use of the individual or entity to whom they are addressed. If you have received this email in error please contact the sender and delete the message from your computer. Ultimate Outsourcing LTD &amp; Ultimate Outsourcing LLC has taken every reasonable step to minimize the risk of viruses, it cannot accept responsibility for any damage which you may sustain as a result of a virus attached to this e-mail. Click <a href="https://ultimateoutsourcing.co.uk/unsubscribe/">Unsubscribe</a> to unsubscribe our services.</p></p>
          </body>`);
  }, []);
  return (
    <>
      <div className="mt-20 ml-6">
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <>
            <Typography variant="subtitle1" sx={{ marginBottom: '1rem' }}>
              Template Preview
            </Typography>
            <div style={{ borderLeft: '5px solid grey' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  border: '1px solid',
                  borderRadius: '5px',
                  padding: '5px',
                  marginBottom: '5px',
                  marginLeft: '10px',
                  width: '500px',
                }}
              >
                Subject :{templateSubject ? templateSubject : ' '}
              </Typography>
            </div>

            <Box display="flex" justifyContent="flex-start" marginTop="20px" marginBottom="20px">
              <ToggleButtonGroup
                exclusive
                value={previewType}
                size="small"
                color="primary"
                onChange={(event, newType) => {
                  if (newType) {
                    setPreviewType(newType);
                    if (newType === 'desktop') {
                      setPreviewWidth('100%'); // Set to '100%' when desktop is selected
                    } else if (newType === 'mobile') {
                      setPreviewWidth('430px'); // Set to '430px' when mobile is selected
                    }
                  }
                }}
              >
                <ToggleButton value="desktop">Desktop</ToggleButton>
                <ToggleButton value="mobile">Mobile</ToggleButton>
              </ToggleButtonGroup>
              <TextField
                value={previewWidth}
                type="string"
                size="medium"
                fullWidth
                sx={{ width: '100px', marginLeft: '20px' }}
                onChange={(e) => setPreviewWidth(e.target.value)}
              />
            </Box>
            {!templateSubject && !templateContent ? (
              <Alert severity="warning">Select a template to preview</Alert>
            ) : (
              <Typography
                variant="subtitle2"
                sx={{
                  padding: '5px',
                  paddingLeft: '20px',
                  marginBottom: '5px',
                  marginLeft: '10px',
                  width: previewWidth,
                  height: '600px',
                  overflowX: 'auto', // Add horizontal scrollbar if necessary
                  overflowY: 'auto', // Add vertical scrollbar if necessary
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: templateContent ? templateContent + regards : '',
                  }}
                />
              </Typography>
            )}
          </>
        </Grid>
      </div>
    </>
  );
};

export default PreviewTemplate;
