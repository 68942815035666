import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Logo } from 'src/images';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// import account from '../../../_mock/account';
import { useSnackbar } from "notistack";
import { Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { serverURLForImages } from 'src/constants/serverURLs';


// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const userId = parseInt(localStorage.getItem('userId'))

  const [accountInfo, setAccountInfo] = useState({
    displayName: '',
    email: '',
    role: '',
    photoURL: '',
  });

  const handleMenuOptions = (option) => {
    switch (option.label) {
      case 'Home':
        navigate('/', { replace: true });
        break;
      case 'Profile':
        navigate('/profile', { replace: true });
        break;
      case 'Settings':
        navigate('/settings', { replace: true });
        break;
      default:
        navigate('/login', { replace: true });
        break;
    }
  };

  const handleLogOut = () => {
    const userId = parseInt(localStorage.getItem('userId'));
    try {
      Post(
        { userId: userId },
        API_URLS.logout,
        (resp) => {
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          enqueueSnackbar('Logged out', { variant: 'info' });
          navigate('/login');
        },
        (error) => {
          enqueueSnackbar('Something went Wrong at the server!', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went Wrong!', { variant: 'error' });
    }
  };

  const getUserById = useCallback(() => {
    Post(
      { userId: userId },
      API_URLS.getUserById,
      (resp) => {
        setAccountInfo({
          displayName: resp?.first_name + ' ' + resp?.last_name,
          email: resp?.email,
          role: resp?.role,
          photoURL: `${serverURLForImages}/${resp?.profile_image}`,
        });
      },
      (error) => {
        setAccountInfo({
          displayName: 'John Doe',
        });
      }
    );
  }, [userId]);

  useEffect(() => {
    getUserById();
  }, [userId]);

  return (
    <>
      
      <div className="grid grid-cols-1 gap-4 xl:grid-cols-4">
        <div className="flex items-center ml-4 mb-6 col-span-full xl:col-span-4">
          <div className="w-12 h-10  bg-white rounded-3xl flex items-center justify-center">
            <img src={Logo} alt="Ultimate. logo" width="30px" />
          </div>
          <div className="w-12 h-10 ml-5  bg-white rounded-3xl flex items-center justify-center">
            <Icon icon="octicon:bell-24" width="2rem" height="2rem" style={{ color: 'black' }} />
          </div>
          <div className="w-12 h-10 ml-5 bg-white rounded-3xl flex items-center justify-center">
            <Icon icon="uil:setting" width="2rem" height="2rem" style={{ color: 'black' }} /> &nbsp;
          </div>
          <div>
            <IconButton
              onClick={handleOpen}
              sx={{
                p: 2,
                ...(open && {
                  '&:before': {
                    zIndex: 1,
                    content: "''",
                    width: '100%',
                    height: '100%',
                    // paddingLeft : '15px',
                    borderRadius: '50%',
                    position: 'absolute',
                    bgcolor: (theme) => alpha(theme.palette.grey[200], 0.8),
                  },
                }),
              }}
            >
              <Avatar src={accountInfo?.photoURL} alt="photoURL" />
            </IconButton>
          </div>
        </div>
      </div>
      {/* <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton> */}

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {accountInfo?.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {accountInfo?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleMenuOptions(option)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            localStorage.removeItem('token');
            localStorage.removeItem('features');
            handleLogOut();
          }}
          sx={{ m: 1 }}
        >
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
