export default function ShowJobpostResults({ jobpost, person, company }) {
  console.log('person', person);
  function formatTimestamp(isoString) {
    const date = new Date(isoString);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };

    return date.toLocaleString('en-US', options);
  }

  return (
    <div key={jobpost.id} className="my-2 p-2 m-2 rounded-md border border-blue-300 bg-[#E1F7FC] h-28">
      <p>
        <b className="text-[#00C1FE] text-xl">{jobpost?.job_title}</b>
        <br />
        <p className="text-xl"> {formatTimestamp(jobpost?.createdAt)}</p>
        {person ? (
          <p className="text-xl">
            Posted by -{' '}
            <span className="font-bold">
              {person?.full_name} 
            </span>
          </p>
        ) : (
          <p className="text-xl">
            Posted by - <span className="font-bold">{company?.name}</span>
          </p>
        )}
      </p>
    </div>
  );
}
