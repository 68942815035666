import { Box, Button, Dialog, DialogContent, DialogTitle, Grow, Typography } from '@mui/material';
import React from 'react';
import { Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';
const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

function ShowPersonsDialog({ open, onClose, emails, status , onUpdation}) {
  console.log('emailhainyeh', emails);
  const user_id = localStorage.getItem('userId');
  const { enqueueSnackbar } = useSnackbar();
  const updateEmailInBulk = async () => {
    try {
      Post(
        {
          persons: emails,
          status: status,
          user_id: user_id,
        },
        API_URLS.updateThePersonEmailsStatusInBulk,
        (resp) => {
          enqueueSnackbar('Emails status updated successfully', { variant: 'success' });
          onClose();
          onUpdation()
        },
        (error) => {
          enqueueSnackbar('Error updating emails status', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Somethign went wrong.', { variant: 'error' });
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '80%',
            minHeight: '20vh',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle>
          <Box display="flex">
            <Typography variant="h4" className="ml-8 text-center">
              Action Confirmation
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" className="mt-8">
            Are you sure you want to mark the emails of these persons as verified?
          </Typography>
          <br />
          <div className="flex justify-around flex-wrap mt-12">
            <Button
              style={{
                border: '2px solid black',
                borderRadius: '10px',
                width: '100px',
                padding: '8px 16px',
                textAlign: 'center',
              }}
              onClick={() => onClose()}
            >
              No
            </Button>
            <Button
              style={{
                backgroundColor: '#FA8282',
                color: 'white',
                borderRadius: '10px',
                width: '100px',
                padding: '8px 16px',
                textAlign: 'center',
              }}
              onClick={() => {
                updateEmailInBulk();
              }}
            >
              Yes
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ShowPersonsDialog;
