import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import dayjs from 'dayjs';
import { allServices, companySize, subscriptionTypes } from 'src/constants/constants';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

function AddNewClient({ open, onClose, onSubmit }) {
  const userId = localStorage.getItem('userId');

  const initialValue = {
    serviceId: null,
    clientNumber: '',
    status: 'Active',
    firstName: '',
    lastName: '',
    jobTitle: '',
    companyName: '',
    email: '',
    subscriptionType: '',
    noOfCredits: null,
    noOfCreditsPerDay: null,
    noOfLeads: 0,
    projectLength: 0,
    startDate: null,
    endDate: null,
    reminderDate: null,
    amount: 0,
    companySize: null,
    phoneNumber: null,

    selectedService: null,

    acmId: userId,
  };

  //   const basicValidationSchema = Yup.object().shape({
  //     selectedService: Yup.object().required(),
  //     firstName: Yup.string().required('Enter first name'),
  //     lastName: Yup.string().required('Enter last name'),
  //     jobTitle: Yup.string().required('Enter job title'),
  //     companyName: Yup.string().required('Enter company name'),
  //     email: Yup.string().email('Enter a valid email').required('Email is required'),
  //     clientNumber: Yup.number()
  //       .required('Client Number is required')
  //       .test('is-in-service-range', 'Client Number is out of range', function (value) {
  //         const { selectedService } = this.parent; // Get the selected service object from the form data
  //         if (selectedService) {
  //           return value >= selectedService?.minId && value <= selectedService?.maxId;
  //         }
  //         return true; // If selectedService is not provided, validation passes
  //       }),
  //     noOfCredits: Yup.number()
  //       .nullable(false)
  //       .test('is-valid-credits', 'Number of credits is required for selected services', function (value) {
  //         const { selectedService } = this.parent; // Get the selected service object from the form data
  //         if (selectedService) {
  //           const { serviceName } = selectedService;
  //           const servicesRequiringCredits = ['CV-Sourcing', 'Pre-Qualification', '360/Direct'];
  //           const isServiceRequiringCredits = servicesRequiringCredits.includes(serviceName);
  //           // Check if the number of credits is required for selected services
  //           if (isServiceRequiringCredits) {
  //             return !!value;
  //           }
  //         }
  //         return true; // If not applicable, validation passes
  //       }),
  //     noOfCreditsPerDay: Yup.number()
  //       .nullable(false)
  //       .test(
  //         'is-valid-credits-per-day',
  //         'Number of credits per day is required for selected services',
  //         function (value) {
  //           const { selectedService } = this.parent; // Get the selected service object from the form data
  //           if (selectedService) {
  //             const { serviceName } = selectedService;
  //             const servicesRequiringCreditsPerDay = ['CV-Sourcing', 'Pre-Qualification', '360/Direct'];
  //             const isServiceRequiringCreditsPerDay = servicesRequiringCreditsPerDay.includes(serviceName);
  //             // Check if the number of credits per day is required for selected services
  //             if (isServiceRequiringCreditsPerDay) {
  //               return !!value;
  //             }
  //           }
  //           return true; // If not applicable, validation passes
  //         }
  //       )
  //       .test('is-less', 'Credits per day must be less than total credits', function (value) {
  //         const credits = this.parent.noOfCredits;
  //         if (credits) {
  //           return value < credits;
  //         }
  //         return true;
  //       }),
  //     startDate: Yup.date().nullable().required('Start Date is required'),
  //     endDate: Yup.date()
  //       .nullable()
  //       .min(Yup.ref('startDate'), 'End Date must be after Start Date')
  //       .required('End date is required'),

  //     reminderDate: Yup.date()
  //       .nullable()
  //       .min(Yup.ref('startDate'), 'Reminder Date must be after Start Date')
  //       .max(Yup.ref('endDate'), 'Reminder Date must be before End Date')
  //       .test('is-valid-reminder-date', 'Reminder Date is required for selected services', function (value) {
  //         const { selectedService, startDate } = this.parent; // Get the selected service object from the form data
  //         if (selectedService) {
  //           const { serviceName } = selectedService;
  //           const servicesRequiringReminder = ['CV-Sourcing', 'Pre-Qualification', '360/Direct'];
  //           const isServiceRequiringReminder = servicesRequiringReminder.includes(serviceName);
  //           // Check if the reminder date is required for selected services
  //           if (isServiceRequiringReminder && startDate) {
  //             return !!value;
  //           }
  //         }
  //         return true; // If not applicable, validation passes
  //       })
  //       .typeError('Invalid date format'),
  //   });

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            display: 'flex',
            width: '100%',
            minHeight: '25vh',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle style={{ backgroundColor: '#D1F3FA', width: '100%' }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5">Add New Client</Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {/* <animated.div style={animatedProps}> */}
          <Formik
            initialValues={initialValue}
            // validationSchema={basicValidationSchema}
            onSubmit={(values, actions) => {
              onSubmit(values, actions);
            }}
          >
            {({ values, errors, getFieldProps, setFieldValue, touched, handleSubmit, handleBlur }) => (
              <Form>
                <br />
                <Grid container spacing={2}>
                  {/* Service Selection */}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Autocomplete
                      id="serviceId"
                      options={allServices || []}
                      value={values.selectedService}
                      defaultValue={values.selectedService}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newService) => {
                        if (newService) {
                          setFieldValue('serviceId', newService.id);
                          setFieldValue('selectedService', newService);
                        } else {
                          setFieldValue('selectedService', null);
                          setFieldValue('serviceId', null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Service"
                          fullWidth
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {values.selectedService && (
                    <>
                      {/* Cleint Number */}
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          id="clientNumber"
                          name="clientNumber"
                          value={values.clientNumber}
                          type="text"
                          // placeholder={`Enter client number [ ${values?.selectedService?.minId} and ${values?.selectedService?.maxId} ]`}
                          placeholder={`Enter client number [1 - 1000]`}
                          {...getFieldProps('clientNumber')}
                          size="small"
                          onBlur={handleBlur}
                          error={touched.clientNumber && Boolean(errors.clientNumber)}
                          helperText={touched.clientNumber && errors.clientNumber}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>
                      {/* First Name */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="firstName"
                          type="text"
                          value={values.firstName}
                          {...getFieldProps('firstName')}
                          placeholder="First Name"
                          size="small"
                          error={touched.firstName && Boolean(errors.firstName)}
                          helperText={touched.firstName && errors.firstName}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>
                      {/* Last Name */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="lastName"
                          value={values.lastName}
                          type="text"
                          {...getFieldProps('lastName')}
                          placeholder="Last Name"
                          size="small"
                          error={touched.lastName && Boolean(errors.lastName)}
                          helperText={touched.lastName && errors.lastName}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>
                      {/* jobTitle */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="jobTitle"
                          type="text"
                          value={values.jobTitle}
                          {...getFieldProps('jobTitle')}
                          placeholder="Job Title"
                          size="small"
                          error={touched.jobTitle && Boolean(errors.jobTitle)}
                          helperText={touched.jobTitle && errors.jobTitle}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>
                      {/* companyName */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="companyName"
                          type="text"
                          value={values.companyName}
                          {...getFieldProps('companyName')}
                          placeholder="Company Name"
                          size="small"
                          error={touched.companyName && Boolean(errors.companyName)}
                          helperText={touched.companyName && errors.companyName}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Autocomplete
                          id="companySize"
                          size="small"
                          fullWidth
                          value={values?.companySize} // Change here
                          options={companySize}
                          getOptionplaceholder={(option) => option}
                          onChange={(event, newValue) => {
                            setFieldValue('companySize', newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Company Size"
                              variant="outlined"
                              required
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{
                                backgroundColor: 'white',
                                borderRadius: '0.3rem',
                                marginBottom: '3px',
                                '& .MuiOutlinedInput-root': {
                                  '&:hover fieldset': {
                                    borderColor: '#00C1FE',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: '#00C1FE',
                                  },
                                },
                                '& .MuiInputBase-root': {
                                  '&.Mui-focused': {
                                    boxShadow: '0 0 0 1px #00C1FE',
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </Grid>
                      {/* Email */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="email"
                          type="text"
                          value={values.email}
                          {...getFieldProps('email')}
                          placeholder="Email"
                          size="small"
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="phoneNumber"
                          type="number"
                          value={values.phoneNumber}
                          {...getFieldProps('phoneNumber')}
                          placeholder="Phone Number"
                          size="small"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>
                      {/* Subscription Type */}
                      {(values?.selectedService?.serviceName === 'CV-Sourcing' ||
                        values.selectedService.serviceName === 'Pre-Qualification' ||
                        values?.selectedService?.serviceName === '360/Direct') && (
                        <>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Autocomplete
                              id="serviceId"
                              size="small"
                              options={subscriptionTypes}
                              fullWidth
                              getOptionLabel={(option) => option.label}
                              onChange={(event, newService) => {
                                if (newService) {
                                  setFieldValue('subscriptionType', newService.id);
                                } else {
                                  setFieldValue('subscriptionType', null);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Subscription Type"
                                  fullWidth
                                  sx={{
                                    backgroundColor: 'white',
                                    borderRadius: '0.3rem',
                                    marginBottom: '3px',
                                    '& .MuiOutlinedInput-root': {
                                      '&:hover fieldset': {
                                        borderColor: '#00C1FE',
                                      },
                                      '&.Mui-focused fieldset': {
                                        borderColor: '#00C1FE',
                                      },
                                    },
                                    '& .MuiInputBase-root': {
                                      '&.Mui-focused': {
                                        boxShadow: '0 0 0 1px #00C1FE',
                                      },
                                    },
                                  }}
                                  k
                                />
                              )}
                            />
                          </Grid>
                          {/* Credits */}
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                              name="noOfCredits"
                              id="noOfCredits"
                              type="number"
                              {...getFieldProps('noOfCredits')}
                              placeholder="Credits"
                              value={values.noOfCredits}
                              size="small"
                              fullWidth
                              error={touched.noOfCredits && Boolean(errors.noOfCredits)}
                              helperText={touched.noOfCredits && errors.noOfCredits}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{
                                backgroundColor: 'white',
                                borderRadius: '0.3rem',
                                marginBottom: '3px',
                                '& .MuiOutlinedInput-root': {
                                  '&:hover fieldset': {
                                    borderColor: '#00C1FE',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: '#00C1FE',
                                  },
                                },
                                '& .MuiInputBase-root': {
                                  '&.Mui-focused': {
                                    boxShadow: '0 0 0 1px #00C1FE',
                                  },
                                },
                              }}
                            />
                          </Grid>
                          {/* Credits Per Day */}
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                              name="noOfCreditsPerDay"
                              id="noOfCreditsPerDay"
                              type="number"
                              {...getFieldProps('noOfCreditsPerDay')}
                              placeholder="Credits Per Day"
                              size="small"
                              fullWidth
                              value={values.noOfCreditsPerDay}
                              error={touched.noOfCreditsPerDay && Boolean(errors.noOfCreditsPerDay)}
                              helperText={touched.noOfCreditsPerDay && errors.noOfCreditsPerDay}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{
                                backgroundColor: 'white',
                                borderRadius: '0.3rem',
                                marginBottom: '3px',
                                '& .MuiOutlinedInput-root': {
                                  '&:hover fieldset': {
                                    borderColor: '#00C1FE',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: '#00C1FE',
                                  },
                                },
                                '& .MuiInputBase-root': {
                                  '&.Mui-focused': {
                                    boxShadow: '0 0 0 1px #00C1FE',
                                  },
                                },
                              }}
                            />
                          </Grid>
                        </>
                      )}

                      {/* No Of Leads */}

                      {values?.selectedService?.serviceName === 'Lead-Generation' && (
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="noOfLeads"
                            type="number"
                            placeholder="No of leads"
                            {...getFieldProps('noOfLeads')}
                            size="small"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              backgroundColor: 'white',
                              borderRadius: '0.3rem',
                              marginBottom: '3px',
                              '& .MuiOutlinedInput-root': {
                                '&:hover fieldset': {
                                  borderColor: '#00C1FE',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#00C1FE',
                                },
                              },
                              '& .MuiInputBase-root': {
                                '&.Mui-focused': {
                                  boxShadow: '0 0 0 1px #00C1FE',
                                },
                              },
                            }}
                          />
                        </Grid>
                      )}
                      {/* Project Length */}
                      {(values?.selectedService?.serviceName === 'IT and Consulting' ||
                        values?.selectedService?.serviceName === 'VA') && (
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="projectLength"
                            type="number"
                            {...getFieldProps('projectLength')}
                            placeholder="Project Length (optional)"
                            size="small"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              backgroundColor: 'white',
                              borderRadius: '0.3rem',
                              marginBottom: '3px',
                              '& .MuiOutlinedInput-root': {
                                '&:hover fieldset': {
                                  borderColor: '#00C1FE',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#00C1FE',
                                },
                              },
                              '& .MuiInputBase-root': {
                                '&.Mui-focused': {
                                  boxShadow: '0 0 0 1px #00C1FE',
                                },
                              },
                            }}
                          />
                        </Grid>
                      )}

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          name="amount"
                          id="amount"
                          type="number"
                          {...getFieldProps('amount')}
                          placeholder="Amount"
                          value={values.amount}
                          size="small"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="startDate"
                          name="startDate"
                          type="date"
                          label="Start Date"
                          placeholder={`Select start date`}
                          {...getFieldProps('startDate')}
                          value={values.startDate ? dayjs(values.startDate).format('YYYY-MM-DD') : 'Select Date'}
                          //   defaultValue={values.s}
                          size="small"
                          error={touched.startDate && Boolean(errors.startDate)}
                          helperText={touched.startDate && errors.startDate}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="endDate"
                          name="endDate"
                          type="date"
                          placeholder={`Select end date`}
                          {...getFieldProps('endDate')}
                          label="End Date"
                          value={values.endDate ? dayjs(values.endDate).format('YYYY-MM-DD') : 'Select Date'}
                          //   defaultValue={values.endDate ? dayjs(values.endDate).format('YYYY-MM-DD') : ''}
                          size="small"
                          error={touched.endDate && Boolean(errors.endDate)}
                          helperText={touched.endDate && errors.endDate}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="reminderDate"
                          name="reminderDate"
                          type="date"
                          placeholder={`Select reminder date`}
                          {...getFieldProps('reminderDate')}
                          size="small"
                          label="Reminder Date"
                          value={values.reminderDate ? dayjs(values.reminderDate).format('YYYY-MM-DD') : 'Select Date'}
                          //   defaultValue={values.reminderDate ? dayjs(values.reminderDate).format('YYYY-MM-DD') : ''}
                          error={touched.reminderDate && Boolean(errors.reminderDate)}
                          helperText={touched.reminderDate && errors.reminderDate}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Box display="flex" justifyContent="flex-end" marginTop="1rem">
                  <Button
                    size="medium"
                    sx={{
                      backgroundColor: '#A3A3A3',
                      fontSize: '16px',
                      color: 'white',
                      marginLeft: '1rem',
                      '&:hover': {
                        color: 'white !important',
                        backgroundColor: '#A3A3A3 !important',
                      },
                    }}
                    onClick={onClose}
                  >
                    Cancle
                  </Button>
                  <Button
                    size="medium"
                    sx={{
                      backgroundColor: '#0ec4fe',
                      fontSize: '16px',
                      color: 'white',
                      marginLeft: '1rem',
                      '&:hover': {
                        color: 'white !important',
                        backgroundColor: '#0ec4fe !important',
                      },
                    }}
                    onClick={() => {
                      if (Object.keys(errors)?.length > 0) {
                        // notify('Please fill all required fields', 'error', 1000);
                      } else {
                        handleSubmit();
                      }
                    }}
                  >
                    Add Client
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
          {/* </animated.div> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddNewClient;
