// src/components/chart/HighchartsBarChart.js

import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// Define the BarChart component
export default function HighchartsBarChart({ title, subheader, chartData, chartLabels }) {
  const options = {
    chart: {
      type: 'bar', // Set chart type to 'bar' for horizontal bars
      height: '440px', // Set the height of the chart
      backgroundColor: 'white', // Set background color
      style: {
        fontFamily: 'Arial, sans-serif', // Optional: Customize font
      },
      plotBorderWidth: 1,
      plotShadow: true, // Enable shadow
    },
    title: {
      text: title,
    },
    subtitle: {
      text: subheader,
    },
    xAxis: {
      categories: chartLabels,
      title: {
        text: null,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Values',
        align: 'high',
      },
      labels: {
        overflow: 'justify',
      },
    },
    tooltip: {
      valueSuffix: ' units',
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
        pointPadding: 0.2, // Adjust spacing between bars
        borderWidth: 0,
      },
    },
    credits: {
      enabled: false,
    },
    series: chartData?.map((series) => ({
      ...series,
      data: series?.data?.map((value, index) => ({
        y: value,
        color: series.colors && series.colors[index] ? series.colors[index] : undefined,
      })),
    })),
  };

  return (
    <div style={{
      backgroundColor: '#f9f9f9', // Background color for the container
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Shadow for the container
      padding: '20px', // Padding around the chart
      borderRadius: '8px', // Rounded corners
    }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
