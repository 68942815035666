import React from 'react';
import { Clients } from 'src/sections/acm';


function ACMManageClientsPage(props) {
    return (
        <div>
            <Clients/>
        </div>
    );
}

export default ACMManageClientsPage;