import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';
import { Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

function UpdateUserDialog({ open, onClose, updateUser, onUpdate }) {
  console.log('543', updateUser);
  const { enqueueSnackbar } = useSnackbar();
  const [features, setFeatures] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const getAllFeatures = () => {
    try {
      Get(
        {},
        API_URLS.findAllFeatures,
        (resp) => {
          console.log('resp', resp);
          setFeatures(resp?.data);
        },
        (error) => {
          console.log('error', error);
        }
      );
    } catch (error) {
      enqueueSnackbar('Failed to get features', { variant: 'error' });
    }
  };
  useEffect(() => {
    getAllFeatures();
  }, []);

  const handleFeatureSelect = (featureId) => {
    setSelectedFeatures((prevSelected) =>
      prevSelected.includes(featureId) ? prevSelected.filter((id) => id !== featureId) : [...prevSelected, featureId]
    );
  };

  const initialValue = (updateUser) => ({
    first_name: '',
    last_name: '',
    phone_number: '',
    cnic: '',
    email: '',
    designation: '',
    ...(updateUser && {
      id: updateUser.id,
      first_name: updateUser.first_name,
      last_name: updateUser.last_name,
      phone_number: updateUser.phone_number,
      cnic: updateUser.cnic,
      email: updateUser.email,
      designation: updateUser.designation,
    }),
  });

  const handleSubmit = (values, actions) => {
    // setSignature(values);
    // onSubmit(values, actions);
    values.feature_ids = selectedFeatures;
    onUpdate(values, actions);
    console.log('values', values);
  };

  useEffect(() => {
    if (updateUser?.feature_ids) {
      setSelectedFeatures(updateUser.feature_ids);
    }
  }, [updateUser]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={false}
        disableBackdropClick
        disableEscapeKeyDown
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            ml: 'auto',
            mt: 'auto',
            mr: '2rem',
            width: '900px',
            height: '90%',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#F4F9FD',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4" className="ml-8">
              Update User Profile
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <br />
          <Formik enableReinitialize initialValues={initialValue(updateUser)} onSubmit={handleSubmit}>
            {({ values, errors, touched, setFieldValue, handleSubmit, getFieldProps, isSubmitting }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <label htmlFor="first_name" style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                      First Name
                    </label>
                    <TextField
                      id="first_name"
                      fullWidth
                      name="first_name"
                      error={Boolean(touched.first_name && errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                      {...getFieldProps('first_name')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <label htmlFor="last_name" style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                      First Name
                    </label>

                    <TextField
                      id="last_name"
                      fullWidth
                      name="last_name"
                      error={Boolean(touched.last_name && errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                      {...getFieldProps('last_name')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <label htmlFor="email" style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                      Email
                    </label>
                    <TextField
                      id="email"
                      fullWidth
                      name="email"
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      {...getFieldProps('email')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <label htmlFor="phone_number" style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                      Contact Number
                    </label>
                    <TextField
                      id="phone_number"
                      fullWidth
                      name="phone_number"
                      error={Boolean(touched.phone_number && errors.phone_number)}
                      helperText={touched.phone_number && errors.phone_number}
                      {...getFieldProps('phone_number')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <label htmlFor="cnic" style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                      CNIC ( Without Dashes )
                    </label>
                    <TextField
                      id="cnic"
                      fullWidth
                      name="cnic"
                      value={values.cnic}
                      error={Boolean(touched.cnic && errors.cnic)}
                      helperText={touched.cnic && errors.cnic}
                      onChange={(e) => {
                        const value = e.target.value;
                        let formattedValue = value.replace(/\D/g, ''); // Remove non-digit characters

                        if (formattedValue.length > 13) {
                          formattedValue = formattedValue.substring(0, 13); // Limit the length to 13 characters
                        }

                        setFieldValue('cnic', formattedValue);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <label htmlFor="designation" style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                      Add / Change Designation
                    </label>
                    <TextField
                      id="designation"
                      fullWidth
                      name="designation"
                      value={values.designation}
                      error={Boolean(touched.designation && errors.designation)}
                      helperText={touched.designation && errors.designation}
                      {...getFieldProps('designation')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                <Typography variant="subtitle1" style={{ marginTop: '10px', marginLeft: '5px' }}>
                  Features access
                </Typography>
                {/* map the features as check boxes and 2 features in one row , */}
                <Grid container spacing={2}>
                  {features?.map((feature) => (
                    <Grid item xs={12} sm={6} key={feature.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedFeatures.includes(feature.id)}
                            onChange={() => handleFeatureSelect(feature.id)}
                            style={{ color: '#00C1FE', marginLeft: '10px' }}
                          />
                        }
                        label={feature.feature}
                      />
                    </Grid>
                  ))}
                </Grid>

                <Box display="flex" alignItems="flex-end" justifyContent="flex-end" sx={{ marginTop: '2rem' }}>
                  <button
                    className="mt-4 mr-4 border-2 border-[#00C1FE] text-sm bg-white text-[#00C1FE] px-2 py-2 rounded-md hover:bg-[#00C1FE] hover:text-white"
                    onClick={() => onClose()}
                    sx={{ marginRight: '20px' }}
                    size="small"
                  >
                    Cancel Update
                  </button>
                  <LoadingButton
                    loading={isSubmitting}
                    onClick={() => {
                      handleSubmit();
                      onClose();
                    }}
                    className="px-2 py-[9px] mt-4 text-white bg-[#00C1FE] hover:bg-[#00C1FE] text-sm rounded-md "
                  >
                    Update Profile
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default UpdateUserDialog;
