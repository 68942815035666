import { useState } from 'react';
import { Icon } from '@iconify/react';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import { notify } from 'src/components/notify';
// @mui

import { roles, workingModes } from 'src/constants/constants';
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  Grid,
} from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
//
import { API_URLS } from 'src/constants/apiURLs';
import { Post } from 'src/actions/API/apiActions';
import UploadImage from 'src/components/UploadImage';
import FormControlContext from '@mui/material/FormControl/FormControlContext';
// ----------------------------------------------------------------------

export default function RegisterForm({ onSubmit }) {
  const [showhashedPassword, setShowhashedPassword] = useState(false);
  const [userValues, setuserValues] = useState({});
  const genderOptions = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
  ];

  // const formatPhoneNumber = (number) => {
  //   const phoneNumber = parsePhoneNumberFromString(number, 'US');
  //   return phoneNumber ? phoneNumber.formatInternational() : number;
  // };

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    // Remove all non-digit characters
    const phoneNumber = value.replace(/\D/g, '');

    // Format the phone number with spaces
    const formattedPhoneNumber = phoneNumber.replace(/(\d{2})(\d{3})(\d{5})/, '$1 $2 $3');

    return formattedPhoneNumber.trim();
  };
  
  const initialValues = {
    profile_image: '',
    first_name: '',
    last_name: '',
    user_name: '',
    cnic: '',
    email: '',
    phone_number: formatPhoneNumber(''),
    gender: '',
    password: '',
    // confirmhashedPassword: '',
    role: '',
    working_mode: '',
  };
  const ValidationSchema = Yup.object().shape({
    // user_name cannot contain spaces
    user_name: Yup.string()
      .min(3, 'user_name must be at least 3 characters')
      .matches(/^\S+$/, 'user_name cannot contain spaces')
      .required('user_name is required'),
    cnic: Yup.string().required('CNIC is required'),
    phone_number: Yup.string().required('Phone number is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string().min(6, 'password must be at least 6 characters').required('password is required'),
    confirmhashedPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'password must match')
      .required('Confirm password is required'),
    role: Yup.string().required('Role is required'),
  });

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          values.isvarified = false;
          values.status = 'inactive';
          onSubmit(values, actions);
          // addCreateuser(values, actions);
        }}
      >
        {({ errors, touched, handleSubmit, isSubmitting, values, setFieldValue, getFieldProps }) => {
          console.log('values', values);
          return (
            <Form>
              <Stack spacing={3}>
                <TextField
                  id="first_name"
                  fullWidth
                  size="small"
                  name="first_name"
                  label="First Name"
                  error={Boolean(touched.first_name && errors.first_name)}
                  helperText={touched.first_name && errors.first_name}
                  {...getFieldProps('first_name')}
                />
                <TextField
                  id="last_name"
                  fullWidth
                  size="small"
                  name="last_name"
                  label="Last Name"
                  error={Boolean(touched.last_name && errors.last_name)}
                  helperText={touched.last_name && errors.last_name}
                  {...getFieldProps('last_name')}
                />
                <TextField
                  id="user_name"
                  fullWidth
                  size="small"
                  name="user_name"
                  label="User Name"
                  error={Boolean(touched.user_name && errors.user_name)}
                  helperText={touched.user_name && errors.user_name}
                  {...getFieldProps('user_name')}
                />
                <TextField
                  id="email"
                  fullWidth
                  size="small"
                  name="email"
                  label="Email"
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  {...getFieldProps('email')}
                />
                <PhoneInput
                  style={{ width: '100%' }}
                  inputStyle={{
                    height: '55px',
                    width: '100%',
                  }}
                  international
                  mask="999-999-9999"
                  defaultCountry="US"
                  name="phone_number"
                  value={values.phone_number}
                  onChange={(value) => {
                    const formattedValue = formatPhoneNumber(value);
                    setFieldValue('phone_number', formattedValue);
                  }}
                  error={touched.phone_number && Boolean(errors.phone_number)}
                  helperText={touched.phone_number && errors.phone_number}
                  // fullWidth

                  inputComponent={TextField}
                  inputProps={{
                    id: 'phone_number',
                    name: 'phone_number',

                    variant: 'outlined',

                    fullWidth: true,
                  }}
                />

                <FormControl component="fieldset" sx={{ mb: 3 }}>
                  <FormLabel component="legend">Gender</FormLabel>
                  <RadioGroup aria-label="gender" {...getFieldProps('gender')} row>
                    <Grid container direction="row" spacing={2}>
                      {genderOptions.map((option) => (
                        <Grid item key={option.value}>
                          <FormControlLabel value={option.value} control={<Radio />} label={option.label} />
                        </Grid>
                      ))}
                    </Grid>
                  </RadioGroup>
                </FormControl>
                <TextField
                  id="cnic"
                  fullWidth
                  size="small"
                  name="cnic"
                  value={values.cnic}
                  label="CNIC ( Without Dashes )"
                  error={Boolean(touched.cnic && errors.cnic)}
                  helperText={touched.cnic && errors.cnic}
                  onChange={(e) => {
                    const value = e.target.value;

                    // insert a dash after 5 digits, and after 13 digits
                    let formattedValue = value.replace(/\D/g, '').replace(/^(.{5})(.{7})(.*)$/, '$1-$2-$3');

                    // let formattedValue = value.replace(/\D/g, ''); // Remove non-digit characters

                    if (formattedValue.length > 15) {
                      formattedValue = formattedValue.substring(0, 15); // Limit the length to 13 characters
                    }

                    setFieldValue('cnic', formattedValue);
                  }}
                />
                <UploadImage onSubmit={(fileName) => setFieldValue('profile_image', fileName)} />

                <TextField
                  id="password"
                  fullWidth
                  size="small"
                  name="password"
                  label="password"
                  type={showhashedPassword ? 'text' : 'password'}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  {...getFieldProps('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowhashedPassword(!showhashedPassword)} edge="end">
                          <Icon icon={showhashedPassword ? 'ph:eye' : 'iconamoon:eye-off'} width="1rem" height="1rem" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  id="confirmhashedPassword"
                  fullWidth
                  size="small"
                  {...getFieldProps('confirmhashedPassword')}
                  error={Boolean(touched.confirmhashedPassword && errors.confirmhashedPassword)}
                  helperText={touched.confirmhashedPassword && errors.confirmhashedPassword}
                  name="confirmhashedPassword"
                  label="Confirm Password"
                  type={showhashedPassword ? 'text' : 'hashedPassword'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowhashedPassword(!showhashedPassword)} edge="end">
                          <Iconify icon={showhashedPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Autocomplete
                  id="role"
                  fullWidth
                  size="small"
                  options={roles}
                  getOptionLabel={(option) => option.role}
                  onChange={(event, newValue) => {
                    setFieldValue('role', newValue.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.role && errors.role)}
                      helperText={touched.role && errors.role}
                      {...getFieldProps('role')}
                      label="Role"
                      variant="outlined"
                    />
                  )}
                />
                {values.role !== '' && values.role !== 'admin' && (
                  <Autocomplete
                    id="working_mode"
                    fullWidth
                    size="small"
                    options={workingModes}
                    getOptionLabel={(option) => option.workingMode}
                    onChange={(event, newValue) => {
                      setFieldValue('working_mode', newValue.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.working_mode && errors.working_mode)}
                        helperText={touched.working_mode && errors.working_mode}
                        {...getFieldProps('working_mode')}
                        label="Work Mode"
                        variant="outlined"
                      />
                    )}
                  />
                )}
              </Stack>
              <br />
              <LoadingButton
                loading={isSubmitting}
                fullWidth
                size="large"
                variant="contained"
                className='bg-[#00C1FE]'
                onClick={() => {
                  handleSubmit();
                }}
              >
                Sign Up
              </LoadingButton>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
