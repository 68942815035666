import { Icon } from '@iconify/react';
import { Box } from '@mui/material';

export default function CompanyCard({ company, onClick }) {
  console.log(company);
  return (
    <div
      className="flex items-center p-4 bg-white border-[grey] hover:border-[#00C1FE] border-[1px] cursor-pointer rounded-r-lg rounded-l-lg w-full  transition-colors duration-300 shadow-lg h-32 mt-4 active:border-[#00C1FE]"
      onClick={onClick}
    >
      <div className="flex-shrink-0 w-16 h-16 border-2 border-gray-300 rounded-full flex items-center justify-center">
        {company?.logo !== null ? (
          <img src={company?.logo} alt="Profile" className="w-12 h-12 object-cover " />
        ) : (
          <div className="w-14 h-14 bg-gray-100 rounded-full flex border-[#00C1FE]  items-center justify-center">
            {company?.name?.charAt(0)}
          </div>
        )}
      </div>
      <div className="ml-4">
        {/* show only first 25 chars of cmpany */}

        <p className="text-lg font-semibold text-[#00C1FE]">{company?.name?.substring(0, 25)}</p>
        {/* <p className="text-sm font-semibold text-[#19AF1A]">{company?.peopleCount} found in CRM</p> */}
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <div
            style={{
              background: '#EBF7FA',
              width: '150px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '3px',
              borderRadius: '5px',
              marginTop: '5px',
            }}
          >
            <Icon icon="mdi:linkedin" style={{ fontSize: '18px', color: '#00C1FE' }} />
            {company?.staff_count && <p className="text-xs pl-1 "> LinkedIn {company?.staff_count}</p>}
          </div>
          <div
            style={{
              background: '#EBF7FA',
              width: '150px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '3px',
              borderRadius: '5px',
              marginTop: '5px',
              marginLeft: '5px',
            }}
          >
            <Icon icon="material-symbols:database" style={{ fontSize: '16px', color: '#00C1FE' }} />
            <p className="text-xs pl-1 "> CRM {company?.peopleCount}</p>
          </div>
        </Box>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <div
            style={{
              background: '#EBF7FA',
              width: '150px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '3px',
              borderRadius: '5px',
              marginTop: '5px',
            }}
          >
            <Icon icon="solar:suitcase-lines-bold" style={{ fontSize: '12px', color: '#00C1FE' }} />
            <p className="text-xs pl-2 "> Jobs {company?.jobPostsCount}</p>
          </div>
          <div
            style={{
              background: '#EBF7FA',
              width: '150px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '3px',
              borderRadius: '5px',
              marginTop: '5px',
              marginLeft: '5px',
            }}
          >
            <Icon icon="mdi:company" style={{ fontSize: '16px', color: '#00C1FE' }} />
            <p className="text-xs pl-1 ">
              {' '}
              Company Size {company?.staff_count_range_start + '-' + company?.staff_count_range_end}
            </p>
          </div>
        </Box>
      </div>
    </div>
  );
}
