// natasha laptop url
// const api_url = 'https://c63d-182-176-99-238.ngrok-free.app/';
// shakir laptop url
// const api_url = 'https://tjnwt9lr-5001.inc1.devtunnels.ms/';
// server url
const api_url = 'https://btds28q8-5001.inc1.devtunnels.ms/';

// const api_url = "https://btds28q8-5002.inc1.devtunnels.ms/";
// const api_url = "http://localhost:5001/";

export const API_URLS = {
  //users routes
  uploadImage: `${api_url}api/users/uploadImage`,
  createUser: `${api_url}api/users/createUser`,
  login: `${api_url}api/users/login`,
  updateUser: `${api_url}api/users/updateUser`,
  getRoleBasedUsers: `${api_url}api/users/getRoleBasedUsers`,
  getAllUsers: `${api_url}api/users/getAllUsers`,
  deactivateAccount: `${api_url}api/users/deactivateAccount`,
  approveAccount: `${api_url}api/users/approveAccount`,
  logout: `${api_url}api/users/logout`,
  getUserById: `${api_url}api/users/getUserById`,

  // features routes
  findAllFeatures: `${api_url}api/features/findAllFeatures`,
  createFeature: `${api_url}api/features/createFeature`,
  updateUserAndGiveFeatureAcess: `${api_url}api/features/updateUserAndGiveFeatureAcess`,
  //company routes
  addCompany: `${api_url}api/companies/addCompany`,
  getAllCompanies: `${api_url}api/companies/getAllCompanies`,
  searchGlobally: `${api_url}api/companies/searchGlobally`,
  update: `${api_url}api/companies/update`,
  searchCompanies: `${api_url}api/companies/searchCompanies`,
  searchCompanisByFilters: `${api_url}api/companies/searchCompanisByFilters`,
  getCompanies: `${api_url}api/companies/getCompanies`,
  getUniqueIndustriesFromCompanies: `${api_url}api/companies/getUniqueIndustriesFromCompanies`,

  //person routes
  addPerson: `${api_url}api/persons/addPerson`,
  getAllPersons: `${api_url}api/persons/getAllPersons`,
  getPersonByCompanyId: `${api_url}api/persons/getPersonByCompanyId`,
  updatePerson: `${api_url}api/persons/updatePerson`,
  getCompanyOfPerson: `${api_url}api/persons/getCompanyOfPerson`,
  assignPersonsToUser: `${api_url}api/persons/assignPersonsToUser`,
  getAssignedPersonsByUserId: `${api_url}api/persons/getAssignedPersonsByUserId`,
  updatePersonInfo: `${api_url}api/persons/updatePersonInfo`,
  getUniqueIndustriesFromPersons: `${api_url}api/persons/getUniqueIndustriesFromPersons`,
  getUniquePersonTitles: `${api_url}api/persons/getUniquePersonTitles`,
  searchPersons: `${api_url}api/persons/searchPersons`,
  searchPersonsOfCompany: `${api_url}api/persons/searchPersonsOfCompany`,
  downloadPersons: `${api_url}api/persons/downloadPersons`,

  //jobpost routes
  addJobPostWithCompanyId: `${api_url}api/jobposts/addJobPostWithCompanyId`,
  addJobPostWithPersonId: `${api_url}api/jobposts/addJobPostWithPersonId`,
  getJobPostsByPersonId: `${api_url}api/jobposts/getJobPostsByPersonId`,
  getPersonOfJobPost: `${api_url}api/jobposts/getPersonOfJobPost`,
  checkDuplicateByPostingLink: `${api_url}api/jobposts/checkDuplicateByPostingLink`,
  // getUniqueIndustries: `${api_url}api/jobposts/getUniqueIndustries`,
  getCompanyOfJobpost: `${api_url}api/jobposts/getCompanyOfJobpost`,
  getJobPostsByCompanyId: `${api_url}api/jobposts/getJobPostsByCompanyId`,
  getAllJobs: `${api_url}api/jobposts/getAllJobs`,
  searchJobPosts: `${api_url}api/jobposts/searchJobPosts`,
  searchJobPostsOfCompany: `${api_url}api/jobposts/searchJobPostsOfCompany`,

  // countries routes
  getAllCountries: `${api_url}api/countries/getAllCountries`,
  getCountriesAndSector: `${api_url}api/countries/getCountriesAndSector`,

  //sectors routes
  getAllSectors: `${api_url}api/sectors/getAllSectors`,

  // scrapper routes
  addCompanyByScrapper: `${api_url}api/scrapper/addCompanyByScrapper`,
  addPersonByScrapper: `${api_url}api/scrapper/addPersonByScrapper`,
  addJobPostByScrapper: `${api_url}api/scrapper/addJobPostByScrapper`,
  getCompanyAndPersonId: `${api_url}api/scrapper/getCompanyAndPersonId`,

  // schedules routes
  createSchedule: `${api_url}api/schedules/createSchedule`,
  updateSchedule: `${api_url}api/schedules/updateSchedule`,
  deleteSchedule: `${api_url}api/schedules/deleteSchedule`,
  getSchedulesByCountry: `${api_url}api/schedules/getSchedulesByCountry`,
  // sequence routes
  createSequence: `${api_url}api/sequences/createSequence`,

  // sequence steps routes
  createSequenceStep: `${api_url}api/sequenceSteps/createSequenceStep`,
  updateSequenceStep: `${api_url}api/sequenceSteps/updateSequenceStep`,

  // templates routes
  createTemplate: `${api_url}api/templates/createTemplate`,
  updateTemplate: `${api_url}api/templates/updateTemplate`,
  getTemplates: `${api_url}api/templates/getTemplates`,
  updateTemplateDisclaimer: `${api_url}api/templates/updateTemplateDisclaimer`,

  // campaign  routes
  createCampaign: `${api_url}api/campaigns/createCampaign`,
  findAll: `${api_url}api/campaigns/findAll`,
  checkDuplicateByName: `${api_url}api/campaigns/checkDuplicateByName`,
  getCounterByCountryAndSector: `${api_url}api/campaigns/getCounterByCountryAndSector`,
  getJobPostsAndSendingStats: `${api_url}api/campaigns/getJobPostsAndSendingStats`,
  getEmailEventStats: `${api_url}api/campaigns/getEmailEventStats`,

  // emails routes
  addPersonEmails: `${api_url}api/emails/addPersonEmails`,
  unSubscribeFromOurServices: `${api_url}api/emails/unSubscribeFromOurServices`,
  searchEmail: `${api_url}api/emails/searchEmail`,
  addPersonalEmails: `${api_url}api/emails/addPersonalEmails`,
  addBusinessEmails: `${api_url}api/emails/addBusinessEmails`,

  // phone routes
  addPersonPhones: `${api_url}api/phones/addPersonPhones`,

  // CAMPAIGN REPORTING ROUTES
  getOverAllStats: `${api_url}api/campaign-reporting/getOverAllStats`,
  getRecentBouncedEmails: `${api_url}api/campaign-reporting/getRecentBouncedEmails`,
  getRecentOpenedEmails: `${api_url}api/campaign-reporting/getRecentOpenedEmails`,
  getRecentSpamEmails: `${api_url}api/campaign-reporting/getRecentSpamEmails`,
  getCampaignReportStats: `${api_url}api/campaign-reporting/getCampaignReportStats`,
  getRecentUnSubscribedEmails: `${api_url}api/campaign-reporting/getRecentUnSubscribedEmails`,
  getEmailBasedReports: `${api_url}api/campaign-reporting/getEmailBasedReports`,
  getCampaignRecipents: `${api_url}api/campaign-reporting/getCampaignRecipents`,
  getCampaignRecipentsStatsForLineGraph: `${api_url}api/campaign-reporting/getCampaignRecipentsStatsForLineGraph`,

  // supression list routes
  addSupressionList: `${api_url}api/campaigns/supressions/addSupressionList`,
  getSupressionList: `${api_url}api/campaigns/supressions/getSupressionList`,
  addSingleEmail: `${api_url}api/campaigns/supressions/addSingleEmail`,
  //blocked domains routes
  addDomainList: `${api_url}api/campaigns/domains/addDomainList`,
  getBlockedDomains: `${api_url}api/campaigns/domains/getBlockedDomains`,
  addSingleDomain: `${api_url}api/campaigns/domains/addSingleDomain`,

  //candidates routes
  createCandidate: `${api_url}api/candidates/createCandidate`,
  getAllCandidates: `${api_url}api/candidate/getAllCandidates`,
  getMatchingCandidates: `${api_url}api/candidate/getMatchingCandidates`,

  // email record routes
  markAsEmailNotFound: `${api_url}api/email_records/markAsEmailNotFound`,
  updateEmailStatus: `${api_url}api/email_records/updateEmailStatus`,
  updateThePersonEmailsStatusInBulk: `${api_url}api/email_records/updateThePersonEmailsStatusInBulk`,
  markAsReplacementNotFound: `${api_url}api/email_records/markAsReplacementNotFound`,
  addReplacementEmails: `${api_url}api/email_records/addReplacementEmails`,
  addEmailsFromSpreadSheet: `${api_url}api/email_records/addEmailsFromSpreadSheet`,

  // Stats
  getCountryAndSectorWiseLeadsStats: `${api_url}api/stats/getCountryAndSectorWiseLeadsStats`,
  getPersonWiseLeadsStats: `${api_url}api/stats/getPersonWiseLeadsStats`,
  getOverallAndUserBasedStats: `${api_url}api/stats/getOverallAndUserBasedStats`,
  userSystemReport: `${api_url}api/stats/userSystemReport`,
  getCountryAndSectorWiseLeadsContactStats: `${api_url}api/stats/getCountryAndSectorWiseLeadsContactStats`,
  getCountryAndSectorWisePersons: `${api_url}api/stats/getCountryAndSectorWisePersons`,
  getUserWorkReport: `${api_url}api/stats/getUserWorkReport`,
  getScrapperReporting: `${api_url}api/stats/getScrapperReporting`,
  getEmailCampaignReporting: `${api_url}api/stats/getEmailCampaignReporting`,
  getTeamAssignedPersonsStats: `${api_url}api/stats/getTeamAssignedPersonsStats`,
};

// Executing (default): SELECT "id", "job_title", "job_posting_link", "job_post_date" FROM "job_posts" AS "job_posts" WHERE "job_posts"."company_id" = 764 ORDER BY "job_posts"."createdAt" DESC LIMIT 1;
