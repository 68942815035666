import React, { useCallback, useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
  Popover,
  MenuItem,
  Autocomplete,
  TextField,
} from '@mui/material';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';

export const FilterDialog = ({ open, onClose, onApply }) => {
  const [selectedFilters, setSelectedFilters] = useState({
    country: null,
    countries: [],
    sector: null,
    sectors: [],
  });
  const [countries, setCountries] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedSector, setSelectedSector] = useState(null);
  const [searchType, setSearchType] = useState('Companies');
  const searchOptions = ['Job Posts', 'Companies', 'Persons'];

  console.log('fdsfdsf', selectedFilters);
  const loadCountriesAndSector = useCallback(() => {
    try {
      Get(
        {},
        API_URLS.getCountriesAndSector,
        (response) => {
          setCountries(response?.data?.countries);
          setSectors(response?.data?.sectors);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    loadCountriesAndSector();
  }, [loadCountriesAndSector]);

  const handleChangeCountry = (countryId) => {
    if (selectedCountry === countryId) {
      setSelectedCountry(null);
      setSelectedFilters((prev) => ({ ...prev, country: null }));
    } else {
      setSelectedCountry(countryId);
      const country = countries.find((c) => c.id === countryId);
      setSelectedFilters((prev) => ({ ...prev, country: country }));
    }
  };
  
  const handleChangeSector = (sectorId) => {
    if (selectedSector === sectorId) {
      setSelectedSector(null);
      setSelectedFilters((prev) => ({ ...prev, sector: null }));
    } else {
      setSelectedSector(sectorId);
      const sector = sectors.find((s) => s.id === sectorId);
      setSelectedFilters((prev) => ({ ...prev, sector: sector }));
    }
  };
  const handleApply = () => {
    onApply(selectedFilters);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: '40%',
          maxWidth: 'none',
          margin: 0,
          position: 'absolute',
          right: 0,
          height: '100%',
        },
      }}
    >
      <DialogTitle>
        Filters
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* Date Posted */}
        {/* <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Button
            variant="contained"
            sx={{
              background: '#00C1FE',
              color: '#fff',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: 'transparent',
                color: '#00C1FE',
              },
              borderRadius: '5px',
              padding: '5px',
              margin: '5px',
              width: '100px',
            }}
            onClick={(e) => {
              setSearchType('Job Posts');
              setAnchorEl(e.currentTarget);
            }}
          >
            {searchType}
          </Button>
        </Box>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem
            sx={{
              background: 'transparent',
              color: '#00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: '#fff',
              },
            }}
            onClick={() => {
              setSearchType('Companies');
            }}
          >
            {searchType}
          </MenuItem>
          <MenuItem
            sx={{
              background: 'transparent',
              color: '#00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: '#fff',
              },
            }}
            onClick={() => {
              setSearchType('Persons');
            }}
          >
            {searchType}
          </MenuItem>
        </Popover> */}
        <br />
        <Box display="flex" justifyContent="flex-end" sx={{ marginRight: '10px' }}>
          <Autocomplete
            id="role"
            fullWidth
            size="small"
            sx={{ width: '150px' }}
            options={searchOptions}
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              setSearchType(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Search by" variant="outlined" />}
          />
        </Box>

        <Box display="flex" flexDirection="column">
          <Typography variant="h6">Date Posted</Typography>
          <Box mx={4}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters['Date Posted']?.['all'] || false}
                    // onChange={() => handleCheckboxChange('Date Posted', 'all')}
                  />
                }
                label="Any time"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters['Date Posted']?.['last7'] || false}
                    // onChange={() => handleCheckboxChange('Date Posted', 'last7')}
                  />
                }
                label="Past 7 days"
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters['Date Posted']?.['last24'] || false}
                    // onChange={() => handleCheckboxChange('Date Posted', 'last24')}
                  />
                }
                label="Past 24 hours"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters['Date Posted']?.['last30'] || false}
                    // onChange={() => handleCheckboxChange('Date Posted', 'last30')}
                  />
                }
                label="Past 30 days"
              />
            </div>
          </Box>
        </Box>
        <br />
        <hr />
        <br />
        {/* Experience level */}
        <Box display="flex" flexDirection="column">
          <Typography variant="h6">Experience level</Typography>
          <Box mx={4}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters['Experience level']?.['Internship'] || false}
                    // onChange={() => handleCheckboxChange('Experience level', 'Internship')}
                  />
                }
                label="Internship"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters['Experience level']?.['Entry Level'] || false}
                    // onChange={() => handleCheckboxChange('Experience level', 'Entry Level')}
                  />
                }
                label="Entry Level"
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters['Experience level']?.['Associate'] || false}
                    // onChange={() => handleCheckboxChange('Experience level', 'Associate')}
                  />
                }
                label="Associate"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters['Experience level']?.['Mid-Senior level'] || false}
                    // onChange={() => handleCheckboxChange('Experience level', 'Mid-Senior level')}
                  />
                }
                label="Mid-Senior level"
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters['Experience level']?.['Director'] || false}
                    // onChange={() => handleCheckboxChange('Experience level', 'Director')}
                  />
                }
                label="Director"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters['Experience level']?.['Executive'] || false}
                    // onChange={() => handleCheckboxChange('Experience level', 'Executive')}
                  />
                }
                label="Executive"
              />
            </div>
          </Box>
        </Box>
        <br />
        <hr />
        <br />
        {/* Job Type */}
        <Box display="flex" flexDirection="column">
          <Typography variant="h6">Job Type</Typography>
          <Box mx={4}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters['Job Type']?.['Full-time'] || false}
                    // onChange={() => handleCheckboxChange('Job Type', 'Full-time')}
                  />
                }
                label="Full-time"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters['Job Type']?.['Part-time'] || false}
                    // onChange={() => handleCheckboxChange('Job Type', 'Part-time')}
                  />
                }
                label="Part-time"
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters['Job Type']?.['Contract'] || false}
                    // onChange={() => handleCheckboxChange('Job Type', 'Contract')}
                  />
                }
                label="Contract"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters['Job Type']?.['Temporary'] || false}
                    // onChange={() => handleCheckboxChange('Job Type', 'Temporary')}
                  />
                }
                label="Temporary"
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters['Job Type']?.['Volunteer'] || false}
                    // onChange={() => handleCheckboxChange('Job Type', 'Volunteer')}
                  />
                }
                label="Volunteer"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters['Job Type']?.['Internship'] || false}
                    // onChange={() => handleCheckboxChange('Job Type', 'Internship')}
                  />
                }
                label="Internship"
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters['Job Type']?.['Other'] || false}
                    // onChange={() => handleCheckboxChange('Job Type', 'Other')}
                  />
                }
                label="Other"
              />
            </div>
          </Box>
        </Box>
        <br />
        <hr />
        <br />
        {/* Remote */}
        <Box display="flex" flexDirection="column">
          <Typography variant="h6">Remote</Typography>
          <Box mx={4}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters['Remote']?.['On-site'] || false}
                    // onChange={() => handleCheckboxChange('Remote', 'On-site')}
                  />
                }
                label="On-site"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters['Remote']?.['Remote'] || false}
                    // onChange={() => handleCheckboxChange('Remote', 'Remote')}
                  />
                }
                label="Remote"
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters['Remote']?.['Hybrid'] || false}
                    // onChange={() => handleCheckboxChange('Remote', 'Hybrid')}
                  />
                }
                label="Hybrid"
              />
            </div>
          </Box>
        </Box>
        <br />
        <hr />
        <br />
        <Box display="flex" flexDirection="column">
          <Typography variant="h6">Industry</Typography>
          <Box mx={4} display="flex" flexDirection="column">
            {sectors?.map(
              (sector, index) =>
                index % 2 === 0 && ( // Check if index is even
                  <div
                    key={sector.id}
                    style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedSector === sector.id}
                          onChange={() => handleChangeSector(sector.id)}
                        />
                      }
                      label={sector?.sector}
                    />
                    {
                      // Render the second sector in the row if available
                      sectors[index + 1] && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedSector === sectors[index + 1].id}
                              onChange={() => handleChangeSector(sectors[index + 1].id)}
                            />
                          }
                          label={sectors[index + 1]?.sector}
                        />
                      )
                    }
                  </div>
                )
            )}
          </Box>
        </Box>
        <br />
        <hr />
        <br />
        <Box display="flex" flexDirection="column">
          <Typography variant="h6">Country</Typography>
          <Box mx={4} display="flex" flexDirection="column">
            {countries?.map(
              (country, index) =>
                index % 2 === 0 && ( // Check if index is even
                  <div
                    key={country.id}
                    style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedCountry === country.id}
                          onChange={() => handleChangeCountry(country.id)}
                        />
                      }
                      label={country?.country}
                    />
                    {
                      // Render the second country in the row if available
                      countries[index + 1] && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedCountry === countries[index + 1].id}
                              onChange={() => handleChangeCountry(countries[index + 1].id)}
                            />
                          }
                          label={countries[index + 1]?.country}
                        />
                      )
                    }
                  </div>
                )
            )}
          </Box>
        </Box>
      </DialogContent>
      <Box display="flex" justifyContent="center" my={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleApply(selectedFilters);
            onClose();
          }}
        >
          Apply Filters
        </Button>
      </Box>
    </Dialog>
  );
};
