import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Button, CircularProgress } from '@mui/material';
// hooks
// components
import Logo from 'src/components/logo/Logo';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Post } from 'src/actions/API/apiActions';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function VerifiedAccountPage() {
  const [isVerified, setIsVerified] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { verificationtoken } = useParams();
  useEffect(() => {
    Post(
      {
        verificationtoken: verificationtoken,
      },
      (res) => {
        setIsVerified(false);
        enqueueSnackbar('Your email is verified succesfully. Continue to login your account.', { variant: 'success' });
      },
      (err) => {
        setIsVerified(false);
        setRedirect(true);
        enqueueSnackbar(
          'Either Email is not verified or the TOKEN is invalid. Contact with your admin to resolve the issue',
          { variant: 'error' }
        );
      }
    );
  }, [enqueueSnackbar, verificationtoken]);
  return (
    <>
      <Helmet>
        <title> Verification Status | CRM </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Container maxWidth="sm">
          {!isVerified ? (
            <StyledContent>
              <Typography variant="h4" gutterBottom>
                {redirect
                  ? 'Redirecting to login page...'
                  : 'Your email is verified succesfully. Continue to login your account.'}
              </Typography>
              {redirect ? (
                setTimeout(() => {
                  navigate('/login');
                }, 3000)
              ) : (
                <Button color="primary" variant="outlined" onClick={() => navigate('/login')}>
                  Continue to login
                </Button>
              )}
            </StyledContent>
          ) : (
            <StyledContent>
              Verifying You email <CircularProgress />
            </StyledContent>
          )}
        </Container>
      </StyledRoot>
    </>
  );
}
