import { Typography } from '@mui/material';
import React from 'react';

function EmailResearcherDashboardPage(props) {
  return (
    <div>
      <Typography variant="h3" sx={{ marginLeft: '10px' }}>
        Hello , Welcome Back!
      </Typography>
    </div>
  );
}

export default EmailResearcherDashboardPage;
