import { Grid, TextField, Button, FormControlLabel, Checkbox, Switch, Box } from '@mui/material';
import React, { useState, useEffect } from 'react';

function CreateSchedule({ onSubmit, existingSchedule }) {
  console.log("dsadhfewhrkewrjuriewuriw3",existingSchedule);
  const [days, setDays] = useState([
    { day: 'Monday', checked: false, startTime: '', endTime: '', switchOn: false, pauseTime: '', resumeTime: '' },
    { day: 'Tuesday', checked: false, startTime: '', endTime: '', switchOn: false, pauseTime: '', resumeTime: '' },
    { day: 'Wednesday', checked: false, startTime: '', endTime: '', switchOn: false, pauseTime: '', resumeTime: '' },
    { day: 'Thursday', checked: false, startTime: '', endTime: '', switchOn: false, pauseTime: '', resumeTime: '' },
    { day: 'Friday', checked: false, startTime: '', endTime: '', switchOn: false, pauseTime: '', resumeTime: '' },
    { day: 'Saturday', checked: false, startTime: '', endTime: '', switchOn: false, pauseTime: '', resumeTime: '' },
    { day: 'Sunday', checked: false, startTime: '', endTime: '', switchOn: false, pauseTime: '', resumeTime: '' },
  ]);

  useEffect(() => {
    if (existingSchedule) {
      const newDays = days.map(day => {
        const schedule = existingSchedule[day.day];
        if (schedule) {
          return {
            ...day,
            checked: true,
            startTime: schedule[0].start_time || '',
            endTime: schedule[0].end_time || '',
            switchOn: Boolean(schedule[0].pause_time || schedule[0].resume_time),
            pauseTime: schedule[0].pause_time || '',
            resumeTime: schedule[0].resume_time || '',
          };
        }
        return day;
      });

      setDays(newDays);
    }
  }, [existingSchedule]);

  const handleChange = (index, field, value) => {
    const newDays = [...days];
    newDays[index] = { ...newDays[index], [field]: value };
    setDays(newDays);
  };

  const handleCheckboxChange = (index, value) => {
    const newDays = [...days];
    newDays[index] = { ...newDays[index], checked: value };
    setDays(newDays);
  };

  const handleSwitchChange = (index, value) => {
    const newDays = [...days];
    newDays[index] = { ...newDays[index], switchOn: value };
    setDays(newDays);
  };

  const handleAddClick = () => {
    onSubmit(days);
  };

  return (
    <div>
      <br />
      {days.map((day, index) => (
        <Grid container spacing={2} alignItems="center" key={index} marginBottom="15px" marginLeft="20px">
          <Grid item xs={12} md={2} lg={2}>
            <FormControlLabel
              control={
                <Checkbox checked={day.checked} onChange={(e) => handleCheckboxChange(index, e.target.checked)} />
              }
              label={day.day}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              label="Start Time"
              type="time"
              fullWidth
              value={day.startTime}
              onChange={(e) => handleChange(index, 'startTime', e.target.value)}
              InputLabelProps={{ shrink: true }}
              sx={{
                borderRadius: '0.5rem',
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#00C1FE',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#00C1FE',
                  },
                },
                '& .MuiInputBase-root': {
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 1px #00C1FE',
                  },
                },
              }}
            />
            {day.switchOn && (
              <TextField
                label="Pause Time"
                type="time"
                fullWidth
                value={day.pauseTime}
                onChange={(e) => handleChange(index, 'pauseTime', e.target.value)}
                InputLabelProps={{ shrink: true }}
                sx={{
                  marginTop: '15px',
                  borderRadius: '0.5rem',
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#00C1FE',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#00C1FE',
                    },
                  },
                  '& .MuiInputBase-root': {
                    '&.Mui-focused': {
                      boxShadow: '0 0 0 1px #00C1FE',
                    },
                  },
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              label="End Time"
              type="time"
              fullWidth
              value={day.endTime}
              onChange={(e) => handleChange(index, 'endTime', e.target.value)}
              InputLabelProps={{ shrink: true }}
              sx={{
                borderRadius: '0.5rem',
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#00C1FE',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#00C1FE',
                  },
                },
                '& .MuiInputBase-root': {
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 1px #00C1FE',
                  },
                },
              }}
            />
            {day.switchOn && (
              <TextField
                label="Resume Time"
                type="time"
                fullWidth
                value={day.resumeTime}
                onChange={(e) => handleChange(index, 'resumeTime', e.target.value)}
                InputLabelProps={{ shrink: true }}
                sx={{
                  marginTop: '15px',
                  borderRadius: '0.5rem',
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#00C1FE',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#00C1FE',
                    },
                  },
                  '& .MuiInputBase-root': {
                    '&.Mui-focused': {
                      boxShadow: '0 0 0 1px #00C1FE',
                    },
                  },
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} md={1} lg={1}>
            <FormControlLabel
              control={<Switch checked={day.switchOn} onChange={(e) => handleSwitchChange(index, e.target.checked)} />}
              label="Active"
            />
          </Grid>
        </Grid>
      ))}
      <Box display="flex" justifyContent="center">
        <Button variant="contained" color="primary" onClick={handleAddClick}>
          {existingSchedule ? 'Update' : 'Add'}
        </Button>
      </Box>
    </div>
  );
}

export default CreateSchedule;
