import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Button } from '@mui/material';
// hooks
// components
import Logo from 'src/components/logo/Logo';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function CheckEmailForVerification() {
  return (
    <>
      <Helmet>
        <title> Verify You Email | CRM </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h6" gutterBottom>
              Check You email for Verification.
            </Typography>
            <Button
              href="/login"
              color="primary"
              variant="outlined"
            >
              Continue to Login
            </Button>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
