import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { AddNewRole, ArrowPipeline, CustomizedRolesComponent, ReactMultiDatePicker, RolesComponent } from '../components';
import { Icon } from '@iconify/react';

function Roles(props) {
  const [showNext, setShowNext] = useState(false);
  const [formattedDates, setFormattedDates] = useState([null, null]);
  const [openAddRoleDialog, setOpenAddRoleDialog] = useState(false);
  return (
    <div>
      <Box display="flex" marginLeft="10px">
        <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
          Manage Roles
        </Typography>
      </Box>
      <br />
      {showNext && (
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          width="95%"
          margin="auto"
          marginBottom="5px"
        >
          <Icon
            icon="ic:round-arrow-back"
            style={{ fontSize: '25px', color: '#00C1FE', cursor: 'pointer' }}
            onClick={() => setShowNext(false)}
          />
          <Typography variant="subtitle1" sx={{ marginLeft: '10px', color: '#00C1FE' }}>
            Back to total
          </Typography>
        </Box>
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: '1rem', backgroundColor: 'white', width: '95%', margin: 'auto' }}
      >
        <ArrowPipeline
          title="Total"
          total={100}
          left={20}
          center={50}
          right={30}
          color="#E496FF"
          onLeftClick={() => {}}
          onCenterClick={() => {}}
          onRightClick={() => {}}
          leftTooltip="Pending"
          centerTooltip="In progress"
          rightTooltip="Done"
        />
        {showNext ? (
          <>
            <ArrowPipeline
              title="CV Sourcing"
              total={150}
              left={40}
              center={60}
              right={50}
              color="#F85ED6"
              onPipeLineClick={() => setShowNext(true)}
              onLeftClick={() => {}}
              onCenterClick={() => {}}
              onRightClick={() => {}}
              leftTooltip="Pending"
              centerTooltip="In progress"
              rightTooltip="Done"
            />
            <ArrowPipeline
              title="Pre Qualification"
              total={150}
              left={40}
              center={60}
              right={50}
              color="#8C85FB"
              onLeftClick={() => {}}
              onCenterClick={() => {}}
              onRightClick={() => {}}
              leftTooltip="Pending"
              centerTooltip="In progress"
              rightTooltip="Done"
            />
            <ArrowPipeline
              title="360/Direct"
              total={150}
              left={40}
              center={60}
              right={50}
              color="#00C1FE"
              onLeftClick={() => {}}
              onCenterClick={() => {}}
              onRightClick={() => {}}
              leftTooltip="Pending"
              centerTooltip="In progress"
              rightTooltip="Done"
            />
          </>
        ) : (
          <>
            <ArrowPipeline
              title="Trial"
              total={150}
              left={40}
              center={60}
              right={50}
              color="#F85ED6"
              onPipeLineClick={() => setShowNext(true)}
              onLeftClick={() => {}}
              onCenterClick={() => {}}
              onRightClick={() => {}}
              leftTooltip="Pending"
              centerTooltip="In progress"
              rightTooltip="Done"
            />
            <ArrowPipeline
              title="Regular"
              total={150}
              left={40}
              center={60}
              right={50}
              color="#8C85FB"
              onLeftClick={() => {}}
              onCenterClick={() => {}}
              onRightClick={() => {}}
              leftTooltip="Pending"
              centerTooltip="In progress"
              rightTooltip="Done"
            />
            <ArrowPipeline
              title="Fix"
              total={150}
              left={40}
              center={60}
              right={50}
              color="#00C1FE"
              onLeftClick={() => {}}
              onCenterClick={() => {}}
              onRightClick={() => {}}
              leftTooltip="Pending"
              centerTooltip="In progress"
              rightTooltip="Done"
            />
          </>
        )}
      </Box>
      <br />
      <Box display="flex" justifyContent="flex-end" sx={{ marginBottom: '1rem', width: '95%', margin: 'auto' }}>
        <ReactMultiDatePicker
          onChange={(dateRange) => {
            setFormattedDates(dateRange);
          }}
          style={{
            width: '240px',
            height: '50px',
            paddingRight: '5px',
            marginRight: '5px',
            border: '2px solid #00C1FE',
            outline: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '10px',
            boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.2) !important',
            backgroundColor: '#ECF6FE',
            color: '#00C1FE',
            boxSizing: 'border-box',
            paddingLeft: '10px',
          }}
        />
        <Button
          variant="outlined"
          startIcon={<Icon icon="material-symbols:add" />}
          sx={{
            fontSize: '18px',
            borderRadius: '10px',
            borderWidth: '2px',
            color: '#0ec4fe',
            height: '50px',
            borderColor: '#0ec4fe',
            marginLeft: '10px',

            '&:hover': {
              color: '#0ec4fe !important',
              borderColor: '#0ec4fe !important',
              backgroundColor: 'transparent !important',
              border: '2px solid #0ec4fe !important',
            },
          }}
          size="small"
          onClick={() => setOpenAddRoleDialog(true)}
        >
          Add New Role
        </Button>
      </Box>
      <br />
      <RolesComponent />
      <br />
      <div style={{ width: '95%', margin: 'auto' }}>
      <CustomizedRolesComponent />
      </div>
      {openAddRoleDialog && <AddNewRole open={openAddRoleDialog} onClose={() => setOpenAddRoleDialog(false)} />}
    </div>
  );
}

export default Roles;
