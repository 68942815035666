import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Card, CardContent, CardHeader, Grid, MenuItem, Select, Typography } from '@mui/material';
import React, { useState } from 'react';
import QuillEditor from './QuilEditor';
import { useNavigate } from 'react-router-dom';
import { CVsTable, InfoBox, IssueBox, LITable, QueryBox, SuggestionBox } from '.';

function RolesProcessing(props) {
    const navigate = useNavigate();
    const roleOptions = [
      {
        id: 'markdone',
        label: 'Mark Done',
      },
      {
        id: 'leavrole',
        label: 'Leave Role',
      },
      {
        id: 'timeout',
        label: 'Time Out',
      },
    ];

      // Initialize state with the first option's value
  const [selectedRole, setSelectedRole] = useState(roleOptions[0]?.id);

  const handleOptionChange = (event) => {
    setSelectedRole(event.target.value);
  };

    return (
      <div style={{ margin: '1rem' }}>
        <Box display="flex" justifyContent="space-between" alignItems="space-between">
          <Button
            variant="none"
            sx={{
              color: '#00C1FE',
            }}
            onClick={() => {
              navigate('/acm/roles');
            }}
          >
            <ArrowBack fontSize="medium" />
            &nbsp;&nbsp; <Typography variant="h6">Back to Roles</Typography>
          </Button>
        </Box>
        <Box mt={2} />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '125vh',
                borderRadius: '15px',
                //give box sahdow
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
                sx={{
                  backgroundColor: '#E1F7FC',
                  color: '#00C1FE',
                  padding: '10px',
                }}
              >
                <Typography variant="h6" sx={{ padding: '10px' }}>
                  Role Details
                </Typography>
                {/* {userRole === 'resourcer' && ( */}
                <Select
                  value={selectedRole}
                  onChange={handleOptionChange}
                  sx={{
                    width: '140px',

                    '.MuiSelect-select': {
                      color: '#FFFFFF',
                      padding: '10px 14px',
                      height: '10px',
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#00C1FE', // Default border color
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#00C1FE', // Hover border color
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#00C1FE', // Focus border color (blue in this case)
                    },
                    '.MuiSelect-select.MuiSelect-outlined': {
                      backgroundColor: '#00C1FE',
                      border: '2px solid #00C1FE',
                    },
                    '.MuiSelect-icon': {
                      color: '#FFFFFF',
                    },
                  }}
                >
                  {roleOptions?.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <CardContent>
                <div
                  style={{
                    // make content scrollable
                    overflowY: 'scroll',
                    height: '99vh',
                  }}
                  //   dangerouslySetInnerHTML={{ __html: role?.docFileTemplate }}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4} display="flex" flexDirection="column" justifyContent="space-between">
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '60%',
                borderRadius: '15px',
                //give box sahdow
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
                sx={{
                  backgroundColor: '#E1F7FC',
                  color: '#00C1FE',
                  padding: '8px',
                }}
              >
                <Typography variant="h6" sx={{ padding: '10px' }}>
                  Query String
                </Typography>
                {/* {userRole === 'resourcer' && ( */}
                <Button
                  sx={{
                    background: '#00C1FE',
                    color: '#fff',
                    width: '140px',
                    height: '45px',
                    border: '1px solid #00C1FE',
                    '&:hover': {
                      background: '#00C1FE',
                      color: 'white',
                    },
                    borderRadius: '5px',
                    padding: '5px',
                    margin: '5px',
                  }}
                  // onClick={() => {
                  //   handleStartRole();
                  // }}
                  // disabled={role?.roleStatus !== 'pending'}
                >
                  {/* {role?.roleStatus === 'pending' ? 'Start Role' : role?.roleStatus} */}
                  Start role
                </Button>
              </Box>
              <QuillEditor
                htmlValue={''}
                // onTextChange={(html) => handleQueryStringUpdate(html)}
              />
              {/* <HTMLEditor
                htmlText={role?.queryString ? role?.queryString : ''}
                onEditorStateChange={(html) => handleQueryStringUpdate(html)}
              /> */}
            </Card>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '40%',
                marginTop: '10px',
                borderRadius: '15px',
                //give box sahdow
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
              }}
            >
              <IssueBox
                // issueData={issues}
                // onSubmitIssue={handleAddIssue}
                // onUpdateIssue={(index, issue) => handleEditIssueClick(index, issue)}
                issueData={[]}
                onSubmitIssue={() => {}}
                onUpdateIssue={(index, issue) => {}}
              />
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              alignContent="center"
              sx={{
                backgroundColor: '#E1F7FC',
                color: '#00C1FE',
                padding: '12px',
              }}
            >
              <Typography variant="h6" sx={{ padding: '10px' }}>
                Focus Points
              </Typography>
            </Box>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '30%',
                marginTop: '10px',
                borderRadius: '15px',
                //give box sahdow
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
              }}
            >
              <InfoBox
                // issueData={issues}
                // onSubmitIssue={handleAddIssue}
                // onUpdateIssue={(index, issue) => handleEditIssueClick(index, issue)}
                issueData={[]}
                onSubmitIssue={() => {}}
                onUpdateIssue={(index, issue) => {}}
              />
            </Card>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '30%',
                marginTop: '10px',
                borderRadius: '15px',
                //give box sahdow
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
              }}
            >
              <QueryBox
                // issueData={issues}
                // onSubmitIssue={handleAddIssue}
                // onUpdateIssue={(index, issue) => handleEditIssueClick(index, issue)}
                issueData={[]}
                onSubmitIssue={() => {}}
                onUpdateIssue={(index, issue) => {}}
              />
            </Card>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '30%',
                marginTop: '10px',
                borderRadius: '15px',
                //give box sahdow
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
              }}
            >
              <SuggestionBox
                // issueData={issues}
                // onSubmitIssue={handleAddIssue}
                // onUpdateIssue={(index, issue) => handleEditIssueClick(index, issue)}
                issueData={[]}
                onSubmitIssue={() => {}}
                onUpdateIssue={(index, issue) => {}}
              />
            </Card>
          </Grid>
        </Grid>
        <Box mt={2} />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <LITable />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <CVsTable />
          </Grid>
        </Grid>
        {/* <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{
                  backgroundColor: '#E1F7FC',
                }}
              >
                <CardHeader
                  title="Role LIs"
                  sx={{
                    color: '#00C1FE',
                    textAlign: 'start',
                  }}
                />
                {isLIProfilesAdding && <CircularProgress thickness={3} />}
                {userRole === 'resourcer' && (
                  <Button
                    variant="contained"
                    sx={{
                      background: '#00C1FE',
                      color: '#fff',
                      border: '1px solid #00C1FE',
                      '&:hover': {
                        background: 'transparent',
                        color: '#00C1FE',
                      },
                      borderRadius: '5px',
                      padding: '5px',
                      margin: '5px',
                      width: '100px',
                    }}
                    onClick={() => {
                      setLinkedinProfileDialogOpen(true);
                    }}
                  >
                    Add LIs
                  </Button>
                )}
              </Box>
              <CardContent>
                <RoleLIProfileTable roleId={roleId} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
              }}
            >
              <Box
                display="flex"
                sx={{
                  color: '#00C1FE',
                  backgroundColor: '#E1F7FC',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <CardHeader
                  title="Role CVs"
                  sx={{
                    textAlign: 'start',
                  }}
                />
                {userRole === 'resourcer' && (
                  <Button
                    variant="contained"
                    sx={{
                      background: '#00C1FE',
                      color: '#fff',
                      border: '1px solid #00C1FE',
                      '&:hover': {
                        background: 'transparent',
                        color: '#00C1FE',
                      },
                      borderRadius: '5px',
                      padding: '5px',
                      margin: '5px',
                      width: '100px',
                    }}
                    onClick={handleCandidateManagement}
                  >
                    Add CVs
                  </Button>
                )}
              </Box>
              <CardContent>
                <RoleCVTable clientNumber={role?.clientNumber} roleId={roleId} />
                <br />
  
                <h3>Uploaded CVs for this role</h3>
                {roleCVs.map((cv, index) => {
                  return (
                    <div>
                      <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ marginRight: '10px' }}>{cv?.fileName}</p>
                        <IconButton aria-label="download" onClick={() => downloadFile(cv?.fileName)}>
                          <Download />
                        </IconButton>
                  
                      </div>
                    </div>
                  );
                })}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <ExcelReader
          open={linkedinProfileDialogOpen}
          onClose={() => setLinkedinProfileDialogOpen(false)}
          onSubmit={(profilesData) => handleLIProfilesSubmit(profilesData)}
        />
        {openCVPreviewDialog && (
          <CVPreviewDialog
            open={openCVPreviewDialog}
            onClose={() => setOpenCVPreviewDialog(false)}
            file={fileToPrevieq}
          />
        )} */}
      </div>
    );
}

export default RolesProcessing;