import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  Box,
  TextField,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Close } from '@mui/icons-material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Ensure to import the styles for PhoneInput

// Function to validate phone numbers
const isValidPhoneNumber = (phone) => {
  // Basic phone number validation regex
  return /^\+?\d{10,15}$/.test(phone); // Adjust the regex according to your phone number format
};

// Function to format phone numbers
const formatPhoneNumber = (value) => {
  if (!value) return value;
  // Remove all non-digit characters
  const phoneNumber = value.replace(/\D/g, '');
  // Format the phone number
  const formattedPhoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  return formattedPhoneNumber.trim();
};

export default function AddPersonPhoneNumberDialog({ open, onClose,  onSave }) {
  const [personalPhones, setPersonalPhones] = useState(['']);
  const [businessPhones, setBusinessPhones] = useState(['']);
  const [errors, setErrors] = useState({ personalPhones: [], businessPhones: [] });

  // Handle phone number input change and real-time validation
  const handleChange = (setFields, index, value) => {
    setFields((fields) => {
      const newFields = fields.map((field, i) => (i === index ? value : field));
      // Validate the updated field
      const errorsList = newFields.map((phone, i) => !isValidPhoneNumber(phone) && i === index ? 'Invalid phone number format' : null);
      // setErrors((prevErrors) => ({
      //   ...prevErrors,
      //   personalPhones: setFields === setPersonalPhones ? errorsList : prevErrors.personalPhones,
      //   businessPhones: setFields === setBusinessPhones ? errorsList : prevErrors.businessPhones,
      // }));
      return newFields;
    });
  };

  // Handle adding a new phone field
  const handleAddField = (setFields) => {
    setFields((fields) => [...fields, '']);
  };

  // Handle form submission
  const handleSave = () => {
    // Validate all phone numbers
    const personalErrors =
      personalPhones?.length > 0 &&
      personalPhones.map((phone) => (!isValidPhoneNumber(phone) ? 'Invalid phone number format' : null));
    const businessErrors =
      businessPhones?.length > 0 &&
      businessPhones.map((phone) => (!isValidPhoneNumber(phone) ? 'Invalid phone number format' : null));

    // if (personalErrors.some(error => error) || businessErrors.some(error => error)) {
    //   setErrors({ personalPhones: personalErrors, businessPhones: businessErrors });
    //   return; // Prevent save if there are validation errors
    // }

    const phones = { personalPhones: personalPhones.map(formatPhoneNumber), businessPhones: businessPhones.map(formatPhoneNumber) };
    onSave( phones);
  };

  console.log('personalPhones', personalPhones , businessPhones);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '40%',
          maxWidth: 'none',
          height: '70%',
        },
      }}
      aria-describedby="alert-dialog-slide-description"
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4">
            Add Phone Numbers
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {personalPhones.map((phone, index) => (
          <div key={index} className="flex items-center mb-2">
            <Box flex={1} mr={1}>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Personal Phone {index + 1}
              </Typography>
              <PhoneInput
                country={'us'}
                value={formatPhoneNumber(phone)}
                onChange={(value) => handleChange(setPersonalPhones, index, value)}
                inputStyle={{
                  height: '55px',
                  width: '100%',
                }}
                mask="999-999-9999"
                defaultCountry="US"
                error={!!errors.personalPhones[index]}
                inputComponent={TextField}
                inputProps={{
                  id: `personal-phone-${index}`,
                  name: `personal-phone-${index}`,
                  variant: 'outlined',
                  fullWidth: true,
                }}
                helperText={errors.personalPhones[index]}
              />
            </Box>
            <Tooltip  arrow position='top' title='Add Another'> 
            <IconButton onClick={() => handleAddField(setPersonalPhones)}>
              <AddIcon />
            </IconButton>
            </Tooltip>
          </div>
        ))}
        {businessPhones.map((phone, index) => (
          <div key={index} className="flex items-center mb-2">
            <Box flex={1} mr={1}>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Business Phone {index + 1}
              </Typography>
              <PhoneInput
                country={'us'}
                value={formatPhoneNumber(phone)}
                onChange={(value) => handleChange(setBusinessPhones, index, value)}
                inputStyle={{
                  height: '55px',
                  width: '100%',
                }}
                mask="999-999-9999"
                defaultCountry="US"
                error={!!errors.businessPhones[index]}
                inputComponent={TextField}
                inputProps={{
                  id: `business-phone-${index}`,
                  name: `business-phone-${index}`,
                  variant: 'outlined',
                  fullWidth: true,
                }}
                helperText={errors.businessPhones[index]}
              />
            </Box>
            <Tooltip  arrow position='top' title='Add Another'> 
            <IconButton onClick={() => handleAddField(setBusinessPhones)}>
              <AddIcon />
            </IconButton>
            </Tooltip>
          </div>
        ))}
      </DialogContent>
      <div className="flex items-center justify-center mt-4">
        <Button
          variant="outlined"
          size="large"
          className="text-white border-[#00C1FE] bg-[#00C1FE] hover:border-[#00C1FE] hover:bg-[#00C1FE]"
          style={{ outline: '1px solid rgba(0, 0, 0, 0.12)', backdropFilter: 'blur(4px)', marginRight: '1rem' }}
          onClick={handleSave}
        >
          Add
        </Button>
      </div>
      <br/>
    </Dialog>
  );
}
