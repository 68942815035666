import React, { useState } from "react";
import { Card, CardContent, Typography, Grid, Box, Button, Collapse } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const Highlight = styled('span')(({ theme }) => ({
  color: 'green',
  fontWeight: 'bold',
}));

const parseJSON = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
};

const CandidateCard = ({ candidate, onCandidateClick }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    onCandidateClick(candidate.search_level);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        marginBottom: 2,
        backgroundColor: '#f5f5f5',
        borderRadius: 2,
        boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
        border: candidate?.search_level === 1 ? '1px solid red' : '1px solid green',
      }}
    >
      <CardContent>
        <Typography variant="h6" color="secondary">
          <a href={candidate?.source_link ? candidate?.source_link : '#'} target="_blank" rel="noopener noreferrer">
            {candidate?.full_name}
          </a>
        </Typography>
        <Typography variant="body1" sx={{ color: '#4caf50' }}>
          {candidate?.headline}
        </Typography>
        <Box sx={{ marginTop: 1 }}>
          <Typography variant="body2">
            <Highlight>Current Title:</Highlight> {candidate?.current_title || 'N/A'}
          </Typography>
          <Typography variant="body2">
            <Highlight>Contact:</Highlight> {candidate?.personal_phone} | {candidate?.personal_email}
          </Typography>
          <Typography variant="body2">
            <Highlight>Registered on {candidate?.candidate_source}:</Highlight>{' '}
            {candidate?.registered_on_job_board?.replace('Registered', '')}
          </Typography>
          <Typography variant="body2">
            <Highlight>Registered on CRM:</Highlight> {new Date(candidate?.createdAt).toDateString()} at{' '}
            {new Date(candidate?.createdAt).toLocaleTimeString()}
          </Typography>
          <Typography variant="body2">
            <Highlight>Updated on CRM:</Highlight> {new Date(candidate?.updatedAt).toDateString()} at{' '}
            {new Date(candidate?.createdAt).toLocaleTimeString()}
          </Typography>
          <Typography variant="body2">
            <Highlight>Last Active:</Highlight> {candidate?.last_active_on_job_board}
          </Typography>
        </Box>

        <Button
          onClick={handleExpandClick}
          endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
          sx={{ marginTop: 2, textTransform: 'none' }}
        >
          {expanded ? 'Show Less' : 'Show More'}
        </Button>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={{ marginTop: 2 }}>
            {candidate?.skills?.length > 0 && (
              <Typography variant="body2">
                <Highlight>Skills:</Highlight> {candidate?.skills.join(', ')}
              </Typography>
            )}
            <Typography variant="body2">
              <Highlight>Looking For:</Highlight>
            </Typography>
            {/*  "looking_for": [
                "{\"salary\":\"£50,000 p.a.\",\"job_type\":\"Full-time, permanent, contract work\"}"
            ], */}
            {candidate?.looking_for && candidate?.looking_for?.length > 0 ? (
              <Grid container spacing={1} sx={{ marginLeft: '1rem', marginRight: '1rem' }}>
                {candidate?.looking_for.map((looking, index) => {
                  const parsedLooking = parseJSON(looking);
                  return parsedLooking ? (
                    <Grid item xs={12} key={index}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'space-between',
                          justifyContent: 'space-between',
                          width: '90%',
                        }}
                      >
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          {parsedLooking.job_type || 'N/A'}
                        </Typography>
                        <Typography variant="body2">
                          <Highlight>{parsedLooking.salary || 'N/A'}</Highlight>
                        </Typography>
                      </div>
                    </Grid>
                  ) : (
                    <Typography variant="body2">N/A</Typography>
                  );
                })}
              </Grid>
            ) : (
              <Typography variant="body2">N/A</Typography>
            )}

            {candidate?.notice_period && (
              <Typography variant="body2">
                <Highlight>Notice Period:</Highlight> {candidate?.notice_period}
              </Typography>
            )}

            {candidate?.transport_license && (
              <Typography variant="body2">
                <Highlight>Transport License:</Highlight> {candidate?.transport_license}
              </Typography>
            )}

            {candidate?.eligible_to_work && (
              <Typography variant="body2">
                <Highlight>Eligible to Work:</Highlight> {candidate?.eligible_to_work}
              </Typography>
            )}

            {candidate?.location && (
              <Typography variant="body2">
                <Highlight>Location:</Highlight> {candidate?.location}
              </Typography>
            )}

            {candidate?.preferred_locations && (
              <Typography variant="body2">
                <Highlight>Preferred Locations:</Highlight> {candidate?.preferred_locations}
              </Typography>
            )}

            {candidate?.preferred_industries?.length > 0 && (
              <Typography variant="body2">
                <Highlight>Preferred Industries:</Highlight> {candidate?.preferred_industries.join(', ')}
              </Typography>
            )}

            {candidate?.languages && (
              <Typography variant="body2">
                <Highlight>Languages:</Highlight> {candidate?.languages}
              </Typography>
            )}

            {candidate?.is_person_profile_updated && (
              <Typography variant="body2">
                <Highlight>Profile Updated:</Highlight> {candidate?.is_person_profile_updated}
              </Typography>
            )}

            {candidate?.about && (
              <Typography variant="body2">
                <Highlight>About:</Highlight> {candidate?.about}
              </Typography>
            )}

            <Typography variant="body2">
              <Highlight>Work History:</Highlight>
            </Typography>
            {candidate?.work_history && candidate?.work_history?.length > 0 ? (
              <Grid container spacing={1} sx={{ marginLeft: '1rem', marginRight: '1rem' }}>
                {candidate?.work_history.map((work, index) => {
                  const parsedWork = parseJSON(work);
                  return parsedWork ? (
                    <Grid item xs={12} key={index}>
                      {console.log('hjsgdfdsfsdf', parsedWork)}
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        {parsedWork?.title || 'N/A'}
                      </Typography>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'space-between',
                          justifyContent: 'space-between',
                          width: '90%',
                        }}
                      >
                        <Typography variant="body2">
                          <Highlight>{parsedWork.company || 'N/A'}</Highlight>
                        </Typography>
                        <Typography variant="body2">
                          <Highlight>{parsedWork.date || 'N/A'}</Highlight>
                        </Typography>
                      </div>
                      <br />
                      <Typography variant="body2">
                        <Highlight>Responsibilities:</Highlight> {parsedWork.responsibilities || 'not added'}
                      </Typography>
                    </Grid>
                  ) : (
                    <Typography variant="body2">N/A</Typography>
                  );
                })}
              </Grid>
            ) : (
              <Typography variant="body2">N/A</Typography>
            )}

            <Typography variant="body2" sx={{ marginTop: 2 }}>
              <Highlight>Qualifications:</Highlight>
            </Typography>
            {candidate?.qualifications && candidate?.qualifications?.length > 0 ? (
              candidate?.qualifications?.map((qualification, index) => {
                const parsedQualification = parseJSON(qualification);
                return parsedQualification ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginLeft: '1rem',
                      marginRight: '1rem',
                    }}
                  >
                    <Typography variant="body2" key={index}>
                      {parsedQualification?.university_detail || 'N/A'}
                    </Typography>
                    <Typography variant="body2" key={index}>
                      {parsedQualification?.date || 'N/A'}
                    </Typography>
                  </Box>
                ) : (
                  <Typography variant="body2">N/A</Typography>
                );
              })
            ) : (
              <Typography variant="body2">N/A</Typography>
            )}

            {candidate?.candidate_source && (
              <Typography variant="body2" sx={{ marginTop: 2 }}>
                <Highlight>Candidate Source:</Highlight> {candidate?.candidate_source}
              </Typography>
            )}

            <Button
              href={candidate?.source_link ? candidate?.source_link : '#'}
              target="_blank"
              sx={{ marginTop: 2, textTransform: 'none' }}
            >
              View Profile
            </Button>
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default CandidateCard;
