import React, { useEffect, useState } from 'react';
// import './styles.css';
import { Chip, FormControl, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
    formControlRoot: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      width: '100%',
      flexWrap: 'wrap',
      flexDirection: 'row',
      border: '2px solid lightgray',
      padding: 4,
      borderRadius: '4px',
      '&> div.container': {
        gap: '6px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
      '& > div.container > span': {
        backgroundColor: 'gray',
        padding: '1px 3px',
        borderRadius: '4px',
      },
    },
  }));
const MultiValuedInput = ({ renderValues, onChange, label, placeholder }) => {
    const classes = useStyles();
    const [values, setValues] = useState(renderValues ? renderValues : []);
    const [currValue, setCurrValue] = useState('');
  
    const handleKeyUp = (e) => {
      if (e.key === 'Enter' || e.key === ',' || e.key === 'Tab') {
        // Trim and add the input value to the array
        setValues((oldState) => [...oldState, currValue.trim()]);
        setCurrValue('');
        // e.preventDefault(); // Prevent the default space key behavior
      }
    };
  
    useEffect(() => {
      onChange(values);
    }, [values]);
  
    const handleChange = (e) => {
      setCurrValue(e.target.value);
    };
  
    const handleDelete = (item, index) => {
      let arr = [...values];
      arr.splice(index, 1);
      setValues(arr);
    };

 
  return (
    <div>
        <div className="App">
      <FormControl classes={{ root: classes.formControlRoot }} fullWidth>
        <div className='container'>
          {values.length === 0 && <Chip size="small" sx={{ backgroundColor: '#00C1FE', color: 'white' }} label="Nothing Selected (Press Enter To Add)" />}
          {values.length !== 0 && values.map((item, index) => (
            <Chip size="small" onDelete={() => handleDelete(item, index)} label={item} />
          ))}
        </div>
        <TextField
          placeholder={(placeholder ? placeholder : '') + ' (press enter to add)'}
          size='small'
          fullWidth
          value={currValue}
          onChange={handleChange}
          onKeyDown={handleKeyUp}
          onBlur={handleKeyUp} />
      </FormControl>
    </div>
    </div>
  )
}

export default MultiValuedInput
