import { Box, Typography, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Icon } from '@iconify/react';

function ClickablePieChart({ title, subheader, chartData, dynamicStats }) {
  const [currentData, setCurrentData] = useState([]);
  const [currentSubheader, setCurrentSubheader] = useState(subheader);

  // UseEffect to set initial data once the chartData and dynamicStats are ready
  useEffect(() => {
    if (chartData.length > 0) {
      setCurrentData(chartData);
      setCurrentSubheader(subheader);
    }
  }, [chartData]);

  const handleClick = (clickedName) => {
    if (clickedName === 'With Contact Info') {
      setCurrentData([
        {
          name: 'Verified Leads',
          value: dynamicStats ? dynamicStats.verifiedLeads : 0,
          color: '#4dd2fe',
        },
        {
          name: 'Bounced Leads',
          value: dynamicStats ? dynamicStats.bouncedLeads : 0,
          color: '#fe4d4d',
        },
      ]);
      setCurrentSubheader(`With Contact Info: ${dynamicStats ? dynamicStats.withContactInfo : 0}`);
    } else if (clickedName === 'Without Contact Info') {
      setCurrentData([
        {
          name: 'Email Not Found Leads',
          value: dynamicStats ? dynamicStats.emailNotFoundLeads : 0,
          color: '#ca80fe',
        },
        {
          name: 'Not Worked',
          value: dynamicStats ? dynamicStats.notWorked : 0,
          color: '#fe804d',
        },
        {
          name: 'Not Assigned',
          value: dynamicStats ? dynamicStats.notAssigned : 0,
          color: '#FFB27D',
        },
      ]);
      setCurrentSubheader(`Without Contact Info: ${dynamicStats ? dynamicStats.withoutContactInfo : 0}`);
    }
  };

  const options = {
    chart: {
      type: 'pie',
      height: '300px',
      backgroundColor: 'white',
      style: {
        fontFamily: 'Arial, sans-serif',
      },
      plotShadow: true,
      events: {
        load: function () {
          var chart = this;
          // Add a horizontal grey bar above the legend
          chart.renderer
            .rect(
              chart.legend.group.translateX - 10, // X position adjustment
              chart.legend.group.translateY - 15, // Y position adjustment to place the bar above the legend
              chart.legend.legendWidth + 30, // Width adjustment for the bar
              2 // Height of the bar
            )
            .attr({
              fill: '#cccccc', // Grey color
              zIndex: 3, // Ensure it's on top,
              marginTop: '1px',
            })
            .add();
        },
      },
    },
    title: {
      text: ' ',
      align: 'left',
    },
    subtitle: {
      text: currentSubheader,
      align: 'left',
      style: {
        fontSize: '12px', // Adjusted subtitle font size
      },
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y}</b> ({point.percentage:.1f}%)',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.y} ({point.percentage:.1f}%)',
          style: {
            color: 'black',
            fontSize: '8px',
          },
        },
        showInLegend: true,
        point: {
          events: {
            click: function () {
              handleClick(this.name);
            },
          },
        },
        size: '80%',
      },
    },
    legend: {
      itemStyle: {
        fontSize: '10px', // Custom font size for legend labels
        color: '#333333', // Custom color for legend text // Bold text
      },
      symbolHeight: 12, // Adjust symbol height in the legend
      symbolWidth: 12, // Adjust symbol width in the legend
      symbolRadius: 6, // Adjust symbol border radius
      margin: 20, // Adjust margin between legend and chart
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: 'Share',
        colorByPoint: true,
        data: currentData.map((item) => ({
          name: item.name,
          y: item.value,
          color: item.color,
        })),
      },
    ],
  };

  const handleBackClick = () => {
    // Reset to the initial chart data
    setCurrentData(chartData);
    setCurrentSubheader(subheader);
  };

  if (!currentData.length) {
    // Show a loader or a placeholder if data is not ready
    return <div>Loading...</div>;
  }

  return (
    <div
      style={{
        backgroundColor: 'white',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        borderRadius: '8px',
        marginLeft: '5px',
        marginRight: '5px',
        // width: '350px',
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="16px">
        <Typography variant="h6" style={{ marginRight: 'auto' }}>
          {title}
        </Typography>
        {currentSubheader !== subheader && (
          <Icon icon="ep:back" style={{ fontSize: '25px', cursor: 'pointer' }} onClick={handleBackClick} />
        )}
      </Box>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

export default ClickablePieChart;
