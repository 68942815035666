import { Icon } from '@iconify/react';
import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import QuillEditor from './QuilEditor';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

function AddNewRole({ open, onClose }) {
  const userId = 1;
  const [selectedClient, setSelectedClient] = useState(null);
  const [docFileTemplate, setDocFileTemplate] = useState(null);
  const initialValues = {
    title: '',
    location: '',
    leadId: null,
    roleDate: new Date().toISOString().split('T')[0],
    endDate: null,
    acmName: null,
    roleType: '',
    clientNumber: null,
    clientId: null,
    serviceId: null,
    addedByUserId: userId,
    roleNumber: null,
    noCreditChargeReason: null,
    chargeCredit: '',
  };

  const Services = ['CV Sourcing', 'Pre-Qualification', '360 / Direct'];
  const Clients = ['HCL', 'Infosys', 'Cognizant', 'Wipro'];
  const cellStyle = {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
    fontWeight: 'bold',
  };
  const roleCategory = ['Fix', 'Regular', ' Trial'];
  const chargeCreditOptions = ['Yes', 'No'];
  const acmOptions = ['Komal', 'John'];
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={false}
        disableBackdropClick
        disableEscapeKeyDown
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            ml: 'auto',
            mt: 'auto',
            mr: '1rem',
            width: '100%',
            maxWidth: '900px',
            height: '95%',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#E1F7FC',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" className="ml-8">
              Add New Role
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <br />
          <Formik
            initialValues={initialValues}
            //  validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);
              //    handleUploadFile(values, actions);
            }}
          >
            {({ errors, values, getFieldProps, setFieldValue, touched, handleSubmit }) => (
              <Form>
                {/* {!editRoleDetails && ( */}
                <>
                  <Autocomplete
                    id="serviceId"
                    options={Services || []}
                    size="small"
                    value={values.serviceId}
                    defaultValue={values.serviceId}
                    getOptionLabel={(option) => option}
                    onChange={(event, newService) => {
                      if (newService) {
                        setFieldValue('serviceId', newService);
                      } else {
                        setFieldValue('serviceId', null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Service"
                        fullWidth
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          border: '1px solid #E4E4E5',
                          marginBottom: '15px',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#00C1FE',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#00C1FE',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #00C1FE',
                            },
                          },
                        }}
                      />
                    )}
                  />
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={Clients}
                    // disabled={!values?.serviceId}
                    getOptionLabel={(option) => option}
                    onChange={(event, newClient) => {
                      if (newClient) {
                        setFieldValue('clientNumber', newClient);
                        setSelectedClient(newClient);
                      } else {
                        // setClientRoles([]);
                        setSelectedClient(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Client"
                        variant="outlined"
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          border: '1px solid #E4E4E5',
                          marginBottom: '15px',
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: '#00C1FE',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #00C1FE',
                            },
                          },
                        }}
                      />
                    )}
                  />

                  {selectedClient && (
                    <>
                      <div>
                        <p style={{ fontSize: '16px', fontWeight: 'bold' }}>Subscription Info</p>
                        {/* beautify table */}
                        <div
                          style={{
                            maxHeight: '200px', // Approximate height for 3 rows
                            overflowY: 'auto',
                            borderRadius: '8px',
                            border: '1px solid #ddd',
                            marginTop: '3px',
                            backgroundColor: '#E1F7FC',
                            border: '2px dashed #00C1FE',
                          }}
                        >
                          <table
                            style={{
                              borderCollapse: 'collapse',
                              width: '100%',
                              fontFamily: 'Arial, sans-serif',
                            }}
                          >
                            <tbody>
                              <tr
                                style={{
                                  position: 'sticky',
                                  top: 0,
                                  textAlign: 'left',
                                  padding: '12px',
                                  fontSize: '12px',
                                }}
                              >
                                <th style={cellStyle}>Start Date</th>
                                <th style={cellStyle}>Expiry Date</th>
                                <th style={cellStyle}>Total Credits</th>
                                <th style={cellStyle}>Used Credits</th>
                                <th style={cellStyle}>Remaining Credits</th>
                                <th style={cellStyle}>Subscription</th>
                              </tr>
                              <tr
                                style={{
                                  textAlign: 'left',
                                  padding: '10px',
                                  fontSize: '12px',
                                }}
                              >
                                <td style={cellStyle}>2024-06-12</td>
                                <td style={cellStyle}>2024-06-12</td>
                                <td style={cellStyle}>0</td>
                                <td style={cellStyle}>0</td>
                                <td style={cellStyle}>0</td>
                                <td
                                  style={{
                                    ...cellStyle,
                                  }}
                                >
                                  Adhoc
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}

                  {/* {clientRoles?.length > 0 && ( */}
                  {selectedClient && (
                    <>
                      <div
                        style={{
                          maxHeight: '200px', // Approximate height for 3 rows
                          overflowY: 'auto',
                          borderRadius: '8px',
                          marginTop: '10px',
                          backgroundColor: '#E1F7FC',
                          border: '2px dashed #00C1FE',
                        }}
                      >
                        <p style={{ fontSize: '13px', textAlign: 'center', paddingTop: '2px', fontWeight: 'bold' }}>
                          Added Roles for this Client
                        </p>
                        <table
                          style={{
                            borderCollapse: 'collapse',
                            width: '100%',
                            fontFamily: 'Arial, sans-serif',
                          }}
                        >
                          <thead>
                            <tr
                              style={{
                                // make this row sticky for scrolling table body only (not thead)
                                position: 'sticky',
                                top: 0,
                                textAlign: 'left',
                                padding: '12px',
                                marginTop: '3px',
                                fontSize: '12px',
                                backgroundColor: '#D0EBFD',
                              }}
                            >
                              <th>#</th>
                              <th style={{ padding: '7px' }}>Role Title</th>
                              <th>Role Status</th>
                              <th>Role Date</th>
                              <th>Role Type</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* {clientRoles?.map((role, index) => ( */}
                            <tr
                              // key={index}
                              style={{
                                fontSize: '12px',
                                transition: 'background-color 0.3s ease',
                              }}
                            >
                              <td style={cellStyle}>{/* {index + 1} */}1</td>
                              <td style={cellStyle}>
                                <span style={{ fontWeight: 'bold' }}>{'Doc file is attached for this role'}</span>
                              </td>
                              <td style={cellStyle}>
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                  }}
                                >
                                  Pending
                                </span>
                              </td>
                              <td style={cellStyle}>
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    // color: getColor(role.roleDate),
                                  }}
                                >
                                  {/* extract date only */}
                                  {/* {role.roleDate !== null ? role.roleDate.split('T')[0] : 'No date'}
                                   */}
                                  2024-09-23
                                </span>
                              </td>
                              <td style={cellStyle}>
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    // color: getColor(role.roleDate),
                                  }}
                                >
                                  {/* extract date only */}
                                  {/* {role.roleType}
                                   */}
                                  Regular
                                </span>
                              </td>
                            </tr>
                            {/* ))} */}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}

                  <Autocomplete
                    id="roleType"
                    size="small"
                    fullWidth
                    options={roleCategory}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => {
                      setFieldValue('roleType', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        placeholder="Select Role Category"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={touched.roleType && Boolean(errors.roleType)}
                        helperText={touched.roleType && errors.roleType}
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          marginTop: '10px',
                          marginBottom: '15px',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#00C1FE',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#00C1FE',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #00C1FE',
                            },
                          },
                        }}
                      />
                    )}
                  />

                  <TextField
                    label="Role Date"
                    value={values.roleDate}
                    defaultValue={new Date()}
                    fullWidth
                    id="roleDate"
                    name="roleDate"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    variant="outlined"
                    onChange={(e) => {
                      setFieldValue('roleDate', e.target.value);
                    }}
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      marginBottom: '15px',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />

                  {values.roleType === 'Fix' && (
                    <TextField
                      label="End Date"
                      value={values.endDate}
                      fullWidth
                      id="endDate"
                      name="endDate"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      variant="outlined"
                      onChange={(e) => {
                        setFieldValue('endDate', e.target.value);
                      }}
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        marginBottom: '15px',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  )}

                  <Autocomplete
                    id="creditoption"
                    fullWidth
                    size="small"
                    options={chargeCreditOptions}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => {
                      // setChargeCredit(newValue)
                      setFieldValue('chargeCredit', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        id="creditoption"
                        {...params}
                        placeholder="Charge Credit"
                        variant="outlined"
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          marginBottom: '15px',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#00C1FE',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#00C1FE',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #00C1FE',
                            },
                          },
                        }}
                      />
                    )}
                  />
                </>

                {values.chargeCredit === 'Yes' && (
                  <TextField
                    required
                    id="roleNumber"
                    name="roleNumber"
                    value={values?.roleNumber}
                    type="number"
                    {...getFieldProps('roleNumber')}
                    size="small"
                    placeholder="Role Number"
                    error={touched.roleNumber && Boolean(errors.roleNumber)}
                    helperText={touched.roleNumber && errors.roleNumber}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      marginBottom: '15px',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />
                )}
                {values.chargeCredit === 'No' && (
                  <TextField
                    required
                    id="noCreditChargeReason"
                    name="noCreditChargeReason"
                    value={values?.noCreditChargeReason}
                    type="text"
                    {...getFieldProps('noCreditChargeReason')}
                    placeholder="No credit charge reason"
                    size="small"
                    error={touched.noCreditChargeReason && Boolean(errors.noCreditChargeReason)}
                    helperText={touched.noCreditChargeReason && errors.noCreditChargeReason}
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      marginBottom: '15px',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />
                )}

                <Box display="flex" flexDirection="column" p={3} m={2}>
                  <TextField
                    required
                    id="title"
                    name="title"
                    value={values.title}
                    type="string"
                    {...getFieldProps('title')}
                    placeholder="Main Title"
                    size="small"
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      marginBottom: '15px',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />

                  <TextField
                    required
                    id="location"
                    name="location"
                    value={values.location}
                    type="string"
                    {...getFieldProps('location')}
                    placeholder="Location"
                    size="small"
                    error={touched.location && Boolean(errors.location)}
                    helperText={touched.location && errors.location}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      marginBottom: '15px',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />

                  <Autocomplete
                    id="acmName"
                    size="small"
                    fullWidth
                    value={values.acmName}
                    defaultValue={values.acmName}
                    options={acmOptions}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => {
                      setFieldValue('acmName', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        placeholder="Select ACM"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={touched.acmName && Boolean(errors.acmName)}
                        helperText={touched.acmName && errors.acmName}
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          marginBottom: '15px',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#00C1FE',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#00C1FE',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #00C1FE',
                            },
                          },
                        }}
                      />
                    )}
                  />

                  {/* {!editRoleDetails && ( */}
                  <>
                    <Input type="file" style={{ display: 'none' }} id="fileInput" />
                    <label htmlFor="fileInput">
                      <Button
                        component="span"
                        sx={{
                          fontSize: '18px',
                          borderRadius: '10px',
                          borderWidth: '3px',
                          marginBottom: '15px',
                          color: 'white',
                          backgroundColor: '#0ec4fe',
                          boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.0)',
                          marginLeft: '10px',
                          borderColor: '#0ec4fe !important',
                          marginRight: '30px',
                          '&:hover': {
                            color: 'white !important',
                            borderColor: '#0ec4fe !important',
                            boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.0) !important',
                            backgroundColor: '#0ec4fe !important',
                          },
                        }}
                      >
                        Select Docs
                      </Button>
                    </label>
                  </>
                  {/* )} */}

                  <QuillEditor
                    htmlValue={docFileTemplate}
                    onTextChange={(htmlText) => {
                      setDocFileTemplate(htmlText);
                    }}
                  />
                  {/* <HTMLEditor
                    editorLabel="Template"
                    htmlText={docFileTemplate}
                    onEditorStateChange={(htmlText) => {
                      setDocFileTemplate(htmlText?.data);
                    }}
                  /> */}

                  {/* {!docFileTemplate && <Alert severity="warning">Select file</Alert>} */}
                  <Box display="flex" justifyContent="flex-end" m={2}>
                    <Button
                      sx={{
                        backgroundColor: '#A3A3A3',
                        fontSize: '16px',
                        color: 'white',
                        marginLeft: '1rem',
                        '&:hover': {
                          color: 'white !important',
                          backgroundColor: '#A3A3A3 !important',
                        },
                      }}
                      style={{ marginRight: '10px' }}
                      onClick={() => onClose()}
                    >
                      Cancel
                    </Button>

                    <>
                      <Button
                        sx={{
                          fontSize: '18px',
                          borderRadius: '10px',
                          borderWidth: '3px',
                          color: 'white',
                          backgroundColor: '#0ec4fe',
                          boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.0)',
                          marginLeft: '10px',
                          borderColor: '#0ec4fe !important',
                          marginRight: '30px',
                          '&:hover': {
                            color: 'white !important',
                            borderColor: '#0ec4fe !important',
                            boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.0) !important',
                            backgroundColor: '#0ec4fe !important',
                          },
                        }}
                        // disabled={!docFileTemplate}
                        startIcon={<Icon icon="line-md:cloud-upload-outline-loop" />}
                        onClick={() => {
                          if (Object.keys(errors)?.length > 0) {
                            // notify('Some fields are missing', 'error', 1000);
                          } else {
                            handleSubmit();
                          }
                        }}
                      >
                        Upload
                      </Button>
                    </>
                    {/* )} */}
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddNewRole;
