import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Button, IconButton, Card, CardHeader, CardContent, Avatar } from '@mui/material';
import { Cancel, Edit, Send } from '@mui/icons-material';

function IssueBox({ issueData, onSubmitIssue, onUpdateIssue }) {
  const [showIssue, setShowIssue] = useState([]);
  const [inputIssue, setInputIssue] = useState('');
  const [isEditingIssue, setIsEditingIssue] = useState(false);
  const [issueIndex, setIssueIndex] = useState(null);

    useEffect(() => {
      setShowIssue(issueData);
    }, [issueData]);

    const handleCancelIssueClick = () => {
      setIsEditingIssue(false);
      setInputIssue('');
    };

    const handleSubmitIssue = () => {
      if (inputIssue.trim() !== '') {
        if (isEditingIssue) {
          onUpdateIssue(issueIndex, inputIssue);
          setIsEditingIssue(false);
        } else {
          onSubmitIssue(inputIssue);
        }
        setInputIssue('');
      }
    };

    const handleEditIssueClick = (issue, index) => {
      setIsEditingIssue(true);
      setIssueIndex(index);
      setInputIssue(issue.issueAdded);
    };

  const renderMessages = (messages, handleEditClick, type) => {
    return (
      <Box
        sx={{
          maxHeight: '400px',
          overflowY: 'auto', // Make the container scrollable
          flexGrow: 1,
        }}
      >
        {messages.map((message, index) => (
          <Box key={message.id} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Box display="flex" flexDirection="column">
              <Box display="flex">
                {/* Avatar for the fullname initials */}
                <Avatar sx={{ width: '20px', height: '20px' }}>{message?.fullName[0]?.toUpperCase()}</Avatar>
                &nbsp;&nbsp;
                <Typography variant="body2" component="span">
                  <strong>{message.fullName.split(' ')[0]}</strong> ({message.role})
                </Typography>
                &nbsp;&nbsp;
                <Typography variant="caption" component="div" color="textSecondary">
                  {message.dateTime.split(',')[1]}
                </Typography>
              </Box>
              <Typography
                variant="body2"
                component="span"
                color="textSecondary"
                width="100%"
                sx={{ border: '1px solid black', borderRadius: '10px', padding: '5px' }}
              >
                {message[`${type}Added`]}
              </Typography>
            </Box>
            <IconButton size="small" onClick={() => handleEditClick(message, index)}>
              <Edit fontSize="small" />
            </IconButton>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <>
      <Card
        sx={{
          color: '#0D6EFD',
          borderRadius: '10px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          alignContent="center"
          sx={{
            backgroundColor: '#E1F7FC',
            color: '#00C1FE',
            padding: '8px',
          }}
        >
          <Typography variant="h6" sx={{ padding: '10px' }}>
            Issues
          </Typography>
        </Box>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          {renderMessages(showIssue, handleEditIssueClick, 'issue')}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <TextField
              placeholder="Enter issue"
              fullWidth
              multiline
              maxRows={3}
              value={inputIssue}
              onChange={(e) => setInputIssue(e.target.value)}
              sx={{
                mr: 1,
                borderRadius: '25px',
                padding: '5px 10px',
                backgroundColor: '#E6EBF5',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '40px',
                  '& fieldset': {
                    border: 'none', // Remove border
                  },
                },
                '&:hover': {
                  backgroundColor: '#E6EBF5', // Keep background color same on hover
                },
              }}
              size="small"
            />

            <Button
              variant="contained"
              sx={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                backgroundColor: '#00C1FE',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: 'unset',
                '&:hover': {
                  backgroundColor: '#00C1FE',
                  color: 'white',
                },
              }}
              onClick={handleSubmitIssue}
            >
              <Send fontSize="small" />
            </Button>
            {isEditingIssue && (
              <Button
                variant="contained"
                sx={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  backgroundColor: '#0D6EFD',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: 'unset',
                  '&:hover': {
                    backgroundColor: '#0D6EFD',
                    color: 'white',
                  },
                  ml: 1,
                }}
                onClick={handleCancelIssueClick}
              >
                <Cancel fontSize="small" />
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default IssueBox;
