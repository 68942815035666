import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Container, Link, Typography } from '@mui/material';
import { LoginForm } from 'src/sections/auth';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useResponsive from 'src/hooks/useResponsive';
import { setUserProfile } from 'src/store/slices/userSlice';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setUserProfile(user));
  }, [user, dispatch]);
  return (
    <>
      <Helmet>
        <title> Login | CRM </title>
      </Helmet>

      <div className="bg-[#eef7ff] h-full pt-10">
        <Container maxWidth="xl">
          <LoginForm
            onUserLogin={(user) => {
              setUser({
                user_name: user.user_name,

                password: user.password,
                status: user.status,
                approved: user.approved,
              });
            }}
          />
        </Container>
      </div>
    </>
  );
}
