import { createContext, useState } from 'react';
import { LeadGenerationSection } from 'src/sections/leads';
import CompaniesList from 'src/sections/leads/companies/CompaniesList';
import { JobPostList } from 'src/sections/leads/jobposts';
import { PersonList } from 'src/sections/leads/persons';

export const roleContext = createContext();
export default function MainLeadGenerationPage({ role }) {
  const [showLeads, setShowLeads] = useState(true);
  const [showCompanies, setShowCompanies] = useState(false);
  const [showPeople, setShowPeople] = useState(false);
  const [showJobs, setShowJobs] = useState(false);
  const [company_sector_id, setCompanySectorId] = useState(null);
  const [comapny_country_id, setCompanyCountryId] = useState(null);
  const [person_sector_id, setPersonSectorId] = useState(null);
  const [person_country_id, setPersonCountryId] = useState(null);
  const [job_sector_id, setJobSectorId] = useState(null);
  const [job_country_id, setJobCountryId] = useState(null);
  return (
    <roleContext.Provider value={role}>
      {showLeads && (
        <LeadGenerationSection
          onCompaniesClick={(sectorId, countryId) => {
            setCompanySectorId(sectorId);
            setCompanyCountryId(countryId);
            setShowLeads(false);
            setShowCompanies(true);
            setShowJobs(false);
            setShowPeople(false);
          }}
          onPeopleClick={(sector_id, country_id) => {
            setPersonSectorId(sector_id);
            setPersonCountryId(country_id);
            setShowLeads(false);
            setShowCompanies(false);
            setShowJobs(false);
            setShowPeople(true);
          }}
          onJobsClick={(sector_id, country_id) => {
            setJobSectorId(sector_id);
            setJobCountryId(country_id);
            setShowLeads(false);
            setShowCompanies(false);
            setShowJobs(true);
            setShowPeople(false);
          }}
        />
      )}

      {showCompanies && (
        <CompaniesList
          country_id={comapny_country_id}
          sector_id={company_sector_id}
          onCountryIdChange={(country_id) => setCompanyCountryId(country_id)}
          onSectorIdChange={(sector_id) => setCompanySectorId(sector_id)}
          onBackClick={() => {
            setShowLeads(true);
            setShowCompanies(false);
            setShowJobs(false);
            setShowPeople(false);
          }}
        />
      )}

      {showPeople && (
        <PersonList
          country_id={person_country_id}
          sector_id={person_sector_id}
          onBackClick={() => {
            setShowLeads(true);
            setShowCompanies(false);
            setShowJobs(false);
            setShowPeople(false);
          }}
          onSectorIdChange={(sector_id) => setPersonSectorId(sector_id)}
        />
      )}

      {showJobs && (
        <JobPostList
          country_id={job_country_id}
          sector_id={job_sector_id}
          onSectorIdChange={(sector_id) => setJobSectorId(sector_id)}
          onBackClick={() => {
            setShowLeads(true);
            setShowCompanies(false);
            setShowJobs(false);
            setShowPeople(false);
          }}
        />
      )}
    </roleContext.Provider>
  );
}
