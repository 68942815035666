import { Icon } from '@iconify/react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function CustomizedRolesComponent(props) {

    const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page whenever rows per page changes
  };

  const roles = [
    {
      receiver: 'Tanya',
      sender: '',
      acm: 'Areej',
      no: '1',
      client_number: 1000,
      role_name: 'Designer',
      role_number: 1,
      service: 'CV-Sourcing',
      status: 'in-progress',
      resourcer: 'Zunaira',
    },
    {
      receiver: 'Tanya',
      sender: '',
      acm: 'Areej',
      no: '1',
      client_number: 1000,
      role_name: 'Designer',
      role_number: 1,
      service: 'CV-Sourcing',
      status: 'pending',
      resourcer: 'Zunaira',
    },
    {
      receiver: 'Tanya',
      sender: '',
      acm: 'Areej',
      no: '1',
      client_number: 1000,
      role_name: 'Designer',
      role_number: 1,
      service: 'CV-Sourcing',
      status: 'pending',
      resourcer: 'Zunaira',
    },
    {
      receiver: 'Irma',
      sender: '',
      acm: 'Komal',
      no: '1',
      client_number: 1000,
      role_name: 'Developer',
      role_number: 1,
      service: 'Pre-Qualification',
      status: 'pending',
      resourcer: 'Zunaira',
    },
    {
      receiver: 'Irma',
      sender: '',
      acm: 'Komal',
      no: '1',
      client_number: 1000,
      role_name: 'Developer',
      role_number: 1,
      service: 'Pre-Qualification',
      status: 'pending',
      resourcer: 'Zunaira',
    },
    {
      receiver: 'Irma',
      sender: '',
      acm: 'Komal',
      no: '1',
      client_number: 1000,
      role_name: 'Developer',
      role_number: 1,
      service: 'Pre-Qualification',
      status: 'pending',
      resourcer: 'Zunaira',
    },
    {
      receiver: 'Clairie',
      sender: '',
      acm: 'Komal',
      no: '1',
      client_number: 1000,
      role_name: 'UI/UX Designer',
      role_number: 1,
      service: '360/Direct',
      status: 'pending',
      resourcer: 'Zunaira',
    },
    {
      receiver: 'Clairie',
      sender: '',
      acm: 'Komal',
      no: '1',
      client_number: 1000,
      role_name: 'UI/UX Designer',
      role_number: 1,
      service: '360/Direct',
      status: 'pending',
      resourcer: 'Zunaira',
    },
    {
      receiver: 'Clairie',
      sender: '',
      acm: 'Komal',
      no: '1',
      client_number: 1000,
      role_name: 'UI/UX Designer',
      role_number: 1,
      service: '360/Direct',
      status: 'pending',
      resourcer: 'Zunaira',
    },
  ];

  // Sorting the roles by service name priority
  const sortedRoles = roles.sort((a, b) => {
    const serviceOrder = ['CV-Sourcing', 'Pre-Qualification', '360/Direct'];
    return serviceOrder.indexOf(a.service) - serviceOrder.indexOf(b.service);
  });

  // Grouping the roles by service
  const groupedRoles = sortedRoles.reduce((groups, role) => {
    const { service } = role;
    if (!groups[service]) {
      groups[service] = [];
    }
    groups[service].push(role);
    return groups;
  }, {});

  // Function to get border color based on service name
  const getBorderColor = (service) => {
    switch (service) {
      case 'CV-Sourcing':
        return '#F85ED6';
      case 'Pre-Qualification':
        return '#8C85FB';
      case '360/Direct':
        return '#00C1FE';
      default:
        return 'black';
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return '#FDC748';
      case 'in-progress':
        return '#00C1FE';
      case 're-working':
        return '#00C1FE';
      case 'completed':

      default:
        return 'black';
    }
  };

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case 'pending':
        return '#FFF3D8';
      case 'in-progress':
        return '#D1F1FC';
      case 're-working':
        return '#FFF3D8';
      case 'completed':

      default:
        return 'black';
    }
  };
  return (
    <div>
      <Paper
        sx={{
          width: '100%',
          overflowX: 'auto',
          position: 'relative',
          display: 'block',
          maxWidth: '100%',
          backgroundColor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.10)',
          borderRadius: '10px',
          '& td, & th': {
            whiteSpace: 'nowrap',
            borderBottom: '1px solid #D9D9D9',
          },
        }}
      >
        <TableContainer
          sx={{
            borderRadius: '10px',
            overflow: 'hidden', // This ensures the rounded corners are visible
          }}
        >
          <Table aria-labelledby="tableTitle">
            <TableHead>
              <TableRow style={{ backgroundColor: '#E1F7FC', padding: '5px' }}>
                <TableCell style={{ fontWeight: 'bold', fontSize :"16px" }}>R</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize :"16px" }}>S</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize :"16px" }}>ACM</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize :"16px" }}>No</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize :"16px" }}>Client No</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize :"16px" }}>Role</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize :"16px" }}> Role No</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize :"16px" }}>Services</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize :"16px" }}>Status</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize :"16px" }}>Resourcer</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ borderRadius: '10px' }}>
              {Object.keys(groupedRoles).map((service) => {
                const rolesForService = groupedRoles[service];
                return (
                  <React.Fragment key={service}>
                    {rolesForService.map((role, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          borderTop: index === 0 ? `3px dashed ${getBorderColor(service)}` : 'none',
                          borderLeft: `3px dashed ${getBorderColor(service)}`,
                          borderRight: `3px dashed ${getBorderColor(service)}`,
                          borderWidth: '3px',
                          overflow: 'hidden',
                          borderBottom:
                            index === rolesForService.length - 1 ? `3px dashed ${getBorderColor(service)}` : 'none',
                          marginBottom: '1px',
                          '& td': {
                            borderBottom: '1px solid #D9D9D9',
                          },
                        }}
                      >
                        <TableCell
                          sx={{
                            borderRadius: '10px 0 0 10px', // Left corners for the first cell
                          }}
                        >
                          {role.receiver}
                        </TableCell>
                        <TableCell>{role.sender}</TableCell>
                        <TableCell>{role.acm}</TableCell>
                        <TableCell>{role.no}</TableCell>
                        <TableCell>{role.client_number}</TableCell>
                        <TableCell style={{ color: '#00C1FE' }}>
                          <Typography
                            onClick={() => navigate(`/acm/roles-processing`)}
                            sx={{ fontSize: '15px', cursor: 'pointer' }}
                          >
                            {role.role_name}
                          </Typography>
                        </TableCell>
                        <TableCell>{role.role_number}</TableCell>
                        <TableCell style={{ color: getBorderColor(service) }}>{role.service}</TableCell>
                        <TableCell>
                          {' '}
                          <Typography
                            sx={{
                              backgroundColor: getStatusBackgroundColor(role.status),
                              color: getStatusColor(role.status),
                              padding: '8px',
                              width: '120px',
                              margin: '5px',
                              fontWeight: 'bold',
                              textAlign: 'center',
                              borderRadius: '15px',
                              display: 'flex', // Flexbox layout
                              alignItems: 'center', // Align items horizontally in the center
                              justifyContent: 'center', // Center content horizontally
                            }}
                          >
                            <Icon icon="fluent-mdl2:radio-bullet" style={{ marginRight: '5px', fontSize: '20px' }} />{' '}
                            {role.status}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderRadius: '0 10px 10px 0', // Right corners for the last cell
                          }}
                        >
                          {role.resourcer}
                        </TableCell>
                      </TableRow>
                    ))}
                    {/* Spacer between groups */}
                    <TableRow>
                      <TableCell colSpan={10} />
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalRows} // The total number of records (from server)
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

export default CustomizedRolesComponent;
