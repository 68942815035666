// import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, TextField} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
// components

// ----------------------------------------------------------------------

export default function ForgetPasswordForm() {
    const [email, setEmail] = useState(''); // This is the email that will be sent to the backend
    // const navigate = useNavigate();
    // const handleClick = () => {
    //     navigate('/login', { replace: true });
    // };

    const sendResetPasswordLink = () => {
        // Send the email to the backend

        // check valid email address
        
        if(email === ""){
            alert("Please enter your email address");
            return;
        }else if(!email.includes("@")){
            alert("Please enter a valid email address");
            return;
        }else if(!email.includes(".")){
            alert("Please enter a valid email address");
            return;
        }else if(email.includes(" ")){
            alert("Please enter a valid email address");
            return;
        }else {
            // send email to backend
            alert("Email sent to " + email);
        }

        // If the email is valid, the backend will send a reset password link to the email
        // If the email is invalid, the backend will send an error message
        // If the email is valid, the backend will send a success message
    };

    return (
        <>
            <Stack spacing={3}>
                <TextField 
                name="email" 
                label="Email address" 
                type="email"
                fullWidth
                required
                onChange={(e)=>{setEmail(e.target.value)}}
                />
            </Stack>
            <br/>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={()=>sendResetPasswordLink()}>
                Send Reset Link
            </LoadingButton>
        </>
    );
}
