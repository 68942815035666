import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

function EmailRecipentsLineChart({ recipientsOverTime, campaigns }) {
  console.log('recipientsOverTime', campaigns);

  // Create a mapping of campaign IDs to campaign names
  const campaignMap = {};
  campaigns?.forEach(campaign => {
    campaignMap[campaign?.id] = campaign?.name; // Assuming each campaign has a unique `id`
  });

  // Prepare the data for Highcharts
  const seriesData = Object.keys(recipientsOverTime).map((key) => {
    return {
      name: campaignMap[key] || `Campaign ${key}`, // Use the campaign name or fallback to 'Campaign {key}'
      data: recipientsOverTime[key].map((item) => [
        Date.UTC(new Date(item.date).getFullYear(), new Date(item.date).getMonth(), new Date(item.date).getDate()),
        item.recipients,
      ]),
    };
  });

  const options = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Campaigns Recipients Over Time',
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Date',
      },
    },
    yAxis: {
      title: {
        text: 'Number of Recipients',
      },
      labels: {
        formatter: function () {
          return Highcharts.numberFormat(this.value, 0); // Format numbers without decimals
        },
      },
    },
    series: seriesData,
  };

  return (
    <div
      style={{
        backgroundColor: 'white',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        borderRadius: '8px',
        marginLeft: '5px',
        marginRight: '5px',
        height: '475px',
      }}
    >
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

export default EmailRecipentsLineChart;