import { Box, Button, Dialog, DialogContent, DialogTitle, Grow, IconButton, Slide, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Add, Close } from '@mui/icons-material';
import { JobpostForm } from '.';
import { useSnackbar } from 'notistack';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

export default function AddJobPostToLocalStorage({ open, onClose, postingLink, isJobPostFound }) {
  // console.log('isJobPostFound', isJobPostFound)
  const [jobPostValues, setJobPostValues] = useState();
  const [formErrors, setFormErrors] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessages, setErrorMessages] = useState([]);
  const [isAlreadyAdded, setIsAlreadyAdded] = useState(false);

  function keyExists(key) {
    return localStorage.getItem(key) !== '';
  }
  console.log('hjsdgafhsdfsdfsd', errorMessages);
  // useEffect(() => {
  //   if (formErrors) {
  //     setErrorMessages(['Please must be fill out job Title, Job Posting link, Date, Country, Sector, Industry']);
  //   }
  //   // else {
  //   //   setErrorMessages(['Please must be fill out job Title, Job Posting link, Date, Country, Sector, Industry']);
  //   // }
  // }, [formErrors]);
  console.log('abcd', jobPostValues);
  console.log('formErrors', formErrors);
  const handleAddJobPostToLocalStorage = () => {
    if (jobPostValues === undefined) {
      setErrorMessages(['Please Must be fill out  Job Title,Job Posting Link,Job Posting Date, Country, Sector']);
      return;
    }
    if (formErrors) {
      setErrorMessages(['Please Must be fill out  Job Title,Job Posting Link,Job Posting Date, Country, Sector']);
      return;
    }


    const jobPost = localStorage.getItem('jobPost');
    if(jobPost){
      setIsAlreadyAdded(true);
      return;
    }else{
      setIsAlreadyAdded(false);
    }
    const values = JSON.stringify(jobPostValues);
    localStorage.setItem('jobPost', values);
    localStorage.setItem('postingLink', postingLink);
    enqueueSnackbar('Job post added', { variant: 'success' });
    onClose();
  };

  useEffect(() => {
    const exsitingJob = localStorage.getItem('jobPost');
    if (exsitingJob) {
      setIsAlreadyAdded(true);
    } else {
      setIsAlreadyAdded(false);
    }
  }, [postingLink]);

// console.log('exsitingJob',exsitingJob)
  console.log('yes', jobPostValues);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      hideBackdrop={false}
      disableBackdropClick
      disableEscapeKeyDown
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '900px', // Set maximum width to control dialog width
          width: '100%', // Set width to 100% for responsiveness
          maxHeight: '90%', // Set maximum height to avoid covering entire screen
        },
      }}
      aria-describedby="alert-dialog-grow-description"
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: '#F4F9FD',
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4">{isJobPostFound ? 'Job already added' : 'Add Job Post'}</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ backgroundColor: '#F4F9FD' }}>
        {isJobPostFound === true ? (
          <Typography variant="h5" className="mt-8 text-red-600">
            The job post you're attempting to add already exists. Please consider creating a new job post instead.
          </Typography>
        ) : isAlreadyAdded ? (
          <>
            <Typography variant="h5" className="mt-8 text-red-600">
              You have recently added a job post. Would you like to discard the current job post and add a new one?
              Alternatively, you can associate the current job post with a specific person or company.
            </Typography>
            <div className="flex justify-around flex-wrap mt-12">
              <Button
                onClick={() => {
                  localStorage.removeItem('jobPost');
                  localStorage.removeItem('postingLink');
                  onClose();
                }}
                style={{
                  backgroundColor: '#FA8282',
                  color: 'white',
                  borderRadius: '10px',
                  width: '200px',
                  textAlign: 'center',
                }}
              >
                Discard
              </Button>
              <Button
                onClick={() => onClose()}
                className="text-white border-[#00C1FE] bg-[#00C1FE] hover:border-[#00C1FE] hover:bg-[#00C1FE] px-2 py-2"
                style={{ outline: '1px solid rgba(0, 0, 0, 0.12)', backdropFilter: 'blur(4px)', marginRight: '1rem' }}
              >
                Associate company or person ?
              </Button>
            </div>
          </>
        ) : (
          <>
            <JobpostForm
              onSubmit={(values, actions) => {
                // console.log("values123", values);
                setJobPostValues(values);
              }}
              isError={(isValid) => {
                console.log('isValid', isValid);
                setFormErrors(isValid);
              }}
              onUpdate={(values) => {
                setJobPostValues({ ...jobPostValues, ...values });
              }}
              postingLink={postingLink}
            />
            <br />
            {errorMessages.length > 0 && (
              <div style={{ color: 'red', marginBottom: '20px' }}>
                {errorMessages.map((msg, index) => (
                  <div key={index}>{msg}</div>
                ))}
              </div>
            )}
            {console.log('errorMessages', errorMessages)}
            <br />
            <div className="flex items-center justify-center mt-4">
              <Button
                variant="outlined"
                size="large"
                startIcon={<Add />}
                className="text-white border-[#00C1FE]  bg-[#00C1FE] hover:border-[#00C1FE] hover:bg-[#00C1FE]"
                style={{ outline: '1px solid rgba(0, 0, 0, 0.12)', backdropFilter: 'blur(4px)', marginRight: '1rem' }}
                onClick={() => {
                  handleAddJobPostToLocalStorage();
                }}
              >
                Add
              </Button>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
