
export const roles = [
  {
      id:"admin",
      role:"Admin",
  },
  {
      id:"hrm",
      role:"HRM",
  },
  {
      id:"leadExpert",
      role:"Lead Expert",
  },
  {
      id:"leadAdmin",
      role:"Lead Admin",
  },
  {
      id:"bdExpert",
      role:"Business Developer",
  },
  {
      id:"360Recruiter",
      role:"360 Recruiter",
  },
  {
      id:"resourcer",
      role:"Resourcer",
  },
  {
      id:"accounts",
      role:"ACM",
  },
  {
      id:"QAResourcing",
      role:"Resourcing QA",
  }
]

export const workingModes = [
  {
      id:"remote",
      workingMode:"Remote",
  },
  {
      id:"on-site",
      workingMode:"Onsite",
  },
  {
      id:"hybrid",
      workingMode:"Hybrid",
  },
]
export const companySizes = [
  {
    id: 1,
    size: '1-10',
  },
  {
    id: 2,
    size: '11-50',
  },
  {
    id: 3,
    size: '51-200',
  },
  {
    id: 4,
    size: '201-500',
  },
  {
    id: 5,
    size: '501-1000',
  },
  {
    id: 6,
    size: '1001-5000',
  },
  {
    id: 7,
    size: '5001-10000',
  },
  {
    id: 8,
    size: '10000+',
  },
];
export const experiences_levels = [
  "Internship",
  "Entry level",
  "Associate",
  "Mid-Senior level",
  "Director",
  "Executive",
];
export const job_types = [
  "Full-time",
  "Part-time",
  "Contract",
  "Temporary",
  "Internship",
];
export const remote_types = ["On-site", "Remote", "Hybrid"];
export const ukStates = ["England", "Scotland", "Wales", "Northern Ireland"];
export const usStates = [
  { full: "Alabama", short: "AL" },
  { full: "Alaska", short: "AK" },
  { full: "Arizona", short: "AZ" },
  { full: "Arkansas", short: "AR" },
  { full: "California", short: "CA" },
  { full: "Colorado", short: "CO" },
  { full: "Connecticut", short: "CT" },
  { full: "Delaware", short: "DE" },
  { full: "Florida", short: "FL" },
  { full: "Georgia", short: "GA" },
  { full: "Hawaii", short: "HI" },
  { full: "Idaho", short: "ID" },
  { full: "Illinois", short: "IL" },
  { full: "Indiana", short: "IN" },
  { full: "Iowa", short: "IA" },
  { full: "Kansas", short: "KS" },
  { full: "Kentucky", short: "KY" },
  { full: "Louisiana", short: "LA" },
  { full: "Maine", short: "ME" },
  { full: "Maryland", short: "MD" },
  { full: "Massachusetts", short: "MA" },
  { full: "Michigan", short: "MI" },
  { full: "Minnesota", short: "MN" },
  { full: "Mississippi", short: "MS" },
  { full: "Missouri", short: "MO" },
  { full: "Montana", short: "MT" },
  { full: "Nebraska", short: "NE" },
  { full: "Nevada", short: "NV" },
  { full: "New Hampshire", short: "NH" },
  { full: "New Jersey", short: "NJ" },
  { full: "New Mexico", short: "NM" },
  { full: "New York", short: "NY" },
  { full: "North Carolina", short: "NC" },
  { full: "North Dakota", short: "ND" },
  { full: "Ohio", short: "OH" },
  { full: "Oklahoma", short: "OK" },
  { full: "Oregon", short: "OR" },
  { full: "Pennsylvania", short: "PA" },
  { full: "Rhode Island", short: "RI" },
  { full: "South Carolina", short: "SC" },
  { full: "South Dakota", short: "SD" },
  { full: "Tennessee", short: "TN" },
  { full: "Texas", short: "TX" },
  { full: "Utah", short: "UT" },
  { full: "Vermont", short: "VT" },
  { full: "Virginia", short: "VA" },
  { full: "Washington", short: "WA" },
  { full: "West Virginia", short: "WV" },
  { full: "Wisconsin", short: "WI" },
  { full: "Wyoming", short: "WY" }
];
// export const industries = [
//   {
//     id: 1,
//     industry: 'Software Development',
//   },
//   {
//     id: 2,
//     industry: 'IT Services and IT Consulting',
//   },
//   {
//     id: 3,
//     industry: 'Non-Profit Orgnanizations',
//   },
//   {
//     id: 4,
//     industry: 'Primary and Secondary Education',
//   },
//   {
//     id: 5,
//     industry: 'Internet Publishing',
//   },
//   {
//     id: 6,
//     industry: 'Technology, Information and Internet',
//   },
//   {
//     id: 7,
//     industry: 'Financial Services',
//   },
//   {
//     id: 8,
//     industry: 'Transformation, Logistics and Supply Chain',
//   },
//   {
//     id: 9,
//     industry: 'Human Resources Services',
//   },
//   {
//     id: 10,
//     industry: 'Information Services',
//   },
//   {
//     id: 11,
//     industry: 'Retail',
//   },
//   {
//     id: 12,
//     industry: 'IT System Custom Software Development',
//   },
//   {
//     id:13,
//     industry:'Market Research'
//   },
//   {
//     id: 14,
//     industry:'Technology, Information and Media'
//   }
// ];

export const industries = [
  'Accounting',
  'Airlines/Aviation',
  'Alternative Dispute Resolution',
  'Alternative Medicine',
  'Animation',
  'Apparel & Fashion',
  'Architecture & Planning',
  'Arts and Crafts',
  'Automotive',
  'Aviation & Aerospace',
  'Banking',
  'Biotechnology',
  'Broadcast Media',
  'Building Materials',
  'Business Supplies and Equipment',
  'Capital Markets',
  'Chemicals',
  'Civic & Social Organization',
  'Civil Engineering',
  'Commercial Real Estate',
  'Computer & Network Security',
  'Computer Games',
  'Computer Hardware',
  'Computer Networking',
  'Computer Software',
  'Construction',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Cosmetics',
  'Dairy',
  'Defense & Space',
  'Design',
  'Education Management',
  'E-Learning',
  'Electrical/Electronic Manufacturing',
  'Entertainment',
  'Environmental Services',
  'Events Services',
  'Executive Office',
  'Facilities Services',
  'Farming',
  'Financial Services',
  'Fine Art',
  'Fishery',
  'Food & Beverages',
  'Food Production',
  'Fund-Raising',
  'Furniture',
  'Gambling & Casinos',
  'Glass, Ceramics & Concrete',
  'Government Administration',
  'Government Relations',
  'Graphic Design',
  'Health, Wellness and Fitness',
  'Higher Education',
  'Hospital & Health Care',
  'Hospitality',
  'Import and Export',
  'Individual & Family Services',
  'Industrial Automation',
  'Information Services',
  'Information Technology and Services',
  'Insurance',
  'International Affairs',
  'International Trade and Development',
  'Internet',
  'Investment Banking',
  'Investment Management',
  'Judiciary',
  'Law Enforcement',
  'Law Practice',
  'Legal Services',
  'Legislative Office',
  'Leisure, Travel & Tourism',
  'Libraries',
  'Logistics and Supply Chain',
  'Luxury Goods & Jewelry',
  'Machinery',
  'Management Consulting',
  'Maritime',
  'Market Research',
  'Marketing and Advertising',
  'Mechanical or Industrial Engineering',
  'Media Production',
  'Medical Devices',
  'Medical Practice',
  'Mental Health Care',
  'Military',
  'Mining & Metals',
  'Motion Pictures and Film',
  'Museums and Institutions',
  'Music',
  'Nanotechnology',
  'Newspapers',
  'Non-Profit Organization Management',
  'Oil & Energy',
  'Online Media',
  'Outsourcing/Offshoring',
  'Package/Freight Delivery',
  'Packaging and Containers',
  'Paper & Forest Products',
  'Performing Arts',
  'Pharmaceuticals',
  'Philanthropy',
  'Photography',
  'Plastics',
  'Political Organization',
  'Primary/Secondary Education',
  'Printing',
  'Professional Training & Coaching',
  'Program Development',
  'Public Policy',
  'Public Relations and Communications',
  'Public Safety',
  'Publishing',
  'Railroad Manufacture',
  'Ranching',
  'Real Estate',
  'Recreational Facilities and Services',
  'Religious Institutions',
  'Renewables & Environment',
  'Research',
  'Restaurants',
  'Retail',
  'Security and Investigations',
  'Semiconductors',
  'Shipbuilding',
  'Sporting Goods',
  'Sports',
  'Staffing and Recruiting',
  'Supermarkets',
  'Telecommunications',
  'Textiles',
  'Think Tanks',
  'Tobacco',
  'Translation and Localization',
  'Transportation/Trucking/Railroad',
  'Utilities',
  'Venture Capital & Private Equity',
  'Veterinary',
  'Warehousing',
  'Wholesale',
  'Wine and Spirits',
  'Wireless',
  'Writing and Editing',
  'Software Development',
  'IT Services and IT Consulting',
  'Technology, Information and Internet',
  'Non-profit Organizations',
  'Advertising Services',
  'Human Resources Services',
  'Education Administration Programs',
  'Internet Publishing',
  'Computer Hardware Manufacturing',
  'Truck Transportation',
  'Transportation, Logistics, Supply Chain and Storage',
  'Hospitals and Health Care',
  'Oil and Gas',
  'Wellness and Fitness Services',
  'Food and Beverage Services',
  'Appliances, Electrical, and Electronics Manufacturing',
  'Travel Arrangements',
  'Armed Forces',
  'Retail Apparel and Fashion',
  'Wholesale Building Materials',
  'Broadcast Media Production and Distribution',
  'Chemical Manufacturing',
  'Personal Care Product Manufacturing',
  'Regenerative Design',
  'Design Services',
  'IT System Data Services',
  'Data Infrastructure and Analytics',
  'Outsourcing and Offshoring Consulting',
  'IT System Custom Software Development',
  'Sporting Goods Manufacturing',
  'Defense and Space Manufacturing',
  'Furniture and Home Furnishings Manufacturing',
  'Janitorial Services',
  'Landscaping Services',
  'Laundry and Drycleaning Services',
  'Loan Brokers',
  'Footwear and Leather Goods Repair',
  'Leasing Residential Real Estate',
  'Taxi and Limousine Services',
  'Newspaper Publishing',
  'Courts of Law',
  "Bars, Taverns, and Nightclubs",
  "Nanotechnology Research",
  "Nursing Homes and Residential Care Facilities",
  "Nonresidential Building Construction",
  "Abrasives and Nonmetallic Minerals Manufacturing",
  "Nonmetallic Mineral Mining",
  "Natural Gas Extraction",
  "Medical and Diagnostic Laboratories",
  "Motor Vehicle Manufacturing",
  "Aviation and Aerospace Component Manufacturing",
  "Legislative Offices",
  "Outpatient Care Centers",
  "Retail Groceries",
  "Railroad Equipment Manufacturing",
  "Recreational Facilities",
  "Consumer Services",
  "Textile Manufacturing",
  "Tobacco Manufacturing",
  "Think Tanks",
  "Freight and Package Transportation",
  "IT System Testing and Evaluation",
  "Space Research and Technology",
  "Media and Telecommunications",
  "Climate Technology Product Manufacturing",
  "Urban Transit Services",
  "Community Development and Urban Planning",
  "Utilities Administration",
  "Retail Recyclable Materials & Used Merchandise",
  "Utility System Construction",
  "Veterinary Services",
  "Audio and Video Equipment Manufacturing",
  "Alternative Fuel Vehicle Manufacturing",
  "Vocational Rehabilitation Services",
  "Metal Valve, Ball, and Roller Manufacturing",
  "Fruit and Vegetable Preserves Manufacturing",
  "Warehousing and Storage",
  "Wholesale Footwear",
  "Wholesale Petroleum and Petroleum Products",
  "Wholesale Chemical and Allied Products",
  "Wholesale Apparel and Sewing Supplies",
  "Wineries",
  "Air, Water, and Waste Program Management",
  "Wholesale Alcoholic Beverages",
  "Waste Treatment and Disposal",
  "Artists and Writers",
  "Zoos and Botanical Gardens",
  "Museums, Historical Sites, and Zoos"
];

export   const allServices = [
  {
    id:1,
    label: 'CV-Sourcing',
    minRange:1,
    maxRange:1000
  },
  {
    id:2,
    label: 'Pre-Qualification',
    minRange:1001,
    maxRange:2000
  },
  {
    id:3,
    label: '360/Direct',
    minRange:2001,
    maxRange:3000
  },
  {
    id:4,
    label: 'VA',
    minRange:3001,
    maxRange:4000
  },
  {
    id:5,
    label: 'Lead-Generation',
    minRange:4001,
    maxRange:5000
  },
  {
    id:6,
    label: 'IT and Consulting',
    minRange:5001,
    maxRange:6000
  },
];

export const companySize = ['1-10', '11-50', '51-200', '201-500', '501-1000', '1001-5000', '5001+'];

export const subscriptionTypes = [
  {
    id: 'monthly',
    label: 'Monthly',
  },
  {
    id: 'yearly',
    label: 'Annually',
  },
  {
    id: 'adhoc',
    label: 'Adhoc',
  },
];
