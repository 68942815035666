import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  IconButton,
  Typography,
  Box,
  Grid,
  Slide,
  List,
  ListItem,
  Divider,
  Tooltip,
} from '@mui/material';
import { Close, Add, Clear } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Get } from 'src/actions/API/apiActions';
import { useSnackbar } from 'notistack';
import { API_URLS } from 'src/constants/apiURLs';
import _ from 'lodash';
import { Icon } from '@iconify/react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});
const style = {
  p: 0,
  width: '100%',
  maxWidth: 455,
  borderRadius: 1,
  border: '1px solid',
  borderColor: '#E2DDDD',
  backgroundColor: '#E1F7FC',
};
// Styled TextField with focused outline color
const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#00C1FE',
    },
    '&:hover fieldset': {
      borderColor: '#00C1FE',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00C1FE',
    },
  },
  marginTop: theme.spacing(1),
}));

export default function AddReplacamentDialog({ open, onClose, onSave ,  person }) {
  console.log('person12345', person);

  const { enqueueSnackbar } = useSnackbar();
  const [searchString, setSearchString] = useState('');
  const [searchedResult, setSearchedResult] = useState(null);
  const [searchError, setSearchError] = useState('');
  const [emails, setEmails] = useState({});
  const [newEmails, setNewEmails] = useState({});

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      onClose();
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const debouncedFindEmail = useCallback(
    _.debounce((email) => {
      if (!validateEmail(email)) {
        setSearchError('Invalid email format');
        setSearchedResult(null);
        return;
      }
      setSearchError('');
      try {
        Get(
          {
            email_id: email, // pass the searchString into the payload
          },
          API_URLS.searchEmail,
          (resp) => {
            // console.log('1234', resp);
            if (resp?.data?.error) {
              setSearchedResult(null);
            } else {
              setSearchedResult(resp?.data);
            }
          },
          (error) => {
            console.log('error', error);
            enqueueSnackbar('Error in searching email', { variant: 'error' });
          }
        );
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    }, 300), // debounce delay in milliseconds
    [] // Add any dependencies if needed
  );

  useEffect(() => {
    if (searchString) {
      debouncedFindEmail(searchString); // pass the searchString into the debounced function
    }

    // Cleanup function to cancel debounce if necessary
    return debouncedFindEmail.cancel;
  }, [searchString, debouncedFindEmail]); // Dependency array ensures debounce is used properly

  // Function to format the date as "14 Aug, 2024"
  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  // filter the bounced business and personal emails from the person object  , bsuiness and perosnal emails is array 
  const businessEmails = person?.businessEmails?.filter((email) =>  email?.status === 'bounced');

    // Handle input change for the new emails
    const handleEmailChange = (type, index, value) => {
      setNewEmails((prev) => ({
        ...prev,
        [`${type}-${index}`]: value,
      }));
    };

    const handleAddEmails = () => {
      if (!searchString && !searchedResult) {
        enqueueSnackbar('Please search each  email before adding.', { variant: 'warning' });
        return;
      }

      const addedEmails = [];

      // Process business emails
      businessEmails?.forEach((email, index) => {
        console.log('email', email);
        const newEmail = newEmails[`business-${index}`];
        if (newEmail) {
          addedEmails.push({
            oldEmail: email.email_id,
            oldEmailId: email.id,
            oldEmaillIsDefault: email.is_default_email,
            user : email.user_id,
            newEmail,
          });
        }
      });

      if (addedEmails.length > 0) {
        setEmails([...addedEmails]);
        onSave(addedEmails);
        // enqueueSnackbar('Replaced emails added successfully!', { variant: 'success' });
        // onClose();
      } else {
        enqueueSnackbar('Please enter new emails before adding.', { variant: 'warning' });
      }
    };
    console.log("emails8973" , emails , newEmails)
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={false}
      PaperProps={{
        sx: {
          ml: 'auto',
          mt: 'auto',
          mr: '2rem',
          width: '1200px',
          height: '85%',
        },
      }}
      aria-describedby="alert-dialog-slide-description"
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: '#F4F9FD' }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4">Replace Emails </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <br />
        <Grid container spacing={2} sx={{ height: '100%', overflow: 'hidden' }}>
          {/* Search Bar Section */}
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <StyledTextField
              variant="outlined"
              placeholder="Search..."
              size="medium"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              error={!!searchError}
              helperText={searchError}
            />
            <br />
            {!searchedResult && searchString && !searchError && (
              <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" marginTop="2rem">
                <Icon icon="mdi:tick-circle-outline" style={{ fontSize: '150px', color: '#1FAF38' }} />
                <Typography variant="subtitle1" sx={{ color: '#1FAF38' }}>
                  Unique Email
                </Typography>
              </Box>
            )}
            {searchedResult && searchString && (
              <>
                <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" marginTop="2rem">
                  <Icon icon="radix-icons:cross-circled" style={{ fontSize: '150px', color: '#FA2F2F' }} />
                  <Typography variant="subtitle1" sx={{ color: '#FA2F2F' }}>
                    Already Exist
                  </Typography>
                </Box>
                <br />
                <List style={style}>
                  <ListItem>
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <Typography style={{ fontWeight: 'bold' }}>Person Name:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <a
                          href={searchedResult?.person?.profile_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: '#1976d2', textDecoration: 'none', fontWeight: 'initial' }}
                        >
                          {searchedResult?.person?.full_name}
                        </a>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider component="li" />

                  <ListItem>
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <Typography style={{ fontWeight: 'bold' }}>Company:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <a
                          href={searchedResult?.company?.profile_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: '#1976d2', textDecoration: 'none', fontWeight: 'initial' }}
                        >
                          {searchedResult?.company?.name}
                        </a>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider component="li" />

                  <ListItem>
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <Typography style={{ fontWeight: 'bold' }}>Lead Expert:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <span style={{ color: '#818283', fontWeight: 'initial' }}>
                          {searchedResult?.user?.first_name} {searchedResult?.user?.last_name}
                        </span>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider component="li" />

                  <ListItem>
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <Typography style={{ fontWeight: 'bold' }}>Date:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <span style={{ color: '#818283', fontWeight: 'initial' }}>
                          {formatDate(searchedResult?.email?.createdAt)}
                        </span>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider component="li" />

                  <ListItem>
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <Typography style={{ fontWeight: 'bold' }}>Email:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <span style={{ color: '#818283', fontWeight: 'initial' }}>
                          {searchedResult?.email?.email_id}
                        </span>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider component="li" />

                  <ListItem>
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <Typography style={{ fontWeight: 'bold' }}>Status:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <span
                          style={{
                            backgroundColor: searchedResult?.email?.status === 'verified' ? '#b3ffb3' : '#FFC9C9',
                            color: searchedResult?.email?.status === 'verified' ? 'green' : 'red',
                            padding: '8px 10px',
                            fontWeight: 'bold',
                          }}
                        >
                          {searchedResult?.email?.status === 'verified'
                            ? 'Verified'
                            : searchedResult?.email?.status
                            ? 'bounced'
                            : 'Bounced'}
                        </span>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </>
            )}
          </Grid>

          {/* Email Fields Section */}
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            sx={{
              borderLeft: '3px solid #E2DDDD',
              marginLeft: '1rem',
            }}
          >
            {/*  map the business emails in the text field with the business email rendered and for each render a text field as well */}
            {businessEmails?.map((email, index) => (
              <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ fontWeight: 'bold' }}>Bounced {email?.type} Email</label>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <TextField
                    variant="outlined"
                    size="medium"
                    value={email.email_id}
                    disabled
                    sx={{
                      border: '1px solid red',
                      marginTop: '5px',
                      borderRadius: '5px',
                      width: '100%',
                    }}
                  />
                </Box>

                <StyledTextField
                  variant="outlined"
                  placeholder="Enter new email"
                  size="medium"
                  value={newEmails[`business-${index}`] || ''}
                  onChange={(e) => handleEmailChange('business', index, e.target.value)}
                />
              </div>
            ))}
            <br />
            <br />
            <Box mt={2} display="flex" justifyContent="flex-start" marginLeft="2.5rem">
              <Button
                color="primary"
                sx={{
                  width: '180px',
                  height: '45px',
                  border: '2px solid #00C1FE',
                  borderRadius: '5px',
                  color: '#ffffff',
                  backgroundColor: '#00C1FE',
                  '&:hover': {
                    backgroundColor: '#00C1FE',
                  },
                  marginTop: '1rem',
                }}
                disabled={searchedResult}
                onClick={handleAddEmails}
              >
                Add replaced emails
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
