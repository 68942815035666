import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Typography,
  Box,
  Grow,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

export default function AssociateJobPostWithPerson({ open, onClose, company }) {
  const jobPostValues = JSON.parse(localStorage.getItem('jobPost'));
  const postingLink = localStorage.getItem('postingLink');
  console.log('fdsfdsfd', jobPostValues, postingLink);
  const userId = 1;
  const addJobPostWithCompany = () => {
    if (!jobPostValues || !postingLink) {
      enqueueSnackbar('Please add job post first from the add button given near the search bar', {
        variant: 'warning',
      });
      return;
    }

    try {
      // jobPostValues.user_id = userId;

      const company_id = company?.id;
      const user_id = userId;
      const job_posting_link = postingLink;
      const job_post_date = jobPostValues?.job_post_date;
      const job_title = jobPostValues?.job_title;
      const expereince_level = jobPostValues?.expereince_level;
      const job_type = jobPostValues?.job_type;
      const remote = jobPostValues?.remote;
      const salary = jobPostValues?.salary;
      const city = jobPostValues?.city;
      const state = jobPostValues?.state;
      const applicants = jobPostValues?.applicants;
      const industry = jobPostValues?.industry;
      const SR_specified_industry = jobPostValues?.SR_specified_industry;
      const country_id = jobPostValues?.country_id;
      const sector_id = jobPostValues?.sector_id;

      const payload = {
        company_id,
        user_id,
        job_posting_link,
        job_post_date,
        job_title,
        expereince_level,
        job_type,
        remote,
        salary,
        city,
        state,
        applicants,
        industry,
        SR_specified_industry,
        country_id,
        sector_id,
      };
      Post(
        payload,
        API_URLS.addJobPostWithCompanyId,
        (resp) => {
          console.log(resp);
          // notify('Job post added', 'success', 1000);
          localStorage.removeItem('jobPost');
          localStorage.removeItem('postingLink');
          enqueueSnackbar('Job post associated with the company', { variant: 'success' });
          onClose();
        },
        (error) => {
          console.log(error);
          const errorMessage = error.response?.data?.message || 'Error in associating job post with the company';
          enqueueSnackbar(errorMessage, { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Failed to associate job post with the company', { variant: 'error' });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '80%',
          minHeight: '20vh',
        },
      }}
      aria-describedby="alert-dialog-slide-description"
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
        },
      }}
    >
      <DialogTitle>
        <Box display="flex">
          <Typography variant="h4" className="ml-8 text-center ">
            Associate Job Post to Company
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" className="mt-8">
          Are you sure about associating the recently added job post with this company?
        </Typography>
        <div className="flex justify-around flex-wrap mt-12">
          <Button
            onClick={onClose}
            style={{
              border: '2px solid black',
              borderRadius: '10px',
              width: '100px',
              padding: '8px 16px', // Adjust padding as needed
              textAlign: 'center',
            }}
          >
            No
          </Button>
          <Button
            onClick={() => addJobPostWithCompany()}
            style={{
              backgroundColor: '#FA8282',
              color: 'white',
              borderRadius: '10px',
              width: '100px',
              padding: '8px 16px', // Adjust padding as needed
              textAlign: 'center',
            }}
          >
            Yes
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
