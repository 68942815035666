export default function ShowPersonResults({ person, company }) {
  return (
    <div key={person.id} className="my-2 p-2  rounded-md border border-blue-300 bg-[#E1F7FC] h-24 ml-1">
      <p>
        <b className="text-[#00C1FE] text-xl curson-pointer" onClick={()=>{
          //  open the person profile_url 
          window.open(person.profile_url, '_blank');
        }}>
          {person.first_name} {person?.last_name}
        </b>
        <br />
        <p className="text-xl">{person.title}</p>

        <p className="text-lg" onClick={()=>{
          window.open(company?.profile_url, '_blank');
        }}>Company - {company?.name}</p>
      </p>
    </div>
  );
}
