import { Button, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { makeStyles } from '@mui/styles';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { Get, Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { EditDisclaimer, EditSignatureDialog } from '.';

const SignatureSection = () => {
  const [openEditDisclaimerDialog, setOpenEditDisclaimerDialog] = useState(false);
  const [openEditSignatureDialog, setOpenEditSignatureDialog] = useState(null);
  const [bdUser, setBdUser] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState([]);

  const getRoleBasedUsers = () => {
    try {
      Get(
        { role: 'bdExpert' },
        API_URLS.getRoleBasedUsers,
        (response) => {
          console.log('bdusers', response.data);
          let data = response.data?.roleBaseUsers;
          data = data.map((row) => {
            row.full_name = `${row.first_name} ${row.last_name}`;
            return row;
          });
          setUsers(data);
        },
        (error) => {
          console.log('error', error);
        }
      );
    } catch {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const handleUpdateUser = (values, actions) => {
    try {
      Post(
        values,
        API_URLS.updateUser,
        (resp) => {
          actions.setSubmitting(false);
          enqueueSnackbar('Signatures Successfully', { variant: 'success' });
          // getRoleBasedUsers();
          setOpenEditSignatureDialog(false);
          getRoleBasedUsers()
        },
        (error) => {
          enqueueSnackbar('Signatures not Updated', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };
  const columns = [
    {
      accessorKey: 'full_name',
      header: 'Full Name',
      enableClickToCopy: true,
    },
    {
      accessorKey: 'email',
      header: 'Email',
      enableClickToCopy: true,
    },
    {
      accessorKey: 'designation',
      header: 'Designation',
      enableClickToCopy: true,
    },
  ];

  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTableCell-root': {
        borderBottom: '2px solid black', // Customize the border color and thickness
      },
    },
  }));

  useEffect(() => {
    getRoleBasedUsers();
  }, []);

  return (
    <>
      <Button
        className=" px-4 py-3 my-8 text-white bg-[#00C1FE] hover:bg-[#00C1FE] border-0 text-lg items-center justify-center rounded-md"
        onClick={() => setOpenEditDisclaimerDialog(true)}
      >
        Edit Disclaimer
      </Button>

      <MaterialReactTable
        // className={classes.root}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 50,
          },
        }}
        columns={columns}
        data={users}
        defaultColumn={{
          size: 200, //make columns wider by default
        }}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableSorting={false}
        enableTopToolbar={false}
        positionToolbarAlertBanner="bottom"
        manualFiltering
        actionsColumnIndex={-1}
        enableRowActions
        renderRowActions={({ row, table }) => (
          <div className="flex justify-center items-center  ">
            <Button
              className="text-[#00C1FE] text-sm bg-[#e1f7fc]"
              onClick={() => {
                setBdUser(row?.original);
                setOpenEditSignatureDialog(true);
              }}
            >
              <Tooltip title="Edit Signatures">
                <Icon
                  icon="material-symbols-light:edit-outline"
                  width="1rem"
                  height="1rem"
                  style={{ color: '#00C1FE' }}
                />
              </Tooltip>
              Edit
            </Button>
          </div>
        )}
      />

      <EditDisclaimer open={openEditDisclaimerDialog} onClose={() => setOpenEditDisclaimerDialog(false)} />

      {openEditSignatureDialog && (
        <EditSignatureDialog
          open={openEditSignatureDialog}
          onClose={() => setOpenEditSignatureDialog(false)}
          updateUser={bdUser}
          onSubmit={(values, actions) => {
            handleUpdateUser(values, actions);
          }}
        />
      )}
    </>
  );
};

export default SignatureSection;
