import { Icon } from '@iconify/react';
import { Box, Card, Typography } from '@mui/material';
import React from 'react';

function StatsCard({
  mainLabel,
  leftLabel,
  rightLabel,
  mainNumber,
  leftNumber,
  rightNumber,
  onLeftClick,
  onRightClick,
  onMainClick,
}) {
  return (
    <Card
      sx={{
        py: 3,
        boxShadow: 0,
        display: 'flex',
        // width: '350px',
        flexDirection: 'column',
        textAlign: 'center',
        backgroundColor: 'white',
        borderRadius: '12px',
        border: '1px solid #EFEFF3',
        marginLeft: '5px',
        padding: '20px',
      }}
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box display="flex" flexDirection="column" alignItems="center" mx={1} justifyContent="center" width="90px">
          <Typography variant="body" sx={{ fontSize: '12px', mb: 1, fontWeight: 'bold' }}>
            {mainLabel}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: '13px', fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => {
              onMainClick();
            }}
          >
            {mainNumber}
          </Typography>
        </Box>

        <Icon icon="pepicons-pop:equal" style={{ fontSize: '18px', marginTop: '18px' }} />
        <Box display="flex" flexDirection="column" alignItems="center" mx={1} justifyContent="center" width="90px">
          <Typography variant="body" sx={{ fontSize: '12px', mb: 1, fontWeight: 'bold' }}>
            {leftLabel}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: '13px', fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => {
              onLeftClick();
            }}
          >
            {leftNumber}
          </Typography>
        </Box>
        <Icon icon="tabler:plus" style={{ fontSize: '18px', marginTop: '18px' }} />

        <Box display="flex" flexDirection="column" alignItems="center" mx={1} justifyContent="center" width="120px">
          <Typography variant="body" sx={{ fontSize: '12px', mb: 1, fontWeight: 'bold' }}>
            {rightLabel}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: '13px', fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => {
              onRightClick();
            }}
          >
            {rightNumber}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

export default StatsCard;
