import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  MenuItem,
  Pagination,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import CandidateCard from './CandidateCard';
import { useCallback, useEffect, useState } from 'react';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { CloseOutlined, Refresh } from '@mui/icons-material';
import MultiDatePicker from 'src/components/MultiDatePicker';

const CandidateList = () => {
  const [candidates, setCandidates] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [search, setSearch] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [dataLevel, setDataLevel] = useState(null);

  const [dateRange, setDateRange] = useState([]);

  const handlePageChange = (event, value) => {
    setPage(parseInt(value) - 1);
  };

  const [loading, setLoading] = useState(false);

  const handleSizeChange = (event) => {
    setPage(0);
    setSize(parseInt(event.target.value));
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const getAllCandidates = useCallback(() => {
    try {
      setLoading(true);
      Get(
        {
          page: page,
          size: size,
          search: search,
          search_level: dataLevel,
          startDate: dateRange[0],
          endDate: dateRange[1],
        },
        API_URLS.getAllCandidates,
        (resp) => {
          setCandidates(resp?.data?.candidates);
          setTotalCount(resp?.data?.totalItems);
          setTotalPages(resp?.data?.totalPages);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          console.log(error);
        }
      );
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [page, size, search, dataLevel, dateRange]);

  useEffect(() => {
    getAllCandidates(); // Trigger API call immediately when page, size, or search changes

    // const interval = setInterval(() => {
    //   getAllCandidates();
    // }, 60000); // Refresh data every 60 seconds

    // return () => {
    //   clearInterval(interval); // Clear the interval on component unmount or when dependencies change
    // };
  }, [getAllCandidates]);

  const titles = ['Chef', 'Book Keeper', 'Driver', "accountant"];
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '1rem',
          gap: '1rem',
        }}
      >
        <Refresh onClick={getAllCandidates} style={{ cursor: 'pointer' }} />
        <h2>Total Candidates: {totalCount}</h2>
        <Pagination count={totalPages} page={page + 1} onChange={handlePageChange} variant="outlined" shape="rounded" />
        <TextField select value={size} onChange={handleSizeChange} variant="outlined" size="small">
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={40}>40</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={50}>100</MenuItem>
        </TextField>
        {/* Add Text field to go to specific page */}
        <Typography variant="h6">Go to Page</Typography>
        <TextField
          // label="Page"
          type="number"
          variant="outlined"
          size="small"
          value={page + 1}
          onChange={(e) => {
            if (e.target.value === '') {
              setPage(0);
            } else {
              setPage(parseInt(e.target.value) - 1);
            }
          }}
          style={{ width: '100px' }}
        />

        {/* add clear all filters button */}

        {/* add filter button */}
        <Button
          variant="outlined"
          onClick={() => {
            setSearch('');
            setDataLevel(null);
            setDateRange([]);
          }}
        >
          <CloseOutlined />
          Clear Filters
        </Button>
      </div>

      <Card style={{
        width: '95%',
        margin: 'auto',
      }}>
        <CardContent>
          Records
        </CardContent>
      </Card>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10,
          margin: '20px',
        }}
      >
        <TextField
          label="Search"
          fullWidth
          variant="outlined"
          size="small"
          value={search}
          onChange={handleSearchChange}
          // style={{ marginBottom: '1rem' }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
          }}
        >
          <Autocomplete
            id="combo-box-demo"
            size="large"
            options={titles}
            getOptionLabel={(option) => option}
            onChange={(event, value) => setSearch(value)}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Title" variant="outlined" />}
          />
          <MultiDatePicker height={55} onChange={(dates) => setDateRange(dates)} />
          <ToggleButtonGroup
            value={dataLevel}
            size="large"
            exclusive
            style={{
              width: 300,
              height: 55,
              // paddingRight: '30px',
              // marginRight: '10px',
              border: '1px solid #00C1FE',
              outline: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: '5px',
              backgroundColor: '#ECF6FE',
              color: '#00C1FE',
              boxSizing: 'border-box',
              // paddingLeft: '10px',
            }}
            onChange={(event, value) => {
              setDataLevel(value);
            }}
          >
            <ToggleButton
              value={1}
              fullWidth
              style={{
                borderRight: '1px solid #ccc',
                borderTopLeftRadius: '5px',
                borderBottomLeftRadius: '5px',
                background: dataLevel === 1 ? '#00C1FE' : 'white',
                color: dataLevel === 1 ? 'white' : 'black',
              }}
            >
              Basic
            </ToggleButton>
            <ToggleButton
              value={2}
              fullWidth
              style={{
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '5px',
                background: dataLevel === 2 ? '#00C1FE' : 'white',
                color: dataLevel === 2 ? 'white' : 'black',
              }}
            >
              Advance
            </ToggleButton>
          </ToggleButtonGroup>

          {/* add autocomplete for title */}
        </Box>
      </div>

      {loading ? (
        <CircularProgress
          thickness={15}
          style={{
            display: 'block',
            margin: 'auto',
            marginTop: '20%',
            background: 'gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
          }}
        />
      ) : (
        <Grid
          container
          spacing={1}
          style={{
            margin: 'auto',
          }}
        >
          {candidates?.map((candidate) => (
            <Grid item xs={12} md={6} key={candidate?.id}>
              <CandidateCard candidate={candidate} onCandidateClick={(level) => setDataLevel(level)} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default CandidateList;
