import React, { useContext, useEffect, useState } from 'react';
import { TextField, Grid, Autocomplete, Checkbox, FormControlLabel, FormGroup, Box } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { JobPostLinkContext } from '../LeadGenerationSection';
import { API_URLS } from 'src/constants/apiURLs';
import { Get } from 'src/actions/API/apiActions';
import { makeStyles } from '@mui/styles';
import { industries, experiences_levels, job_types, remote_types, ukStates, usStates } from 'src/constants/constants';
import parse from 'autosuggest-highlight/parse';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    '&$checked': {
      color: '#00C1FE', // color of the checkbox when checked
    },
  },
  checked: {},
  label: {
    color: '#000', // color of the label text
  },
}));

function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

const JobPostForm = ({ onSubmit, onUpdate, isError,postingLink}) => {
  const [countries, setCountries] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  // const leadSources = ['LinkedIn', 'Indeed'];
  // const jobPostingLink = useContext(JobPostLinkContext);
  const [options, setOptions] = React.useState(industries);
  // const [selectedDirectIndustry, setSelectedDirectIndustry] = useState('');
  const [open, setOpen] = React.useState(false);
  const loading = open && options.length === 0;

  //states for testing the search as you type autocomplete
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  const [stateOptions, setStateOptions] = useState([]);
 const [formData, setFormData]=useState()
  //  console.log('countries',countries)
console.log('formData',formData)
  const initialValues = {
    // job_posting_link: postingLink ? postingLink : '',
    job_posting_link: postingLink || '',
    job_post_date: '',
    job_title: '',
    expereince_level: '',
    job_type: '',
    remote: '',
    salary: '',
    city: '',
    state: '',
    applicants: '',
    industry: '',
    SR_specified_industry: '',
    person_id: '1',
    company_id:'1',
    user_id: '1',
    country_id: '',
    sector_id: '',
  };

  const classes = useStyles();
  React.useEffect(() => {
    if (inputValue === '') {
      setOptions(industries);
      return;
    }

    const filteredOptions = industries.filter((option) => option.toLowerCase().includes(inputValue.toLowerCase()));

    setOptions(filteredOptions);
  }, [inputValue]);

  const ValidationSchema = Yup.object().shape({
    job_title: Yup.string().required('Job Title is required'),
    job_posting_link: Yup.string()
      .required('Job Posting URL is required')
      .url('Enter Valid URL')
      .test('notEmpty', 'Posting link is required', (value) => value.trim() !== ''),
    job_post_date: Yup.string().required('Job Post Date is required'),

    country_id: Yup.number().required('Country is required'),
    sector_id: Yup.number().required('Sector is required'),
  });

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        setOptions([...industries]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const getAllCountries = () => {
    try {
      Get(
        {},
        API_URLS.getAllCountries,
        (response) => {
          setCountries(response.data);
        },
        (error) => {
          console.log('error', error);
        }
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getAllCountries();
  }, []);

  const getAllSectors = () => {
    try {
      Get(
        {},
        API_URLS.getAllSectors,
        (response) => {
          setSectors(response.data);
        },
        (error) => {
          console.log('error', error);
        }
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getAllSectors();
  }, []);

  const handleIndustryChange = (industry) => {
    setSelectedIndustry(industry);
  };

  const renderSRSectorOptions = (setFieldValue) => (
    <>
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkbox}
                classes={{
                  checked: classes.checked,
                }}
                checked={selectedIndustry === 'Staffing and Recruiting'}
                onChange={() => {
                  handleIndustryChange('Staffing and Recruiting');
                  setFieldValue('industry', 'Staffing and Recruiting');
                  onUpdate({ industry: 'Staffing and Recruiting' });
                }}
              />
            }
            label="Staffing and Recruiting"
            className={classes.label}
          />
          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkbox}
                classes={{
                  checked: classes.checked,
                }}
                checked={selectedIndustry === 'Human Resources Services'}
                onChange={() => {
                  handleIndustryChange('Human Resources Services');
                  setFieldValue('industry', 'Human Resources Services');
                  onUpdate({ industry: 'Human Resources Services' });
                }}
              />
            }
            label="Human Resources Services"
            className={classes.label}
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          options={industries}
          getOptionLabel={(option) => option}
          onChange={(event, value) => {
            setFieldValue('SR_specified_industry', value);
            onUpdate({ SR_specified_industry: value });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select S&R Industry"
              fullWidth
              sx={{
                backgroundColor: 'white',
                borderRadius: '0.3rem',
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#00C1FE',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#00C1FE',
                  },
                },
                '& .MuiInputBase-root': {
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 1px #00C1FE',
                  },
                },
              }}
            />
          )}
        />
      </Grid>
    </>
  );

  const renderDirectSectorOptions = (setFieldValue) => (
    <Grid item xs={12}>
      <FormGroup row>
        {industries?.slice(0, 8).map((option) => (
          <Grid item xs={6} key={option}>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checkbox}
                  classes={{
                    checked: classes.checked,
                  }}
                  checked={selectedIndustry === option}
                  onChange={() => {
                    handleIndustryChange(option);
                    setFieldValue('industry', option);
                    onUpdate({ industry: option });
                  }}
                />
              }
              label={option}
              className={classes.label}
            />
          </Grid>
        ))}
        {industries?.length > 8 && (
          <Grid item xs={12}>
            <Autocomplete
              id="direct-industry-dropdown"
              getOptionLabel={(option) => option}
              filterOptions={(x) => x}
              options={options}
              autoComplete
              includeInputInList
              filterSelectedOptions
              noOptionsText="No industries"
              onChange={(event, newValue) => {
                handleIndustryChange(newValue);
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                setFieldValue('industry', newValue);
                onUpdate({ industry: newValue });
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                setSelectedIndustry(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Industry"
                  fullWidth
                  InputLabelProps={{ classes: { root: classes.label } }}
                />
              )}
              renderOption={(props, option) => {
                const matches = [];
                const parts = parse(
                  option,
                  matches.map((match) => [match.offset, match.offset + match.length])
                );

                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                        {parts.map((part, index) => (
                          <Box key={index} component="span" sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                            {part.text}
                          </Box>
                        ))}
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            />
          </Grid>
        )}
      </FormGroup>
    </Grid>
  );

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        validate={(values) => {
          const errors = {};
          try {
            ValidationSchema.validateSync(values, { abortEarly: false });
          } catch (validationError) {
            validationError.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          }
          isError(Object.keys(errors).length > 0);
          return errors;
        }}
        onSubmit={(values, actions) => {
          onSubmit(values, actions);
          setFormData(values)
        }}
      >
        {({ values, errors, touched, handleBlur, setFieldValue, setValues }) => (
          <Form>

          {console.log('errors', errors)}
            {console.log('valuesofjob', values)}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label htmlFor="job_title" style={{ fontWeight: 'bold' }}>
                  Job Title
                </label>
                <TextField
                  id="job_title"
                  name="job_title"
                  placeholder="Enter Job Job Title"
                  value={values.job_title}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue('job_title', e.target.value);
                    onUpdate({ job_title: e.target.value });
                  }}
                  error={touched.job_title && Boolean(errors.job_title)}
                  helperText={touched.job_title && errors.job_title}
                  fullWidth
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '0.3rem',
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#00C1FE',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#00C1FE',
                      },
                    },
                    '& .MuiInputBase-root': {
                      '&.Mui-focused': {
                        boxShadow: '0 0 0 1px #00C1FE',
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <label htmlFor="job_posting_link" style={{ fontWeight: 'bold' }}>
                  Job Posting Link
                </label>
                <TextField
                  id="job_posting_link"
                  name="job_posting_link"
                  placeholder="Enter Job Posting Link"
                  // value={values.job_posting_link}
                  value={values.job_posting_link || (postingLink ? postingLink : '')}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue('job_posting_link', e.target.value);
                    onUpdate({ job_posting_link: e.target.value });
                  }}
                  error={touched.job_posting_link && Boolean(errors.job_posting_link)}
                  helperText={touched.job_posting_link && errors.job_posting_link}
                  fullWidth
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '0.3rem',
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#00C1FE',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#00C1FE',
                      },
                    },
                    '& .MuiInputBase-root': {
                      '&.Mui-focused': {
                        boxShadow: '0 0 0 1px #00C1FE',
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <label htmlFor="job_post_date" style={{ fontWeight: 'bold' }}>
                  Job Posting Date
                </label>
                <TextField
                  type="date"
                  id="job_post_date"
                  name="job_post_date"
                  placeholder="Enter Job Posting Date"
                  value={values.job_post_date}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue('job_post_date', e.target.value);
                    onUpdate({ job_post_date: e.target.value });
                  }}
                  error={touched.job_post_date && Boolean(errors.job_post_date)}
                  helperText={touched.job_post_date && errors.job_post_date}
                  fullWidth
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '0.3rem',
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#00C1FE',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#00C1FE',
                      },
                    },
                    '& .MuiInputBase-root': {
                      '&.Mui-focused': {
                        boxShadow: '0 0 0 1px #00C1FE',
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <label htmlFor="experiences_levels" style={{ fontWeight: 'bold' }}>
                  Expereince Level
                </label>
                <Autocomplete
                  options={experiences_levels}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => {
                    setFieldValue('expereince_level', value || null);
                    onUpdate({ expereince_level: value || null });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Expereince Level"
                      onBlur={handleBlur}
                      error={touched.expereince_level && Boolean(errors.expereince_level)}
                      helperText={touched.expereince_level && errors.expereince_level}
                      fullWidth
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <label htmlFor="job_type" style={{ fontWeight: 'bold' }}>
                  Job Type
                </label>
                <Autocomplete
                  options={job_types}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => {
                    setFieldValue('job_type', value || null);
                    onUpdate({ job_type: value || null });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Job Type"
                      onBlur={handleBlur}
                      error={touched.job_type && Boolean(errors.job_type)}
                      helperText={touched.job_type && errors.job_type}
                      fullWidth
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="remote" style={{ fontWeight: 'bold' }}>
                  Remote
                </label>
                <Autocomplete
                  options={remote_types}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => {
                    setFieldValue('remote', value || null);
                    onUpdate({ remote: value || null });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Remote Type"
                      onBlur={handleBlur}
                      error={touched.remote && Boolean(errors.remote)}
                      helperText={touched.remote && errors.remote}
                      fullWidth
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="salary" style={{ fontWeight: 'bold' }}>
                  Salary
                </label>
                <TextField
                  id="salary"
                  name="salary"
                  placeholder="Enter salary"
                  value={values.salary}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue('salary', e.target.value);
                    onUpdate({ salary: e.target.value });
                  }}
                  error={touched.salary && Boolean(errors.salary)}
                  helperText={touched.salary && errors.salary}
                  fullWidth
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '0.3rem',
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#00C1FE',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#00C1FE',
                      },
                    },
                    '& .MuiInputBase-root': {
                      '&.Mui-focused': {
                        boxShadow: '0 0 0 1px #00C1FE',
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="city" style={{ fontWeight: 'bold' }}>
                  City
                </label>
                <TextField
                  id="city"
                  name="city"
                  placeholder="Enter city"
                  value={values.city}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue('city', e.target.value);
                    onUpdate({ city: e.target.value });
                  }}
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                  fullWidth
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '0.3rem',
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#00C1FE',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#00C1FE',
                      },
                    },
                    '& .MuiInputBase-root': {
                      '&.Mui-focused': {
                        boxShadow: '0 0 0 1px #00C1FE',
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="applicants" style={{ fontWeight: 'bold' }}>
                  Applicants
                </label>
                <TextField
                  id="applicants"
                  name="applicants"
                  placeholder="Enter Applicants"
                  value={values.applicants}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue('applicants', e.target.value);
                    onUpdate({ applicants: e.target.value });
                  }}
                  error={touched.applicants && Boolean(errors.applicants)}
                  helperText={touched.applicants && errors.applicants}
                  fullWidth
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '0.3rem',
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#00C1FE',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#00C1FE',
                      },
                    },
                    '& .MuiInputBase-root': {
                      '&.Mui-focused': {
                        boxShadow: '0 0 0 1px #00C1FE',
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
  <label htmlFor="country_id" style={{ fontWeight: 'bold' }}>
    Country
  </label>
  <Autocomplete
    options={countries}
    getOptionLabel={(option) => option.country} // Adjust based on your backend response
    onChange={(event, value) => {
      console.log('Selected country:', value); // Log the selected country
      setFieldValue('country_id', value ? value.id : '');
      onUpdate({ country_id: value ? value.id : '' }); // Ensure country_id is updated
      if (value?.id === 1) {
        setStateOptions(ukStates);
      } else if (value?.id === 2) {
        setStateOptions(usStates.map((state) => state.full));
      } else {
        setStateOptions([]);
      }
      setFieldValue('state', ''); // Clear the state field when the country changes
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        placeholder="Select Country"
        onBlur={handleBlur}
        error={touched.country_id && Boolean(errors.country_id)}
        helperText={touched.country_id && errors.country_id}
        fullWidth
        sx={{
          backgroundColor: 'white',
          borderRadius: '0.3rem',
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: '#00C1FE',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#00C1FE',
            },
          },
          '& .MuiInputBase-root': {
            '&.Mui-focused': {
              boxShadow: '0 0 0 1px #00C1FE',
            },
          },
        }}
      />
    )}
  />
</Grid>
{values.country_id && stateOptions.length > 0 && (
  <Grid item xs={12}>
    <label htmlFor="state" style={{ fontWeight: 'bold' }}>
      State
    </label>
    <Autocomplete
      options={stateOptions}
      getOptionLabel={(option) => option}
      onChange={(event, value) => {
        console.log('Selected state:', value); // Log the selected state
        setFieldValue('state', value);
        onUpdate({ state: value }); // Update state separately
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select State"
          onBlur={handleBlur}
          error={touched.state && Boolean(errors.state)}
          helperText={touched.state && errors.state}
          fullWidth
          sx={{
            backgroundColor: 'white',
            borderRadius: '0.3rem',
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: '#00C1FE',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#00C1FE',
              },
            },
            '& .MuiInputBase-root': {
              '&.Mui-focused': {
                boxShadow: '0 0 0 1px #00C1FE',
              },
            },
          }}
        />
      )}
    />
  </Grid>
)}


              <Grid item xs={12}>
                <label htmlFor="sector_id" style={{ fontWeight: 'bold' }}>
                  Sector
                </label>
                <Autocomplete
                  options={sectors}
                  getOptionLabel={(option) => option.sector}
                  onChange={(event, value) => {
                    setFieldValue('sector_id', value ? value.id : null);
                    setFieldValue('industry', '');
                    setFieldValue('SR_specified_industry', '');
                    setSelectedSector(value);
                    onUpdate({ sector_id: value ? value.id : null });
                    // onUpdate({ industry: '' });
                    // onUpdate({ SRSpecifiedIndustry: '' });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Sector"
                      onBlur={handleBlur}
                      error={touched.sector_id && Boolean(errors.sector_id)}
                      helperText={touched.sector_id && errors.sector_id}
                      fullWidth
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              {selectedSector?.sector === 'S&R'
                ? renderSRSectorOptions(setFieldValue)
                : selectedSector?.sector === 'Direct'
                ? renderDirectSectorOptions(setFieldValue)
                : null}
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default JobPostForm;
