import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import React, { useState, useEffect, useRef } from 'react';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const [openIndex, setOpenIndex] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenIndex(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleItemClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Box {...other} ref={ref}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item, index) => (
          <NavItem
            key={item.title}
            item={item}
            isOpen={openIndex === index}
            onItemClick={() => handleItemClick(index)}
          />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  isOpen: PropTypes.bool,
  onItemClick: PropTypes.func,
};

function NavItem({ item, isOpen, onItemClick }) {
  const { title, path, icon, children } = item;

  return (
    <>
      <StyledNavItem
        component={RouterLink}
        to={path || '#'}
        onClick={children ? onItemClick : undefined}
        sx={{
          '&.active': {
            color: '#0EC4FE',
            bgcolor: 'action.selected',
            fontWeight: 'fontWeightBold',
          },
        }}
        aria-expanded={children ? isOpen : undefined}
        aria-controls={children ? `collapse-${title}` : undefined}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={title} />
        {children && (isOpen ? <ExpandLess /> : <ExpandMore />)}
      </StyledNavItem>

      {children && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List disablePadding>
            {children.map((child) => (
              <NavItem key={child.title} item={child} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}
