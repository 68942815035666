import { Box, Button } from '@mui/material';
import React from 'react';
import Spreadsheet from 'react-spreadsheet';

function SpreadSheetComponent({ persons }) {
  console.log("persons", persons);

  // Prepare the data for the spreadsheet
  const data = persons.map((person) => [
    { value: person.full_name || '' },
    { value: person.company ? person.company.name : '' },
    { value: person.company ? person.company.website : '' },
    { value: person.profile_url || '' },
    { value: '' },
    { value: '' },
    { value: '' },
    { value: '' },
  ]);

  // Define column labels
  const columnLabels = [
    'Name',
    'Company',
    'Website Link',
    'LI Link',
    'Personal Email',
    'Business Email',
    'Personal Number',
    'Business Number',
  ];

  return (
    <div>
      <div style={{ margin: '1rem', width: '95%' }}>
        <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
          <Button
            color="primary"
            sx={{
              width: '150px',
              height: '50px',
              border: '2px solid #00C1FE',
              borderRadius: '10px',
              color: '#ffffff',
              backgroundColor: '#00C1FE',
              '&:hover': {
                backgroundColor: '#00C1FE',
              },
              marginRight: '10px', // Add margin between the last button and this button
            }}
          >
            Submit as verified
          </Button>
        </Box>
        <Spreadsheet data={data} columnLabels={columnLabels} />
      </div>
    </div>
  );
}

export default SpreadSheetComponent;
