import React, { useCallback, useEffect, useState } from 'react';
import { EmailSendingReporting } from '.';
import EmailBasedReporting from '../../templates/components/EmailBasedReporting';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';

function EmailSendingReportingPage(props) {
    const [users, setUsers] = useState([]);
  // Fetch users based on role
  const getRoleBasedUsers = useCallback(() => {
    try {
      Get(
        { role: 'leadExpert' },
        API_URLS.getRoleBasedUsers,
        (response) => {
          setUsers(response?.data?.roleBaseUsers);
        },
        (error) => {
          console.log('error', error);
        }
      );
    } catch {
      // enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, []);

  useEffect(() => {
    getRoleBasedUsers();
  }, [getRoleBasedUsers]);

  return (
    <div>
      <EmailSendingReporting users={users}/>
      <br />

    </div>
  );
}

export default EmailSendingReportingPage;
